import { createAction } from 'redux-actions';

export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';
export const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION';
export const REMOVE_ALL_NOTIFICATIONS = 'REMOVE_ALL_NOTIFICATIONS';

export const showErrorNotification = createAction(SHOW_NOTIFICATION, opts => ({ ...opts, level: 'error', uid: opts.uid || Date.now() }));
export const showWarningNotification = createAction(SHOW_NOTIFICATION, opts => ({ ...opts, level: 'warning', uid: opts.uid || Date.now() }));
export const showSuccessNotification = createAction(SHOW_NOTIFICATION, opts => ({ ...opts, level: 'success', uid: opts.uid || Date.now() }));
export const hideNotification = createAction(HIDE_NOTIFICATION);
export const removeAllNotifications = createAction(REMOVE_ALL_NOTIFICATIONS);
