import { fork } from 'redux-saga/effects';
import modules from '../modules';

export default function* rootSaga() {
  for (const key in modules) {
    if (!modules.hasOwnProperty(key) || !modules[key].sagas) {
      continue;
    }
    const module = modules[key];
    yield fork(module.sagas);
  }
}
