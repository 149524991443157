import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { Icons } from '../base/BootstrapComponents';
import Loader from '../base/Loader/Loader';
import { CLIENT_MODULE } from '../../configs/moduleNames';
import { getPathNameWithOutParameters } from '../../utils/utils';
import { getActiveBlockName, sortLinksFunction } from '../../utils/layout-utils';
import { BUILD_VERSION } from '../../configs';
import MiniProfile from '../MiniProfile';
import Notifications from '../../modules/Notifications/components/Notifications';
import GlobalConfirmModal from '../../modules/System/components/GlobalConfirmModal';
import logo from '../../theme/img/mobile-logo.svg';
import { getSystemData } from '../../modules/System/reducer';

export default function MobileLayout({ currentRoutes, children }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const intl = useIntl();
  const { formatMessage } = intl;
  const navigate = useNavigate();
  const { loadApp } = useSelector(getSystemData);

  useEffect(() => {
    window.addEventListener('scroll', window.customEventListener.emit, { capture: true });
    return () => {
      window.removeEventListener('scroll', window.customEventListener.emit);
    };
  }, [dispatch]);

  useEffect(() => {
    setMenuOpenState(false);
  }, [navigate]);

  const currentPathName = getPathNameWithOutParameters(location.pathname);
  const [activeBlockName, setActiveBlockName] = useState(getActiveBlockName(currentPathName, currentRoutes));
  const [menuOpenState, setMenuOpenState] = useState(false);
  const loginProcess = useSelector(state => (state && state[CLIENT_MODULE] && state[CLIENT_MODULE].loginProcess ? state[CLIENT_MODULE].loginProcess : false));

  const handleClickMenuBlock = name => () => {
    let newName = name;
    if (activeBlockName === name) {
      newName = null;
    }
    setActiveBlockName(newName);
  };

  const renderMenuLink = (prop, key, isNested = true) => {
    if (prop.hideOnMenu || prop.redirect) {
      return null;
    }
    let className = prop.className || '';
    const isChildren = Boolean(prop.children && prop.children.length);
    const currentPathName = getPathNameWithOutParameters(location.pathname);
    const newActiveBlockName = getActiveBlockName(currentPathName, currentRoutes);
    if (activeBlockName !== newActiveBlockName) {
      setActiveBlockName(newActiveBlockName);
    }

    const pathName = getPathNameWithOutParameters(prop.path);
    const addProps = {};
    if (isNested) {
      addProps.onClick = handleClickMenuBlock(prop.name);
    }
    if (!isNested) {
      className = classNames(className, 'nested');
    }
    const isOpen = prop.name === activeBlockName;
    const isSelected = (currentPathName.includes(pathName) && pathName !== '/') || (currentPathName === pathName);
    const { MenuComponent = null } = prop;
    return (
      <div className="bd-toc-item" key={key}>
        <NavLink
          to={pathName}
          {...addProps}
          className={classNames('menu-link', className, { selected: isSelected || isOpen })}
        >
          {isNested && <Icons className="menu-item-icon" icon={prop.icon} customIcon={!!prop.customIcon} />}
          {MenuComponent ? <MenuComponent prop={prop} isOpen={isOpen} />
            : <span className="nav-link-text">{formatMessage({ id: prop.name })}</span>}
          {isChildren && (
          <Icons
            icon={isOpen ? 'caret-up-fill' : 'caret-down-fill'}
            className="nested-arrow"
            width={12}
            height={12}
          />
          )}
        </NavLink>
        {isChildren && isOpen
          ? (
            <ul className="nav nested sub-nav-group">
              {prop.children.sort(sortLinksFunction).map((prop, key) => renderMenuLink(prop, key, false))}
            </ul>
          )
          : null}
      </div>
    );
  };
  const sortedRoutes = currentRoutes.sort(sortLinksFunction);

  return (
    <div className={classNames('main-layout')}>
      <Notifications />
      <Loader loading={loginProcess || loadApp} global hideScreen={loadApp} />
      <div className="app-container">
        <div className={classNames('app-left-column', { active: menuOpenState })}>
          <div className="app-menu">
            <nav className="bd-links">
              <ul className="nav">
                {sortedRoutes.map(renderMenuLink)}
                <div className="menu-hr" />
                <div className="bd-toc-item">
                  <NavLink className="menu-link" to="/logout">
                    <Icons customIcon className="menu-item-icon" icon="logout" />
                    <span className="nav-link-text">Выйти</span>
                  </NavLink>
                </div>
              </ul>
            </nav>
            <div className="version-block">
              <div className="version-number">{BUILD_VERSION}</div>
            </div>
          </div>
        </div>
        <div className="app-content-wrapper">
          <div className="app-content">
            <div className="app-bar" id="app-bar">
              <div className="logo-block">
                <div className="toggle-button-wrapper" onClick={() => { setMenuOpenState(!menuOpenState); }}>
                  {menuOpenState
                    ? <Icons customIcon className="toggle-button" icon="arrow-back" />
                    : <Icons customIcon className="toggle-button" icon="arrow-forward" />}
                </div>
                <div className="logo">
                  <img src={logo} alt="logo" className="header-logo" />
                </div>
                <div className="logo-name">Ультра Паркинг </div>
              </div>
              <MiniProfile />
            </div>
            <div className="app-content-children">
              {children}
            </div>
          </div>
        </div>
      </div>
      <GlobalConfirmModal />
    </div>
  );
}
