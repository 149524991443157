import classNames from 'classnames';
import React, { Component } from 'react';
import Chart from 'react-apexcharts';
import { DEFAULT_CHART_COLORS } from '../../configs';
import Loader from '../base/Loader/Loader';

export default function ApexAreaChart(props) {
  const {
    series = [],
    labels = [],
    className = '',
    title = '',
    renderFooter = () => {
    },
    loading = false,
    blockHeight = 250,
  } = props;

  const options = {
    chart: {
      type: 'area',
    },
    colors: DEFAULT_CHART_COLORS,
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      // type: 'datetime',
      categories: labels,
    },
    // tooltip: {
    //   x: {
    //     format: 'dd/MM/yy'
    //   },
    // },
    title: {
      text: title,
      style: {
        fontSize: '15px',
        fontWeight: 500,
        fontFamily: 'GOSTUI2, Helvetica Neue, Helvetica, Arial, sans-serif',
        color: '#1665A7',
      },
    },
  };

  return (
    <div className={classNames('card-block-wrapper', className)}>
      <Loader loading={loading} />
      <div className="card-block chart-block">
        <div className="chart-block-content">
          <div className="chart-graph">
            <div className="apex-chart-wrapper">
              <Chart options={options} series={series} type="area" height={blockHeight} />
            </div>
          </div>
        </div>
        {renderFooter()}
      </div>
    </div>
  );
}
