import { call, put } from 'redux-saga/effects';
import { BACKEND_URL } from '../../../configs';
import { rejectActionPromise } from '../../../utils/rejectActionPromise';
import { resolveActionPromise } from '../../../utils/resolveActionPromise';

import { updateAvatarFailed, updateAvatarSuccessful } from '../actions';
import { OK } from '../../../configs/serverStatusCodes';
import makeRequest from '../../../utils/makeRequest';
import processError from '../../../utils/processError';

export default function* updateAvatar(action) {
  try {
    const {
      formData,
      id,
    } = action.payload;
    const url = `${BACKEND_URL}/users/${id}/update-avatar`;
    const dataResponse = yield makeRequest(url, formData, action, 'POST', true, {}, true);
    const data = yield dataResponse.json();
    if (dataResponse.status === OK
      && data
    ) {
      yield put(updateAvatarSuccessful([]));
      yield call(resolveActionPromise(action, data));
    } else {
      yield put(updateAvatarFailed([]));
      yield call(rejectActionPromise(action, data));
    }
  } catch (e) {
    yield put(updateAvatarFailed([]));
    yield call(rejectActionPromise(action, e));
    yield processError(e);
  }
}
