import { createAction } from 'redux-actions';
import { createDeferredAction } from '../../utils/redux';
import { SYSTEM_MODULE } from '../../configs/moduleNames';
import { FAILED, SUCCESSFUL } from '../../configs/actionNames';

export const INIT_APP = 'INIT_APP';
export const SET_LOCALE = 'SET_LOCALE';
export const SET_MENU_STATE = 'SET_MENU_STATE';
export const SHOW_CONFIRM = '_SHOW_CONFIRM';
export const HIDE_CONFIRM = '_HIDE_CONFIRM';
export const START_LOAD_APP = '_START_LOAD_APP';
export const END_LOAD_APP = '_END_LOAD_APP';

export const initApp = createDeferredAction(SYSTEM_MODULE + INIT_APP);

export const setLocale = createDeferredAction(SYSTEM_MODULE + SET_LOCALE, formValues => (formValues));
export const setMenuState = createDeferredAction(SYSTEM_MODULE + SET_MENU_STATE);
export const showGlobalConfirm = createDeferredAction(SYSTEM_MODULE + SHOW_CONFIRM);
export const hideGlobalConfirm = createAction(SYSTEM_MODULE + HIDE_CONFIRM);
export const startLoadApp = createDeferredAction(SYSTEM_MODULE + START_LOAD_APP);
export const endLoadApp = createAction(SYSTEM_MODULE + END_LOAD_APP);
