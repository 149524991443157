import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { useDeviceSelectors } from 'react-device-detect';
import { useForm } from 'react-hook-form';
import UserDTO from '../../../dto/UserDTO';
import logo from '../../../theme/img/logo-auth.svg';
import { processFormError } from '../../../utils/processFormError';
import {
  SUPPORT_MAIL, SUPPORT_PHONE,
} from '../../../configs';
import Loader from '../../../components/base/Loader/Loader';
import FieldDTO from '../../../components/fields/Abstract/FieldDTO';
import { Alert, Button, Icons } from '../../../components/base/BootstrapComponents';
import { clientCheck, clientLogin, forgotPassword } from '../actions';
import { CLIENT_MODULE } from '../../../configs/moduleNames';
import TextInputWithLabel from '../../../components/fields/TextInput/TextInputWithLabel';
import TextPasswordInput from '../../../components/fields/TextInput/TextPasswordInput';
import notificationDecorator from '../../Notifications/notificationDecorator';
import { responseLogWithNotification } from '../../../utils/utils';
import { calculateBaseRoute } from '../../../utils/routeByRoles';
import { startLoadApp, endLoadApp } from '../../System/actions';

export default function LoginPage({
  change,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const client = useSelector(state => state[CLIENT_MODULE]);
  const { loggedIn, loginProcess, loadingForgotPassword } = client;
  const [showForgot, setShowForgot] = useState(false);
  const [forgotSuccess, setForgotSuccess] = useState(false);
  const [showSupport, setShowSupport] = useState(false);
  const [selectors] = useDeviceSelectors(window.navigator.userAgent);
  const { isMobile } = selectors;
  useEffect(() => {
    if (loggedIn) {
      const baseRoute = calculateBaseRoute(true, [], null, isMobile, true);
      navigate(baseRoute || '/');
    }
  }, []);
  const {
    control, handleSubmit, reset,
  } = useForm({ mode: 'onBlur' });
  const [error, setError] = useState('');

  const handleLogin = values => {
    setError('');
    dispatch(clientLogin(values))
      .then(loginData => {
        dispatch(startLoadApp());
        setForgotSuccess(false);
        reset();
        const permission_keys = loginData && loginData.user && loginData.user.permission_keys ? loginData.user.permission_keys : [];
        const baseRoute = calculateBaseRoute(true, permission_keys, null, isMobile, true);
        dispatch(clientCheck())
          .then(() => {
            navigate(baseRoute || '/');
            setTimeout(() => { dispatch(endLoadApp()); }, 800);
          })
          .catch(responseLogWithNotification);
      })
      .catch(response => {
        setForgotSuccess(false);
        responseLogWithNotification(response, 'Вход', 'Произошла ошибка');
        setError(processFormError(response));
      });
  };

  const handleForgotPassword = values => {
    setError('');
    dispatch(forgotPassword(values))
      .then(() => {
        reset();
        setForgotSuccess(true);
        setShowForgot(false);
        setTimeout(() => {
          change('email', values.email);
        }, 100);
        notificationDecorator({
          title: 'Восстановление пароля',
          message: 'Успешно',
        });
      })
      .catch(response => {
        setForgotSuccess(false);
        responseLogWithNotification(response, 'Восстановление пароля', 'Произошла ошибка');
        setError(processFormError(response));
      });
  };

  const renderLoginForm = values => {
    const userDTO = new UserDTO();
    const attributes = userDTO.getAttributesByName();
    const showPassword = false;
    return (
      <>
        <div className="header text-center">
          <h2>
            Вход
          </h2>
        </div>
        <form
          className="login-form"
          noValidate
          onSubmit={handleSubmit(handleLogin.bind(this))}
        >
          {forgotSuccess ? <div className="forgot-password-success">Временный пароль выслан на указанную почту</div> : null}
          <FieldDTO
            control={control}
            type="text"
            component={TextInputWithLabel}
            dtoObject={attributes.email}
            variant="material"
          />
          <FieldDTO
            control={control}
            type={showPassword ? 'text' : 'password'}
            component={TextPasswordInput}
            withLabel
            dtoObject={attributes.password}
            className="password-field"
            variant="material"
          />
          {error && <Alert className="mt-3" variant="danger">{error}</Alert>}
          <Button className="login-button big" type="submit">Войти</Button>
          <div className="forgot-password" onClick={() => { setShowForgot(true); }}>
            Забыли пароль?
          </div>
          <div className="forgot-password" onClick={() => { setShowForgot(true); }}>
            Зарегистрироваться
          </div>
        </form>
      </>
    );
  };

  const renderForgotPasswordForm = values => {
    const userDTO = new UserDTO();
    const attributes = userDTO.getAttributesByName();
    return (
      <>
        <div className="header text-center">
          <h2>
            Восстановление пароля
          </h2>
        </div>
        <form className="login-form" noValidate onSubmit={handleSubmit(handleForgotPassword.bind(this))}>
          <FieldDTO
            type="text"
            control={control}
            component={TextInputWithLabel}
            dtoObject={attributes.email}
            variant="material"
          />
          {error && <Alert className="mt-3" variant="danger">{error}</Alert>}
          <div className="login-button-wrapper">
            <Button className="cancel-button big" variant="secondary" onClick={() => { setShowForgot(false); }}>Отменить</Button>
            <Button className="login-button big" type="submit">Отправить</Button>
          </div>
        </form>
      </>
    );
  };

  return (
    <div className={classNames('login-page-wrapper')}>
      <div className="login-page">
        <Loader loading={loginProcess || loadingForgotPassword} />
        <div className="data-wrapper">
          <div className="data-block">
            <div className="form-block">
              <div className="header text-center">
                <img src={logo} alt="logo" className="header-logo" />
              </div>
              {!showForgot ? renderLoginForm() : renderForgotPasswordForm()}
            </div>
            <div className="copyright">
              Техническая поддержка:
              {' '}
              <br />
              Тел.
              {' '}
              {SUPPORT_PHONE}
              <br />
              <a style={{ color: 'white', textDecoration: 'none' }} href={`mailto:${SUPPORT_MAIL}`}>
                {SUPPORT_MAIL}
              </a>
              &nbsp;
              <br />
            </div>
            {isMobile
              ? (
                <div className="support-agent-block">
                  <div className="support-agent-icon-wrapper">
                    <Icons customIcon icon="support-agent" className="support-agent-icon" onClick={() => { setShowSupport(true); }} />
                  </div>
                  { showSupport
                    ? (
                      <div className="support-agent-popup">
                        <div className="sap-backdrop" />
                        <div className="sap-data">
                          <div className="sap-header">
                            <div className="sap-title">Техническая поддержка</div>
                            <div className="sap-close">
                              <Icons icon="close" customIcon className="close" onClick={() => { setShowSupport(false); }} />
                            </div>
                          </div>
                          <div className="sap-body">
                            <a className="sap-item sap-phone" href={`tel:${SUPPORT_PHONE}`}>
                              <Icons icon="local-phone" customIcon className="sap-icon sap-phone-icon" />
                              <div className="sap-item-text">{SUPPORT_PHONE}</div>
                            </a>
                            <a className="sap-item sap-mail" href={`mailto:${SUPPORT_MAIL}`}>
                              <Icons icon="local-post-office" customIcon className="sap-icon sap-mail-icon" />
                              <div className="sap-item-text">{SUPPORT_MAIL}</div>
                            </a>

                          </div>
                        </div>
                      </div>
                    )
                    : null}
                </div>
              )
              : null}
          </div>
        </div>
      </div>
    </div>
  );
}
