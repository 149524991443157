import { takeLatest } from 'redux-saga/effects';
import { MODULE_NAME } from '../constants';
import { LOAD_ZONE_LIST } from '../actions';

import loadZoneList from './loadZoneList';
import loadZoneStatusList from './loadZoneStatusList';

export default function* sagas() {
  yield takeLatest(MODULE_NAME + LOAD_ZONE_LIST, loadZoneList);
}
