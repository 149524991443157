import { call, put } from 'redux-saga/effects';
import { BACKEND_URL } from '../../../configs';
import { rejectActionPromise } from '../../../utils/rejectActionPromise';
import { resolveActionPromise } from '../../../utils/resolveActionPromise';

import { removeRoleFailed, removeRoleSuccessful } from '../actions';
import { OK, NO_CONTENT } from '../../../configs/serverStatusCodes';
import makeRequest from '../../../utils/makeRequest';
import processError from '../../../utils/processError';

export default function* removeRole(action) {
  try {
    const id = action.payload;
    const url = `${BACKEND_URL}/roles/${id}`;
    const pool = {};
    const dataResponse = yield makeRequest(url, pool, action, 'DELETE', true);
    if (dataResponse.status === NO_CONTENT) {
      yield put(removeRoleSuccessful([]));
      yield call(resolveActionPromise(action));
    } else {
      const data = yield dataResponse.json();
      yield put(removeRoleFailed([]));
      yield call(rejectActionPromise(action, data));
    }
  } catch (e) {
    yield put(removeRoleFailed([]));
    yield call(rejectActionPromise(action, e));
    yield processError(e);
  }
}
