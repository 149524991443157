import moment from 'moment';
import DTO from './DTO';

class RoutesDTO extends DTO {
  constructor(params, isValidInit) {
    const attributes = [
      {
        name: 'id',
        type: String,
        validators: [],
        labelKey: 'role.id',
      },
      {
        name: 'url',
        type: String,
        validators: [],
        labelKey: 'role.name',
      },
      {
        name: 'name',
        type: String,
        validators: [],
        labelKey: 'role.is_root',
      },
      {
        name: 'created_at',
        type: moment,
        validators: [],
        labelKey: 'role.created_at',
      },
      {
        name: 'updated_at',
        type: moment,
        validators: [],
        labelKey: 'role.updated_at',
      },
    ];
    super(attributes, params, isValidInit);
  }

  getData() {
    return {
      id: this.id,
      url: this.url,
      name: this.name,
    };
  }

  getDataForRole() {
    return {
      id: this.id,
      url: this.url,
    };
  }
}

export default RoutesDTO;
