import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import modules from '../modules';

const reducersConfig = {
  routing: routerReducer,
};
for (const key in modules) {
  if (!modules.hasOwnProperty(key) || !modules[key].reducer) {
    continue;
  }
  reducersConfig[key] = modules[key].reducer;
}

const reducers = combineReducers(reducersConfig);
export default reducers;
