import React from 'react';
import classNames from 'classnames';
import CheckBoxInput from './CheckBoxInput';

export default function CheckBoxInputWithLabel(props) {
  const {
    label = '',
    className = '',
    inputClassName = '',
    textMuted = '',
    inputProps,
    blockId = '',
    style = {},
    afterContent = null,
  } = props;

  const checkBoxId = `check-box-${Math.random()}`;
  return (
    <div style={style} id={blockId} className={classNames('form-control-wrapper form-check check-box-with-label', className, { 'checked-checkbox': inputProps && inputProps.value })}>
      <CheckBoxInput
        {...props}
        id={checkBoxId}
        className={inputClassName}
      />
      <label className="form-check-label" htmlFor={checkBoxId}>
        {label}
      </label>
      { afterContent }
      {textMuted
        ? <span>{textMuted}</span>
        : null}
    </div>
  );
}
