import { handleActions } from 'redux-actions';
import { HIDE_NOTIFICATION, REMOVE_ALL_NOTIFICATIONS, SHOW_NOTIFICATION } from './actions';

const initialState = {
  notifications: [],
};

const reducer = handleActions({
  [SHOW_NOTIFICATION]: (state, action) => {
    const { notifications } = state;
    const { payload } = action;
    if (notifications.find(i => i.level === payload.level
      && i.title === payload.title
      && i.message === payload.message)) {
      return { ...state };
    }
    return {
      ...state,
      notifications: [...notifications, payload],
    };
  },
  [HIDE_NOTIFICATION]: (state, action) => {
    const notifications = state.notifications.filter(notification => notification.uid !== action.payload);
    return {
      ...state,
      notifications,
    };
  },
  [REMOVE_ALL_NOTIFICATIONS]: state => ({ ...state, notifications: [] }),
}, initialState);

export default reducer;
