import React, { useState } from 'react';
import classNames from 'classnames';
import CustomizedGrid from '../../../components/base/Grid/CustomizedGrid';
import Pagination from '../../../components/base/Pagination/Pagination';
import { Icons } from '../../../components/base/BootstrapComponents';
import CheckBoxInput from '../../../components/fields/CheckBoxInput/CheckBoxInput';
import { MoreFormatter } from '../../../utils/formatters';

export default function ClientGrid(props) {
  const {
    data,
    meta,
    handleLoadingClientList,
    onClickByRow,
    columns,
    setSelected,
    selected,
    setMode,
    setData,
    withPagination = true,
    removeClient,
  } = props;
  const [isOpen, setShowSession] = useState(false);

  const checkBoxColumn = columns.find(column => column.dataField === 'checkBox');
  const control = columns.find(column => column.dataField === 'control');

  if (control) {
    control.formatter = (_, item) => (
      <div className="control-box-formatter">
        <div className="control-box-button" key="views-details">
          <Icons width={24} height={24} customIcon icon="views-details" className="button-icons" />
          <div className="expanded-click-zone" onClick={e => { setMode('views'); setData(item); e.stopPropagation(); }} />
        </div>
        <div className="control-box-button" key="pencile">
          <Icons width={24} height={24} customIcon icon="pencile" className="button-icons" />
          <div className="expanded-click-zone" onClick={e => { setMode('edit'); setData(item); e.stopPropagation(); }} />
        </div>
        <div className="control-box-button" key="trash-active">
          <Icons width={24} height={24} customIcon icon="trash-active" className="button-icons" />
          <div className="expanded-click-zone" onClick={e => { setMode('delete'); setSelected([item.id]); e.stopPropagation(); }} />
        </div>
      </div>
    );
  }

  if (checkBoxColumn) {
    checkBoxColumn.formatter = (_, item) => {
      const check = selected.includes(item.id);
      return (
        <div className="check-box-field-formatter" onClick={e => { setSelected(check ? selected.filter(e => e !== item.id) : [...selected, item.id]); e.stopPropagation(); }}>
          <CheckBoxInput
            inputProps={{
              value: check,
            }}
          />
        </div>
      );
    };

    checkBoxColumn.headerFormatter = () => {
      const isAll = selected.length === data.length && selected.length && data.length;
      return (
        <div className="check-box-field-formatter" onClick={() => setSelected(selected.length < data.length ? data.map(e => e.id) : [])}>
          <CheckBoxInput
            className={classNames({ 'some-child': selected.length })}
            inputProps={{
              value: isAll,
            }}
          />
        </div>
      );
    };
  }
  return (
    <div className="client-table g-0">
      <div className="frame">
        <div className="sticky-table-wrapper custom-scroll-theme">
          <CustomizedGrid onClickByRow={onClickByRow} className="client-grid" data={data} columns={columns} name="client-list" settingsFormatter={MoreFormatter} />
        </div>
      </div>
      { withPagination ? <Pagination meta={meta} list={data} handleChangeMeta={handleLoadingClientList} /> : null }
    </div>
  );
}
