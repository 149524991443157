import React, { Component } from 'react';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';
import renderMessage from '../renderMessage';
import { required } from '../../../utils/validation';
import './SelectInputStyles.scss';

class SelectInput extends Component {
  componentDidMount() {
    const {
      input = {
        value: '',
        onChange: () => {},
      },
      addPlaceholder = false,
      defaultSelect = 0,
      options = [],
    } = this.props;
    const { value, onChange } = input;
    if (!value && !addPlaceholder && defaultSelect && options.length && options[defaultSelect - 1]) {
      onChange(options[defaultSelect - 1].value);
    }
  }

  onChange = v => {
    const { input, dtoObject } = this.props;
    if (input && input.onChange) {
      if (dtoObject && dtoObject.type && dtoObject.type === Boolean) {
        input.onChange(v.target.value === 'true');
        return;
      }
      input.onChange(v);
    }
  };

  onBlur = v => {
    const { input, dtoObject } = this.props;
    if (input && input.onBlur) {
      if (dtoObject && dtoObject.type && dtoObject.type === Boolean) {
        input.onBlur(v.target.value === 'true');
        return;
      }
      input.onBlur(v);
    }
  };

  render() {
    const {
      input = '',
      label,
      addPlaceholder = false,
      placeholder = '',
      className = '',
      inputClassName = '',
      textMuted = '',
      disabled = false,
      largeControl = false,
      textarea = false,
      withLabel = false,
      inputProps = {},
      options = [],
      multiple = false,
      useDefaultNull = false,
      errorWithOutTouched = false,
      notValid = false,
      size = 1,
      dtoObject,
      fieldState = {
        error: '',
        touched: false,
      },
      intl,
      controlId = `text-input-${Math.random() * 10000}`,
      customValidator = [],
    } = this.props;
    const { value = '' } = input;
    const { formatMessage } = intl;
    const {
      error,
      isTouched: touched,
    } = fieldState;
    const isError = Boolean(error);

    const isRequired = ((dtoObject
      && dtoObject.validators
      && dtoObject.validators.length
      && !!dtoObject.validators.find(validator => validator.toString() === required.toString()))
      || !!customValidator.find(validator => validator.toString() === required.toString()))
      && !notValid;
    return (
      <div className={classNames('form-control-wrapper', className)}>
        {withLabel ? (
          <label htmlFor={controlId} className="form-label">
            {renderMessage(label)}
            {isRequired ? <span className="form-label-mark-required">*</span> : null}
          </label>
        ) : null}
        <select
          aria-label="Select box"
          className={classNames(
            'form-select',
            inputClassName,
            { 'form-control-lg': largeControl },
            { 'is-invalid': isError && (touched || errorWithOutTouched) },
            { 'text-area': textarea },
            { 'not-selected': !value.toString().length },
          )}
          id={controlId}
          disabled={disabled}
          {...input}
          onChange={this.onChange}
          onBlur={this.onBlur}
          {...inputProps}
          multiple={multiple}
          size={size}
        >
          {addPlaceholder
            ? (
              <option
                value={useDefaultNull ? 'null' : ''}
              >
                {placeholder || ''}
              </option>
            )
            : null}
          {options.map((item, key) => {
            const isSelected = item.value === input.value;
            return (
              <option
                key={key}
                value={item.value === null ? 'null' : item.value}
                // selected={isSelected}
              >
                {!item.name && item.nameId ? formatMessage({ id: item.nameId }) : item.name || ''}
              </option>
            );
          })}
        </select>
        {textMuted ? <div id={`${controlId}-help`} className="form-text">{renderMessage(textMuted)}</div> : null}
        {(touched || errorWithOutTouched) && isError ? <div className="invalid-feedback">{renderMessage(error)}</div> : null}
      </div>
    );
  }
}

export default compose(
  injectIntl,
)(SelectInput);
