import React, { useState } from 'react';
import classNames from 'classnames';
import Grid from '../../../components/base/Grid/Grid';
import Pagination from '../../../components/base/Pagination/Pagination';
import CheckBoxInput from '../../../components/fields/CheckBoxInput/CheckBoxInput';
import CustomizedGrid from '../../../components/base/Grid/CustomizedGrid';
import { Icons } from '../../../components/base/BootstrapComponents';
import { MoreFormatter } from '../../../utils/formatters';

export default function EventLogGrid(props) {
  const {
    data,
    meta,
    handleLoadingEventList,
    onClickByRow,
    setData,
    columns,
    withPagination = true,
  } = props;
  const [isOpen, setShowSession] = useState(false);
  const checkBoxColumn = columns.find(column => column.dataField === 'checkBox');
  const [selected, setSelected] = useState([]);

  if (checkBoxColumn) {
    checkBoxColumn.formatter = (_, item) => {
      const check = selected.includes(item.id);
      return (
        <div className="check-box-field-formatter" onClick={e => { setSelected(check ? selected.filter(e => e !== item.id) : [...selected, item.id]); e.stopPropagation(); }}>
          <CheckBoxInput
            inputProps={{
              value: check,
            }}
          />
        </div>
      );
    };

    checkBoxColumn.headerFormatter = () => {
      const isAll = selected.length === data.length && selected.length && data.length;
      return (
        <div className="check-box-field-formatter" onClick={() => setSelected(selected.length < data.length ? data.map(e => e.id) : [])}>
          <CheckBoxInput
            className={classNames({ 'some-child': selected.length })}
            inputProps={{
              value: isAll,
            }}
          />
        </div>
      );
    };
  }

  return (
    <div className="elp-table g-0">
      <div className="frame">
        <div className="sticky-table-wrapper custom-scroll-theme">
          <CustomizedGrid className="elp-grid" data={data} columns={columns} onClickByRow={onClickByRow} name="elp-list" settingsFormatter={MoreFormatter} />
        </div>
      </div>
      { withPagination ? <Pagination meta={meta} list={data} handleChangeMeta={handleLoadingEventList} /> : null }
    </div>
  );
}
