import { handleActions } from 'redux-actions';
import { createLoadListReducerWithBegin } from '../../utils/getCrudConfig';
import { MODULE_NAME } from './constants';
import { HOSTS_MODULE } from '../../configs/moduleNames';
import {
  LOAD_HOST_LIST,
  LOAD_ACTION_HISTORY,
} from './actions';
import {
  LOAD_CLIENT_TYPE_LIST,
} from '../Clients/actions';
import {
  LOAD_ZONE_LIST,
} from '../Zones/actions';

const ZONES_PAGE = 'ZONES_PAGE';

const CLIENT_PAGE = 'CLIENTS_PAGE';

const initialState = {
  hostList: [],
  hostListMeta: [],
  parkingZoneList: [],
  hostActionHistory: [],
  hostActionHistoryMeta: [],
  clientTypeList: [],
};

const reducer = handleActions({
  ...createLoadListReducerWithBegin(MODULE_NAME + LOAD_HOST_LIST, 'hostList', 'loadinghostList'),
  ...createLoadListReducerWithBegin(MODULE_NAME + LOAD_ACTION_HISTORY, 'hostActionHistory', 'loadingactionHistory'),
  ...createLoadListReducerWithBegin(CLIENT_PAGE + LOAD_CLIENT_TYPE_LIST, 'clientTypeList', 'loadClientTypeList'),
  ...createLoadListReducerWithBegin(ZONES_PAGE + LOAD_ZONE_LIST, 'parkingZoneList', 'loadZoneList'),
}, initialState);

export default reducer;

export const getHostData = state => {
  const reducerData = state[HOSTS_MODULE];
  const {
    hostList,
    hostListMeta,
    hostActionHistory,
    hostActionHistoryMeta,
    clientTypeList,
    parkingZoneList,
  } = reducerData;

  return {
    hostListMeta,
    hostList,
    clientTypeList,
    hostActionHistory,
    hostActionHistoryMeta,
    parkingZoneList,
  };
};
