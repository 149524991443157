import React from 'react';
import classNames from 'classnames';
import './GridStyles.scss';
import { Icons } from '../BootstrapComponents';

export default function GridHeader(props) {
  const {
    columns,
    sort = [],
    changeSort = () => {},
    hideHeader = false,
  } = props;

  if (hideHeader) {
    return null;
  }

  const renderHeaderCell = (item, key) => {
    const { headerStyle = {}, headerClassName } = item;
    let content = item.text;
    if (item.headerFormatter && typeof item.headerFormatter === 'function') {
      content = item.headerFormatter(content, item);
    }
    const sortName = item.sortField || item.dataField;
    const el = sort.find(i => i === sortName || i === `-${sortName}`);
    return (
      <th
        key={key}
        style={headerStyle}
        className={classNames('table-header-cell', `column-${key}`, headerClassName, { 'sorted-column': !item.disableSort }, { 'revert-sort': el && el[0] === '-' })}
        role="columnheader"
        scope="col"
        onClick={!item.disableSort ? changeSort(sortName) : item.onClick ? item.onClick() : () => {}}
      >
        <div className="table-column-wrapper">
          <span className={classNames('table-column')}>
            {content}
          </span>
          <span className="table-column-sorted">
            {!item.disableSort && el ? <Icons customIcon icon="arrow-drop-down" /> : null}
          </span>
        </div>
      </th>
    );
  };

  return (
    <thead className="data-table-header">
      <tr className="table-header-row">
        {columns.map(renderHeaderCell)}
      </tr>
    </thead>
  );
}
