import Empty from '../../components/base/Empty';
import SessionLogPage from './components/SessionLogPage';

const routes = [
  {
    path: '/session-log/:page?',
    name: 'menu.session-log',
    icon: 'mon-session',
    customIcon: true,
    loginRequired: true,
    component: SessionLogPage,
    weight: 101,
    permission: ['frontend_menu_sessions'],
    children: [],
  },
];

export default routes;
