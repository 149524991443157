import React from 'react';
import classNames from 'classnames';
import './GridStyles.scss';

export default function GridWrapper(props) {
  const {
    data = [],
    className = '',
    loading = false,
    children = null,
  } = props;
  const empty = !data || data.length < 1;
  return (
    <table className={classNames('table', className, { empty }, { loading })}>
      {children}
    </table>
  );
}
