import modules from '../modules';

const routes = [];

let modulesRoutes = [];
for (const key in modules) {
  if (!modules.hasOwnProperty(key) || !modules[key].routes) {
    continue;
  }
  const module = modules[key];
  modulesRoutes = [...modulesRoutes, ...module.routes];
}

export default [...modulesRoutes, ...routes];
