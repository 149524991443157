import ConfigZonesAndHost from './components/ConfigZonesAndHost';

const routes = [
  {
    path: '/config-zone-and-host/:page?',
    name: 'menu.config-zone-and-host',
    icon: 'mon-setting',
    customIcon: true,
    loginRequired: true,
    component: ConfigZonesAndHost,
    weight: 101,
    permission: ['frontend_menu_config'],
    children: [],
  },
];

export default routes;
