import { call, put } from 'redux-saga/effects';
import { BACKEND_URL } from '../../../configs';
import { rejectActionPromise } from '../../../utils/rejectActionPromise';
import { resolveActionPromise } from '../../../utils/resolveActionPromise';

import { deleteUserFailed, deleteUserSuccessful } from '../actions';
import { NO_CONTENT } from '../../../configs/serverStatusCodes';
import makeRequest from '../../../utils/makeRequest';
import processError from '../../../utils/processError';

export default function* deleteUser(action) {
  try {
    const id = action.payload;
    const url = `${BACKEND_URL}/users/${id}`;
    const dataResponse = yield makeRequest(url, {}, action, 'DELETE', true);
    if (dataResponse.status === NO_CONTENT
    ) {
      yield put(deleteUserSuccessful([]));
      yield call(resolveActionPromise(action));
    } else {
      const data = yield dataResponse.json();
      yield put(deleteUserFailed([]));
      yield call(rejectActionPromise(action, data));
    }
  } catch (e) {
    yield put(deleteUserFailed([]));
    yield call(rejectActionPromise(action, e));
    yield processError(e);
  }
}
