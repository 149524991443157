import moment from 'moment';

const privateValidators = Symbol('validators');
const privateAttributes = Symbol('attributes');

class DTO {
  constructor(attributes, params, isValidInit = false) {
    // todo need refactoring
    try {
      if (!attributes || attributes.length < 1) {
        throw new Error('DTO is Invalid. Attributes not found.');
      }
      this[privateAttributes] = attributes; // use set get
      this[privateValidators] = {}; // use set get
      let index;
      for (index = attributes.length - 1; index >= 0; --index) {
        const attribute = attributes[index];
        const { name, couldBeNull = false } = attribute;
        const { validators } = attribute;
        const defaultValue = attribute.type && attribute.type !== moment && !couldBeNull ? attribute.type() : null; // todo neeed add default value to dto
        const param = params && params.hasOwnProperty(name) ? params[name] : defaultValue;
        this[privateValidators][name] = validators;
        if (isValidInit) {
          const isInvalid = this.isValid(param);
          if (isInvalid) {
            // todo need refactoring
            throw new Error(isInvalid.errorMesages.join(' , '));
          }
        }
        if (attribute.type) {
          if (param === null && couldBeNull) {
            this[name] = null;
          } else if (attribute.type === Array) {
            this[name] = param ? attribute.type(...param) : [];
          } else if (attribute.type === moment && !param) {
            this[name] = couldBeNull ? defaultValue : param;
          } else if (attribute.type === String && param === null) {
            this[name] = '';
          } else {
            this[name] = attribute.type(param);
          }
        } else {
          this[name] = param;
        }

        // use set and get
      }
    } catch (e) {
      window.console.error('Dto filed', e);// eslint-disable-line
      throw new Error(e.message);
    }
  }

  isValid(param) {
    const errorMesages = [];
    const validators = this[privateValidators];
    for (const name in validators) {
      if (validators.hasOwnProperty(name)) {
        const validator = validators[name];
        const isInvalid = validator(param);
        if (isInvalid) {
          errorMesages.push(`DTO is Invalid param ${name} value: ${param}. ${isInvalid}`);
        }
      }
    }
    return errorMesages.length > 0 ? errorMesages : false;
  }

  getAttributes() {
    return this[privateAttributes];
  }

  getAttributesByName() {
    return this[privateAttributes].reduce((acc, cur) => {
      acc[cur.name] = cur;
      return acc;
    }, {});
  }

  isClear() {
    return this[privateAttributes].reduce((acc, cur) => {
      const value = this[cur.name];
      if (value && value.isClear) {
        return acc && value.isClear();
      } if (Array.isArray(value)) {
        return acc && !value.length;
      }
      return acc && !value;
    }, true);
  }

  toObject() {
    const returnedValue = {};
    const keys = Object.keys(this);
    keys.forEach(item => {
      returnedValue[item] = this[item];
    });
    return returnedValue;
  }

  toString() {
    const returnedValue = {};
    const keys = Object.keys(this);
    keys.forEach(item => {
      returnedValue[item] = this[item] ? this[item].toString() : this[item];
    });
    return returnedValue;
  }

  get() {
    return this.toObject();
  }
}

export default DTO;
