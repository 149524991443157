import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { clientLogout } from '../actions';
import { CLIENT_MODULE } from '../../../configs/moduleNames';
import { responseLogWithNotification } from '../../../utils/utils';

export default function LogoutPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const client = useSelector(state => state[CLIENT_MODULE]);
  const { loggedIn } = client;
  useEffect(() => {
    if (!loggedIn) {
      navigate('/');
    } else {
      dispatch(clientLogout())
        .then(() => {
          navigate('/');
        })
        .catch(response => {
          responseLogWithNotification(response, 'Выход', 'Произошла ошибка');
        });
    }
  }, [navigate]);

  return null;
}
