import { createAction } from 'redux-actions';
import { createDeferredAction } from '../../utils/redux';
import { FAILED, SUCCESSFUL } from '../../configs/actionNames';
import { CLIENT_MODULE } from '../../configs/moduleNames';

export const CLIENT_REGISTRATION = '_CLIENT_REGISTRATION';
export const CLIENT_LOGIN = '_CLIENT_LOGIN';
export const CLIENT_LOGOUT = '_CLIENT_LOGOUT';
export const CLIENT_CHECK = '_CLIENT_CHECK';
export const FORGOT_PASSWORD = '_FORGOT_PASSWORD';

export const clientRegistration = createDeferredAction(CLIENT_MODULE + CLIENT_REGISTRATION, formValues => (formValues));
export const clientLogin = createDeferredAction(CLIENT_MODULE + CLIENT_LOGIN, formValues => (formValues));
export const clientLogout = createDeferredAction(CLIENT_MODULE + CLIENT_LOGOUT);
export const clientLogoutSuccessful = createDeferredAction(CLIENT_MODULE + CLIENT_LOGOUT + SUCCESSFUL);
export const clientLoginSuccessful = createAction(CLIENT_MODULE + CLIENT_LOGIN + SUCCESSFUL, clientData => (clientData));
export const clientLoginFailed = createAction(CLIENT_MODULE + CLIENT_LOGIN + FAILED, formValues => (formValues));
export const clientCheck = createDeferredAction(CLIENT_MODULE + CLIENT_CHECK);
export const clientCheckSuccess = createAction(CLIENT_MODULE + CLIENT_CHECK + SUCCESSFUL);
export const clientCheckFailed = createAction(CLIENT_MODULE + CLIENT_CHECK + FAILED);

export const forgotPassword = createDeferredAction(CLIENT_MODULE + FORGOT_PASSWORD);
export const forgotPasswordSuccessful = createAction(CLIENT_MODULE + FORGOT_PASSWORD + SUCCESSFUL);
export const forgotPasswordFailed = createAction(CLIENT_MODULE + FORGOT_PASSWORD + FAILED);
