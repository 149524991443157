import ClientPage from './components/ClientPage';

const routes = [
  {
    path: '/clients/:page?',
    name: 'menu.clients',
    icon: 'mon-clients',
    customIcon: true,
    loginRequired: true,
    component: ClientPage,
    weight: 101,
    permission: ['frontend_menu_clients'],
    children: [],
  },
];

export default routes;
