import { put, call } from 'redux-saga/effects';
import { BACKEND_URL } from '../../../configs';
import makeRequest from '../../../utils/makeRequest';
import { OK } from '../../../configs/serverStatusCodes';
import processError from '../../../utils/processError';
import { rejectActionPromise } from '../../../utils/rejectActionPromise';
import { resolveActionPromise } from '../../../utils/resolveActionPromise';
import { MODULE_BACK_PATH, MODULE_DTO } from '../constants';
import { loadByIdFailed, loadByIdSuccessful } from '../actions';

export default function* loadById(action) {
  try {
    const pool = {};
    const id = action.payload;
    const url = `${BACKEND_URL}/${MODULE_BACK_PATH}/${id}`;
    const dataResponse = yield makeRequest(url, pool, action, 'GET', true);
    const data = yield dataResponse.json();
    if (dataResponse.status === OK && data) {
      if (data.data && data.data.offices) {
        const { offices = [] } = data.data;
        const officeIds = offices.map(i => i.id);
        data.data.officeIds = officeIds;
      }
      const DTO = new MODULE_DTO(data.data);
      yield put(loadByIdSuccessful(DTO));
      yield call(resolveActionPromise(action, data));
    } else {
      yield put(loadByIdFailed());
      yield call(rejectActionPromise(action, data));
    }
  } catch (e) {
    yield put(loadByIdFailed());
    yield call(rejectActionPromise(action, e));
    yield processError(e);
  }
}
