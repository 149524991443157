import classNames from 'classnames';
import React from 'react';
import Chart from 'react-apexcharts';
import Loader from '../base/Loader/Loader';

export default function HeatMap(props) {
  const {
    series = [],
    options = {},
    title = '',
    className = '',
    loading = false,
  } = props;

  const defaultOptions = {
    plotOptions: {
      heatmap: {
        radius: 0,
        colorScale: {
          ranges: [
            {
              from: 0,
              to: 0,
              color: '#eff7ff',
            },
          ],
          min: 0,
        },
      },
    },
    chart: {
      height: 350,
      type: 'heatmap',
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          customIcons: [],
        },
        export: {
          csv: {
            filename: undefined,
            columnDelimiter: ',',
            headerCategory: 'category',
            headerValue: 'value',
            dateFormatter(timestamp) {
              return new Date(timestamp).toDateString();
            },
          },
        },
        autoSelected: 'zoom',
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: ['#3b7ddd'],
    title: {
      text: title,
      style: {
        fontSize: '15px',
        fontWeight: 500,
        fontFamily: 'GOSTUI2, Helvetica Neue, Helvetica, Arial, sans-serif',
        color: '#1665A7',
      },
    },
    legend: {
      show: false,
    },
  };
  return (
    <div className={classNames('card-block-wrapper', className)}>
      <Loader loading={loading} />
      <div className="card-block chart-block">
        <div className="chart-block-content">
          <div className="chart-graph">
            <div className="apex-chart-wrapper">
              <Chart options={{ ...defaultOptions, ...options }} series={series} type="heatmap" height={350} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
