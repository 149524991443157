/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import classNames from 'classnames';
import React, { Component } from 'react';
import * as ReactDOM from 'react-dom';
import PageHeader from '../PageHeader/PageHeader';
import { Icons } from '../BootstrapComponents';
import './DialogStyles.scss';

export class Dialog extends Component {
  constructor(props) {
    super(props);
    this.wrapperRef = null;
    this.modalId = `modal-block-${Math.random()}`;
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside); // todo нужно сделать на этапе всплытия
    window.addEventListener('keydown', this.keyDown);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
    window.removeEventListener('keydown', this.keyDown);
    this.removeModal();
  }

  keyDown = event => {
    const { onClose = () => {}, open = false } = this.props;
    if (event.keyCode === 27 && open) {
      onClose();
      event.preventDefault();
      event.stopPropagation();
    }
  };

  setRef = ref => {
    this.wrapperRef = ref;
  };

  handleClickOutside = event => {
    const { onClose = () => {}, hideByOutside = false } = this.props;
    if (this.wrapperRef && !this.wrapperRef.contains(event.target) && hideByOutside) {
      onClose();
    }
  };

  removeModal = () => {
    const element = document.body;
    const index = window.activeDialogList.indexOf(this.modalId);
    if (index > -1) {
      window.activeDialogList.splice(index, 1);
    }
    if (element.classList.contains('modal-open') && !window.activeDialogList.length) {
      element.classList.remove('modal-open');
    }
  };

  addBodyClass = () => {
    const {
      open = false,
    } = this.props;
    const element = document.body;
    const index = window.activeDialogList.indexOf(this.modalId);
    if (open) {
      if (index === -1) {
        window.activeDialogList.push(this.modalId);
      }
      element.classList.add('modal-open');
    } else {
      this.removeModal();
    }
  };

  renderBackdrop = backdropProps => {
    const {
      open = true,
      onClose = () => {},
    } = this.props;
    if (!open) {
      return null;
    }
    return (
      <div
        {...backdropProps}
        onClick={onClose}
        className={classNames(
          'modal-backdrop fade',
          open && 'show',
        )}
      />
    );
  };

  render() {
    const {
      className = '',
      children,
      open = false,
      size = '',
      onMouseMove = () => {},
      onMouseUp = () => {},
    } = this.props;
    const propsToComponent = { ...this.props };

    const baseModalStyle = { display: open ? 'block' : '' };
    this.addBodyClass();

    return (
      <>
        {ReactDOM.createPortal(
          <div
            {...propsToComponent}
            onMouseMove={onMouseMove}
            onMouseUp={onMouseUp}
            id={this.modalId}
            className={classNames('modal', 'fade', className, { show: open })}
            aria-labelledby={`${this.modalId}Label`}
            aria-hidden={!open}
            style={baseModalStyle}
            tabIndex="1"
          >
            {this.renderBackdrop()}
            <div className={classNames('modal-dialog', 'modal-dialog-centered', 'modal-dialog-scrollable', size && `modal-${size}`)} ref={this.setRef}>
              <div className="modal-content">
                {children}
              </div>
            </div>
          </div>,
          document.body,
        )}
      </>
    );
  }
}

export function DialogContent(props) {
  const {
    children,
    className = '',
    onMouseDown = () => {},
    id = '',
  } = props;

  return (
    <div className={classNames('modal-body', className)} onMouseDown={onMouseDown} id={id}>
      {children}
    </div>
  );
}

export function DialogFooter(props) {
  return (<div className={classNames(props.className, 'modal-footer')}>{props && props.children ? props.children : null}</div>);
}

export function DialogTitle(props) {
  return (<PageHeader {...props} className={classNames(props.className, 'modal-header')}>{props.title}</PageHeader>);
}

export function DialogClose(props) {
  const {
    onClick = () => {},
  } = props;
  return (
    <Icons icon="close" customIcon className="close" onClick={onClick} />
  );
}
