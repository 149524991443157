import React from 'react';
import classNames from 'classnames';
import './GridStyles.scss';

export default function GridBody(props) {
  const {
    columns,
    data,
    loading = false,
    emptyText = 'Нет данных',
    onClickByRow = () => {},
    rowClassFormatter = () => {},
  } = props;

  const onSelectRow = (item, index) => event => {
    onClickByRow(item, event, index);
  };

  const renderCell = (row, item, key, rowKey) => {
    let content = row[item.dataField];
    const { bodyStyle = {}, dataClassName = '' } = item;
    if (item.formatter && typeof item.formatter === 'function') {
      content = item.formatter(content, row, rowKey);
    }
    return (
      <td
        key={key}
        style={bodyStyle}
        className={classNames('data-table-cell', dataClassName)}
      >
        {content}
      </td>
    );
  };

  const renderRow = (row, rowKey) => (
    <tr
      key={rowKey}
      className={classNames('data-table-row', rowClassFormatter(row))}
      onClick={onSelectRow(row, rowKey)}
    >
      {columns.map((item, key) => (renderCell(row, item, key, rowKey)))}
    </tr>
  );

  return (
    <tbody className="data-table-content">
      {data && data.map(renderRow)}
      {(!data || data.length < 1) && emptyText && !loading ? (<tr><td colSpan={columns.length || 0} className="text-center align-middle empty-text">{emptyText}</td></tr>) : null}
      {(!data || data.length < 1) && loading ? (<tr><td colSpan={columns.length || 0} className="text-center align-middle">Идет загрузка</td></tr>) : null}
    </tbody>
  );
}
