import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  visualFormatter,
  HostNameAndStatusFormatter,
} from '../../../utils/formatters';
import EventLogGrid from '../../EventLog/components/EventLogGrid';
import { Icons, Button } from '../../../components/base/BootstrapComponents';
import TooltipWithPortal from '../../../components/base/Tooltip/TooltipWithPortal';
import { defaultColumns as eventLogColumn } from '../../EventLog/moks';
import { getColumnsForTable } from '../../../utils/getColumnsForTable';
import HostHistoryPopUp from './HostHistoryPopUp';

export default function HostStatus(props) {
  const {
    hostList: item,
    hostActionHistoryMeta,
    setValue,
    handleLoadingEventList,
    handleActionHistory,
    eventList,
    eventListMeta,
    hostActionHistory,
  } = props;
  const [isPopup, setIsPopup] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    handleActionHistory();
    handleLoadingEventList();
  }, []);

  return (
    <div className="host-status">
      <div className="host-status-info ">
        <div className="host-status-box">
          <div className="host-status-box-panel">
            {HostNameAndStatusFormatter({ item })}
            <div className="host-status-box-control">
              <div className="control-button" onClick={() => setIsPopup(true)}>
                <Icons width={24} height={24} customIcon icon="history" className="button-icons item-icon" />
                История
              </div>
              {/* <TooltipWithPortal
                showByClick={() => {}}
                content={
                  (
                    <div className="control-button command">
                      <Icons width={18} height={12} customIcon icon="vector" className="button-icons item-icon" />
                      Команды
                    </div>
                  )
                }
                classNamePortal="mpf-more-actions setting-host-status-history-portal"
                tooltipContent={(
                  <div className="hosts-more-wrapper">
                    {item.available_commands && item.available_commands.map(copm => (
                      <Button variant="link" key={copm} className="w-100 hosts-more-button">
                        <div className="hosts-more-button-icon">
                          <Icons width={18} height={14} customIcon icon="reorder" className="button-icons" />
                        </div>
                        {copm}
                      </Button>
                    ))}
                  </div>
                )}
              /> */}
            </div>
          </div>
          <div className="host-status-items">
            <div className="host-status-item visual" key="visual">
              {visualFormatter((item.vehicle_detection_state))}
            </div>
            {
              item.components && item.components.map((equipment, index) => (
                <div className="host-status-item" key={index}>
                  <div className="item-content" key={index}>
                    <span className="item-title">{equipment.type}</span>
                    <div className="item-equipment-container">
                      <div className="equipments-group-item" key={equipment}>
                        <Icons width={32} height={32} customIcon icon={equipment.type} className="button-icons" />
                        <div className="equipments-group-item-status">
                          { equipment.status === 'ok' ? null : <Icons width={17} height={17} customIcon icon={equipment.status === 'error' ? 'error-circle' : 'warning-circle'} className="button-icons" />}
                        </div>
                      </div>
                    </div>
                    {/* { equipment.name === 'cam' ? govermentNumberFormatter(item.govermentNumber) : null } */}
                    <div className="item-description">
                      {equipment.description}
                    </div>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </div>
      <div className="host-status-event">
        <div className="host-status-event-panel">
          <div className="host-status-title">Журнал событий</div>
          <div className="host-status-show-table" onClick={() => navigate('/event-log')}>Показать всё</div>
        </div>
        <EventLogGrid handleLoadingEventList={handleLoadingEventList} meta={eventListMeta} data={eventList} columns={getColumnsForTable(eventLogColumn, ['created_at', 'client_id', 'ticket', 'session', 'status', 'message'])} />
      </div>
      { isPopup ? <HostHistoryPopUp setValue={setValue} title={item.name} meta={hostActionHistoryMeta} handleActionHistory={handleActionHistory} item={hostActionHistory} onHide={() => { setIsPopup(false); }} /> : null}
    </div>
  );
}
