import { takeEvery, takeLatest } from 'redux-saga/effects';
import { MODULE_NAME } from '../constants';
import { CREATE } from '../../../configs/actionNames';
import {
  LOAD_PERMISSION_LIST,
  LOAD_ROLE_LIST,
  LOAD_USER_LIST,
  UPDATE_ROLE,
  UPDATE_USER,
  REMOVE_ROLE,
  CREATE_USER,
  REMOVE_USER,
  UPDATE_AVATAR,
  DELETE_USER,
} from '../actions';

import create from './create';
import loadUserList from './loadUserList';
import loadRoleList from './loadRoleList';
import loadPermissionList from './loadPermissionList';
import updateRole from './updateRole';
import removeRole from './removeRole';
import createUser from './createUser';
import updateUser from './updateUser';
import removeUser from './removeUser';
import updateAvatar from './updateAvatar';
import deleteUser from './deleteUser';

export default function* sagas() {
  yield takeLatest(MODULE_NAME + LOAD_USER_LIST, loadUserList);
  yield takeLatest(MODULE_NAME + LOAD_ROLE_LIST, loadRoleList);
  yield takeLatest(MODULE_NAME + LOAD_PERMISSION_LIST, loadPermissionList);
  yield takeEvery(MODULE_NAME + CREATE, create);
  yield takeLatest(MODULE_NAME + UPDATE_ROLE, updateRole);
  yield takeEvery(MODULE_NAME + REMOVE_ROLE, removeRole);
  yield takeEvery(MODULE_NAME + CREATE_USER, createUser);
  yield takeEvery(MODULE_NAME + UPDATE_USER, updateUser);
  yield takeEvery(MODULE_NAME + REMOVE_USER, removeUser);
  yield takeEvery(MODULE_NAME + UPDATE_AVATAR, updateAvatar);
  yield takeEvery(MODULE_NAME + DELETE_USER, deleteUser);
}
