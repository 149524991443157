import { takeLatest } from 'redux-saga/effects';
import { MODULE_NAME } from '../constants';
import { LOAD_EVENT_LIST } from '../actions';

import loadEventList from './loadEventList';
import loadEventStatusList from './loadEventStatusList';

export default function* sagas() {
  yield takeLatest(MODULE_NAME + LOAD_EVENT_LIST, loadEventList);
  yield takeLatest(MODULE_NAME + LOAD_EVENT_LIST, loadEventStatusList);
}
