import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';
import { CLIENT_MODULE } from '../../configs/moduleNames';
import notificationDecorator from '../../modules/Notifications/notificationDecorator';

class PermissionAccessWrapper extends Component {
  onClick = event => {
    const {
      intl: {
        formatMessage,
      },
    } = this.props;
    notificationDecorator(
      {
        isError: true,
        title: formatMessage({ id: 'permission.access.title' }),
        message: formatMessage({ id: 'permission.access.denied' }),
      },
    );
    event.preventDefault();
    event.stopPropagation();
    return false;
  };

  render() {
    const {
      requiredPermissions = [],
      permission_keys = [],
      children,
      locked = false,
      hided = false,
      lockedOnClick = false,
      addLockedClass = false,
      strictMode = true,
    } = this.props;

    const access = !!requiredPermissions.every(routePermission => !!permission_keys.find(item => item === routePermission));
    const noRules = !access || (strictMode && (!permission_keys || !permission_keys.length));
    if (hided && noRules) {
      return null;
    }
    let customProps = {};
    return (
      React.Children.map(children, child => {
        if (!child) { return null; }
        if (lockedOnClick && noRules) {
          customProps = { ...customProps, onClick: child.props.onClick ? this.onClick : () => {} };
        }
        if (addLockedClass && noRules) {
          customProps = { ...customProps, className: classNames(child.props.className, 'locked-events') };
        }
        if (locked && noRules) {
          customProps = { ...customProps, disabled: true };
        }
        return React.cloneElement(child, customProps);
      })
    );
  }
}

const mapStateToProps = state => {
  const {
    [CLIENT_MODULE]: { clientData },
  } = state;
  const { permissions } = clientData;
  return {
    permission_keys: permissions ? permissions.map(e => e.name) : [],
  };
};

export default compose(
  injectIntl,
  connect(mapStateToProps, null),
)(PermissionAccessWrapper);
