import { handleActions } from 'redux-actions';

import { createLoadListReducerWithBegin, createOperationStateReducer } from '../../utils/getCrudConfig';
import { MODULE_NAME } from './constants';
import { CLIENTS_MODULE } from '../../configs/moduleNames';
import {
  DELETE_USER,
  LOAD_CLIENT_LIST, LOAD_CLIENT_TYPE_LIST,
} from './actions';
import {
  LOAD_ZONE_LIST,
} from '../Zones/actions';

const initialState = {
  clientList: [],
  clientTypeList: [],
  parkingZoneList: [],
  clientListMeta: [],
  loadingRemoveUser: false,
};
const ZONES_PAGE = 'ZONES_PAGE';

const reducer = handleActions({
  ...createLoadListReducerWithBegin(MODULE_NAME + LOAD_CLIENT_LIST, 'clientList', 'loadingclientList'),
  ...createLoadListReducerWithBegin(MODULE_NAME + LOAD_CLIENT_TYPE_LIST, 'clientTypeList', 'loadclientTypeList'),
  ...createLoadListReducerWithBegin(ZONES_PAGE + LOAD_ZONE_LIST, 'parkingZoneList', 'loadZoneList'),
}, initialState);

export default reducer;

export const getClientData = state => {
  const reducerData = state[CLIENTS_MODULE];
  const {
    clientList,
    parkingZoneList,
    clientTypeList,
    clientListMeta,
  } = reducerData;

  return {
    clientList,
    parkingZoneList,
    clientTypeList,
    clientListMeta,
  };
};
