import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import { Calendar, DateObject } from 'react-multi-date-picker';
import moment from 'moment';
import Dropdown from '../../base/Dropdown/Dropdown';
import { Icons } from '../../base/BootstrapComponents';
import gregorian_ru from './gregorian_ru';
import TextInput from '../TextInput/TextInput';
import './RangeDatePickerStyles.scss';

export default function RangeDatePicker(props) {
  const {
    className = '',
    inline = false,
    hidePeriodButtons = false,
    handleChangeDate = () => {},
    fieldState = {
      error: '',
      touched: false,
    },
    usePortal = false,
    value = null,
    controlId = `text-input-${Math.random() * 10000}`,
  } = props;
  const defaultValue = value && value.length ? value : [new DateObject(), new DateObject()];
  const {
    isTouched: touched,
  } = fieldState;
  const [values, setValues] = useState(defaultValue);
  const [selectedPeriod, setSelectedPeriod] = useState(null);
  const [pickerKey, setPickerKey] = useState(null);
  const pickerRef = useRef(null);
  const [start, end] = values;
  const onSelect = range => {
    if (range && range.length && range.length > 1) {
      setValues(range);
      handleChangeDate(range);
    }
  };
  const onChange = range => {
    setSelectedPeriod(null);
    onSelect(range);
  };
  const anchorElement = useRef(null);

  const handleSelectPeriod = type => () => {
    const today = moment();
    let start = today.clone().startOf('day');
    let end = today.clone().endOf('day');
    if (type === 'y') {
      start = today.clone().startOf('year');
      end = today.clone().endOf('year');
    }
    if (type === 'm') {
      start = today.clone().startOf('month');
      end = today.clone().endOf('month');
    }
    if (type === 'w') {
      start = today.clone().startOf('week');
      end = today.clone().endOf('week');
    }
    if (type === 'yd') {
      start = today.clone().startOf('days')
        .subtract(1, 'days');
      end = today.clone().endOf('days')
        .subtract(1, 'days');
    }
    setSelectedPeriod(type);
    onSelect([new DateObject({ date: start.toDate() }), new DateObject({ date: end.toDate() })]);
    setTimeout(() => {
      setPickerKey(pickerKey + 1);
    }, 50);
  };

  const startText = start && start.format ? start.format('DD.MM.YYYY') : '';
  const endText = end && end.format ? end.format('DD.MM.YYYY') : '';
  const isError = false;
  return (
    <div className={classNames('form-control-wrapper range-date-picker', { 'inline-picker': inline }, className)}>
      <div className="custom-react-date-picker">
        <Dropdown usePortal={usePortal}>
          <Dropdown.Toggle target={pickerRef && pickerRef.current ? pickerRef.current : null}>
            <div ref={anchorElement} className="date-selector-wrapper">
              <div className="date-selector">
                <TextInput
                  {...props}
                  readOnly
                  inputBefore={(
                    <Icons id={controlId} customIcon className="calendar-week" width={20} height={22} icon="calendar-week" />
                  )}
                  className={classNames('data-picker-text', { 'is-invalid': isError && touched })}
                  refFunction={pickerRef}
                  inputProps={{
                    onChange: () => {},
                    value: `${startText} — ${endText}`,
                  }}
                />
              </div>
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu anchorElement={anchorElement} disableClickToggle className="data-picker-wrapper">
            <Calendar
              key={pickerKey}
              value={values}
              onChange={onChange}
              locale={gregorian_ru}
              range
              rangeHover
              weekStartDayIndex={1}
            />
            {!hidePeriodButtons
              ? (
                <div className="data-picker-filter-buttons">
                  <span className={classNames('data-picker-filter-button', { active: selectedPeriod === 'yd' })} onClick={handleSelectPeriod('yd')}>Вчера</span>
                  <span className={classNames('data-picker-filter-button', { active: selectedPeriod === 'd' })} onClick={handleSelectPeriod('d')}>Сегодня</span>
                  <span className={classNames('data-picker-filter-button', { active: selectedPeriod === 'w' })} onClick={handleSelectPeriod('w')}>Неделя</span>
                  <span className={classNames('data-picker-filter-button', { active: selectedPeriod === 'm' })} onClick={handleSelectPeriod('m')}>Месяц</span>
                  <span className={classNames('data-picker-filter-button', { active: selectedPeriod === 'y' })} onClick={handleSelectPeriod('y')}>Год</span>
                </div>
              )
              : null}
          </Dropdown.Menu>
        </Dropdown>

      </div>
    </div>
  );
}
