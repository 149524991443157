import React from 'react';
import { Icons } from '../base/BootstrapComponents';

export default function IconBox(props) {
  const { icon, onClick } = props;
  return (
    <div className="box-icon" onClick={onClick}>
      <Icons width={24} height={24} customIcon icon={icon} className="button-icons" />
    </div>
  );
}
