import processError from '../../../utils/processError';

export default function* setLocale() {
  try {
    setTimeout(() => {
      document.location.reload();// todo hack
    }, 200);
  } catch (e) {
    yield processError(e);
  }
}
