import { put, call } from 'redux-saga/effects';
import { rejectActionPromise } from '../../../utils/rejectActionPromise';
import { resolveActionPromise } from '../../../utils/resolveActionPromise';
import { BACKEND_URL } from '../../../configs';
import makeRequest from '../../../utils/makeRequest';
import processError from '../../../utils/processError';
import { OK } from '../../../configs/serverStatusCodes';
import {
  loadEventStatusFailed,
  loadEventStatusSuccessful,
  loadEventStatusBegin,
} from '../actions';

export default function* loadEventStatusList(action) {
  try {
    const {
      payload: {
        limit = 10,
        page = 1,
      } = {},
    } = action.payload;

    const url = `${BACKEND_URL}/event-statuses`;
    const pool = { page, limit };
    yield put(loadEventStatusBegin());
    const dataResponse = yield makeRequest(url, pool, action, 'GET', true);
    const data = yield dataResponse.json();
    if (dataResponse.status === OK) {
      data.result = data.data;
      yield put(loadEventStatusSuccessful(data));
      yield call(resolveActionPromise(action, data));
    } else {
      yield put(loadEventStatusFailed());
      yield call(rejectActionPromise(action, data));
    }
  } catch (e) {
    yield put(loadEventStatusFailed());
    yield call(rejectActionPromise(action, e));
    yield processError(e);
  }
}
