import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import './NotificationStyles.scss';
import { hideNotification } from '../actions';
import { Icons } from '../../../components/base/BootstrapComponents';
import Timer from '../timer';

export default function NotificationsItem(props) {
  const [visible, setVisible] = useState(false);
  const [isHidden, setHidden] = useState(false);
  const [notificationTimer, setTimer] = useState(null);
  const { autoDismiss = 0 } = props;

  const handleMouseEnter = () => {
    if (autoDismiss && notificationTimer) {
      notificationTimer.pause();
    }
  };

  const handleMouseLeave = () => {
    if (autoDismiss && notificationTimer) {
      notificationTimer.resume();
    }
  };

  const showNotification = () => {
    setTimeout(() => setVisible(true), 50);
  };

  const handleHideNotification = () => {
    dispatch(hideNotification(props.uid));
  };

  useEffect(() => {
    if (autoDismiss) {
      setTimer(
        new Timer(() => {
          handleHideNotification();
        }, autoDismiss * 1000),
      );
    }
    showNotification();
  }, []);

  const dispatch = useDispatch();
  const title = props.title ? <div className="notification-title">{props.title}</div> : null;
  const message = props.message ? <div className="notification-message">{props.message}</div> : null;
  const level = props.level || 'info';
  const levelIcon = level !== 'success' ? 'error' : 'success';

  return (
    <div
      className={classNames('notification', `notification-${level}`, { 'is-visible': visible }, { 'is-hidden': isHidden })}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="notification-icon-wrapper">
        <Icons
          customIcon
          icon={levelIcon}
          className="notification-icon"
        />
      </div>
      <div className="notification-content">
        {title}
        {message}
      </div>
      <Icons
        width={14}
        height={14}
        customIcon
        icon="clear"
        className="notification-dismiss"
        onClick={handleHideNotification}
      />
    </div>
  );
}
