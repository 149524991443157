import classNames from 'classnames';
import React from 'react';
import Chart from 'react-apexcharts';
import { DEFAULT_CHART_COLORS } from '../../configs';
import Loader from '../base/Loader/Loader';

export default function ApexDonutChart(props) {
  const {
    series = [],
    labels = [],
    title = '',
    className = '',
    loading = false,
  } = props;

  const options = {
    labels,
    chart: {
      type: 'donut',
      toolbar: {
        show: true,
      },
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            value: {
              color: '#757575',
            },
            total: {
              show: true,
              showAlways: true,
              label: 'Всего',
              color: '#757575',
            },
          },
        },
      },
    },
    colors: DEFAULT_CHART_COLORS,
    legend: {
      position: 'bottom',
      formatter(seriesName, opts) {
        return [seriesName, ' - ', opts.w.globals.series[opts.seriesIndex]];
      },
      labels: {
        color: undefined,
        useSeriesColors: true,
      },
    },
    title: {
      text: title,
      style: {
        fontSize: '15px',
        fontWeight: 500,
        fontFamily: 'GOSTUI2, Helvetica Neue, Helvetica, Arial, sans-serif',
        color: '#1665A7',
      },
    },
  };

  return (
    <div className={classNames('card-block-wrapper', className)}>
      <Loader loading={loading} />
      <div className="card-block chart-block">
        <div className="chart-block-content">
          <div className="chart-graph">
            <div className="apex-chart-wrapper">
              <Chart options={options} series={series} type="donut" height={380} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
