import React, { useRef, useState } from 'react';
import * as classNames from 'classnames';
import Dropdown from '../Dropdown/Dropdown';

export default function TooltipWithPortal(props) {
  const {
    content = '',
    tooltipContent = '',
    className = '',
    classNameWrapper = '',
    classNamePortal = '',
    showByClick = false,
    disable = false,
  } = props;
  const [isOpen, setOpen] = useState(false);
  const anchorElement = useRef(null);
  let useProps = {
    onMouseEnter: () => setOpen(true),
    onMouseLeave: () => setOpen(false),
  };
  if (showByClick && disable) {
    useProps = {
      onClick: event => {
        event.stopPropagation();
        setOpen(true);
      },
    };
  }
  return (
    <Dropdown
      usePortal
      customControl
      customOpen={isOpen}
      customHandleClose={() => { setOpen(false); }}
    >
      <Dropdown.Toggle>
        <div
          className={classNames('tooltip-content', className)}
          ref={anchorElement}
        >
          <div className="click-wrapper" {...useProps}>
            {content}
          </div>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu
        className={classNames('portal-tooltip-wrapper', classNameWrapper)}
        anchorElement={anchorElement}
        classNamePortal={classNames('portal-tooltip', classNamePortal)}
        {...useProps}
        useResizeContent={false}
      >
        <div
          onClick={e => e.stopPropagation()}
          className="portal-tooltip-popup"
        >
          {tooltipContent || content}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}
