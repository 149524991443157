import classNames from 'classnames';
import React, { Component } from 'react';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import './FileInputStyles.scss';
import { MAX_UPLOAD_FILE_COUNT, MAX_UPLOAD_FILE_SIZE } from '../../../configs';
import renderMessage from '../renderMessage';
import { required } from '../../../utils/validation';

class FileInput extends Component {
  state = {
    file: '',
    fileError: '',
  };

  componentDidMount() {
    this.setClear();
  }

  clearState = () => {
    this.setState({ file: '' });
  };

  setClear = () => {
    const { setClear = () => {} } = this.props;
    setClear(this.clearState);
  };

  onChange = event => {
    const {
      notSaveState = false,
      input: {
        onChange = () => {},
      },
      countElements = 0,
    } = this.props;
    const file = event.target.files[0];
    if (!file) {
      return;
    }
    if (countElements !== 0 && countElements >= MAX_UPLOAD_FILE_COUNT) {
      this.setState({ fileError: `Нельзя добавить более ${MAX_UPLOAD_FILE_COUNT} файлов` });
      return;
    }
    if (file.size > MAX_UPLOAD_FILE_SIZE) {
      this.setState({ fileError: `Размер файла не может превышать ${MAX_UPLOAD_FILE_SIZE / 1024 / 1024} мегабайта` });
      return;
    }
    this.setState({ fileError: '' });
    const data = new FormData();
    data.append('file', file);
    onChange(data);
    if (notSaveState) {
      event.target.value = '';
      return;
    }
    this.setState({ file: file.name });
  };

  renderLinkInput = () => {
    const { file, fileError } = this.state;
    const {
      input = '',
      label,
      placeholder = '',
      inputClassName = '',
      disabled = false,
      largeControl = false,
      readOnly = false,
      inputProps = {},
      dtoObject,
      fieldState = {
        error: '',
        touched: false,
      },
      controlId = `text-input-${Math.random() * 10000}`,
    } = this.props;
    const {
      error,
      isTouched: touched,
    } = fieldState;

    const isError = Boolean(error);
    const isRequired = dtoObject && dtoObject.validators && dtoObject.validators.length && !!dtoObject.validators.find(validator => validator.toString() === required.toString());
    return (
      <>
        <input
          type="file"
          className={classNames('form-file-input', inputClassName, { 'form-control-lg': largeControl }, { 'is-invalid': (isError && touched) || fileError })}
          id={controlId}
          aria-describedby={`${controlId}-help`}
          disabled={disabled}
          placeholder={placeholder}
          readOnly={readOnly}
          {...input}
          {...inputProps}
          onChange={this.onChange}
        />
        <label className="form-file-label" htmlFor={controlId}>
          <span className="form-file-button">
            {file || renderMessage(label)}
            {isRequired ? <span className="form-label-mark-required">*</span> : null}
          </span>
        </label>
      </>
    );
  };

  renderButtonInput = () => {
    const { file, fileError } = this.state;
    const {
      input = '',
      label,
      placeholder = '',
      inputClassName = '',
      disabled = false,
      largeControl = false,
      readOnly = false,
      inputProps = {},
      dtoObject,
      fieldState = {
        error: '',
        touched: false,
      },
      controlId = `text-input-${Math.random() * 10000}`,
    } = this.props;
    const {
      error,
      isTouched: touched,
    } = fieldState;
    const isError = Boolean(error);
    const isRequired = dtoObject && dtoObject.validators && dtoObject.validators.length && !!dtoObject.validators.find(validator => validator.toString() === required.toString());
    return (
      <>
        <input
          type="file"
          className={classNames('form-file-input', inputClassName, { 'form-control-lg': largeControl }, { 'is-invalid': (isError && touched) || fileError })}
          id={controlId}
          aria-describedby={`${controlId}-help`}
          disabled={disabled}
          placeholder={placeholder}
          readOnly={readOnly}
          {...input}
          {...inputProps}
          onChange={this.onChange}
        />
        <label className="form-file-label" htmlFor={controlId}>
          <span className="form-file-button">
            {file || renderMessage(label)}
            {isRequired ? <span className="form-label-mark-required">*</span> : null}
          </span>
        </label>
      </>
    );
  };

  renderDefaultInput = () => {
    const { file, fileError } = this.state;
    const {
      input = '',
      label,
      placeholder = '',
      inputClassName = '',
      disabled = false,
      largeControl = false,
      readOnly = false,
      inputProps = {},
      dtoObject,
      fieldState = {
        error: '',
        touched: false,
      },
      hideButton = false,
      buttonContent = 'Выбрать',
      controlId = `text-input-${Math.random() * 10000}`,
    } = this.props;
    const {
      error,
      isTouched: touched,
    } = fieldState;
    const isError = Boolean(error);
    const isRequired = dtoObject && dtoObject.validators && dtoObject.validators.length && !!dtoObject.validators.find(validator => validator.toString() === required.toString());
    return (
      <>
        <input
          type="file"
          className={classNames('form-file-input', inputClassName, { 'form-control-lg': largeControl }, { 'is-invalid': (isError && touched) || fileError })}
          id={controlId}
          aria-describedby={`${controlId}-help`}
          disabled={disabled}
          placeholder={placeholder}
          readOnly={readOnly}
          {...input}
          {...inputProps}
          onChange={this.onChange}
        />
        <label className="form-file-label" htmlFor={controlId}>
          <span className="form-file-text">
            {file || renderMessage(label)}
            {isRequired ? <span className="form-label-mark-required">*</span> : null}
          </span>
          {!hideButton ? <span className="form-file-button">{buttonContent}</span> : null}
        </label>
      </>
    );
  };

  render() {
    const {
      className = '',
      textMuted = '',
      withLabel = false,
      withOutLabel = false,
      fieldState = {
        error: '',
        touched: false,
      },
      controlId = `text-input-${Math.random() * 10000}`,
      variant = 'default',
      outLabel = '',
      disabled,
    } = this.props;
    const { fileError } = this.state;
    const {
      error,
      isTouched: touched,
    } = fieldState;
    const isError = Boolean(error);
    return (
      <>
        {withOutLabel ? <div className={classNames('form-out-label mt-label', { disabled, 'is-invalid': (error && touched) || fileError })}>{renderMessage(outLabel)}</div> : null}
        <div className={classNames('form-control-wrapper', 'form-file', className, {
          'mt-label': withLabel && !withOutLabel, 'link-file-input': variant === 'link', 'button-file-input': variant === 'button', disabled, 'is-invalid': (error && touched) || fileError,
        })}
        >
          {variant === 'default' ? this.renderDefaultInput() : null}
          {variant === 'link' ? this.renderLinkInput() : null}
          {variant === 'button' ? this.renderButtonInput() : null}
          {textMuted ? <div id={`${controlId}-help`} className="form-text">{renderMessage(textMuted)}</div> : null}
          {touched && isError ? <div className="invalid-feedback">{renderMessage(error)}</div> : null}
          {fileError ? <div className="invalid-feedback">{fileError}</div> : null}
        </div>
      </>
    );
  }
}

export default compose(
  injectIntl,
)(FileInput);
