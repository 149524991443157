import { put, call } from 'redux-saga/effects';
import { rejectActionPromise } from '../../../utils/rejectActionPromise';
import { resolveActionPromise } from '../../../utils/resolveActionPromise';
import { BACKEND_URL } from '../../../configs';
import makeRequest from '../../../utils/makeRequest';
import processError from '../../../utils/processError';
import { OK } from '../../../configs/serverStatusCodes';
import {
  loadActionHistoryFailed,
  loadActionHistorySuccessful,
  loadActionHistoryBegin,
} from '../actions';

export default function* loadActionHistory(action) {
  try {
    const {
      payload: {
        limit = 10,
        page = 1,
      } = {},
    } = action.payload;

    const filter = Object.entries(action.payload.payload.filter).reduce((previos, current) => {
      if (current[1]) {
        const [name, value] = current;
        previos[name] = value;
      }
      return previos;
    }, {});
    const url = `${BACKEND_URL}/actions`;
    const pool = { page, limit, ...{ ...filter } };
    yield put(loadActionHistoryBegin());
    const dataResponse = yield makeRequest(url, pool, action, 'GET', true);
    const data = yield dataResponse.json();
    if (dataResponse.status === OK) {
      data.result = data.data;
      yield put(loadActionHistorySuccessful(data));
      yield call(resolveActionPromise(action, data));
    } else {
      yield put(loadActionHistoryFailed());
      yield call(rejectActionPromise(action, data));
    }
  } catch (e) {
    yield put(loadActionHistoryFailed());
    yield call(rejectActionPromise(action, e));
    yield processError(e);
  }
}
