import React, { useState, useEffect } from 'react';
import './zones-page.scss';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { loadZoneList } from '../actions';
import { getPaginationOpts, responseLogWithNotification } from '../../../utils/utils';
import { getZoneData } from '../reducer';

import PageHeader from '../../../components/base/PageHeader/PageHeader';
import GridZoneCards from './GridZoneCards';

export default function ZonesPage() {
  const dispatch = useDispatch();

  const {
    zoneList,
    zoneStatusList,
    zoneListMeta,
  } = useSelector(getZoneData);

  const handleLoadingZoneList = opts => {
    // const filterData = getValues();
    if (opts && !opts.page) {
      opts.page = 1;
    }
    const paginationOpts = getPaginationOpts(zoneListMeta, opts);
    const requestData = {
      ...paginationOpts,
    };
    const action = loadZoneList;
    dispatch(loadZoneList(action(requestData))).catch(responseLogWithNotification);
  };

  useEffect(() => handleLoadingZoneList(), []);

  return (
    <div className="zones-page">
      <div className="zones-page-wrapper">
        <PageHeader header="Список зон" />
        <GridZoneCards zones={zoneList} />
      </div>
    </div>
  );
}
