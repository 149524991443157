import React from 'react';
import classNames from 'classnames';
import { Icons } from '../BootstrapComponents';
import './custom-button.scss';

export default function CustomButton(props) {
  const {
    text = '',
    icon = '',
    disable = false,
    isCancel = '',
    className = '',
    onClick = () => {},
  } = props;

  return (
    <div className={classNames('custom-button', { disable }, { isCancel }, className)} onClick={() => onClick()}>
      <Icons customIcon width={24} height={24} icon={icon} />
      <span>{text}</span>
    </div>
  );
}
