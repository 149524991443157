import { createAction } from 'redux-actions';
import { createDeferredAction } from '../../utils/redux';

import {
  BEGIN,
  CREATE,
  FAILED,
  SUCCESSFUL,
} from '../../configs/actionNames';
import { SESSION_LOG_PAGE_MODULE } from '../../configs/moduleNames';

export const LOAD_SESSION_LIST = '_LOAD_SESSION_LIST';
export const LOAD_SESSION_ID = '_LOAD_SESSION_ID';
export const LOAD_SESSION_STATUS_LIST = '_LOAD_SESSION_STATUS_LIST';

export const create = createDeferredAction(SESSION_LOG_PAGE_MODULE + CREATE);
export const createSuccessful = createAction(SESSION_LOG_PAGE_MODULE + CREATE + SUCCESSFUL);
export const createFailed = createAction(SESSION_LOG_PAGE_MODULE + CREATE + FAILED);

export const loadSessionList = createDeferredAction(SESSION_LOG_PAGE_MODULE + LOAD_SESSION_LIST);
export const loadSessionListBegin = createAction(SESSION_LOG_PAGE_MODULE + LOAD_SESSION_LIST + BEGIN);
export const loadSessionListSuccessful = createAction(SESSION_LOG_PAGE_MODULE + LOAD_SESSION_LIST + SUCCESSFUL);
export const loadSessionListFailed = createAction(SESSION_LOG_PAGE_MODULE + LOAD_SESSION_LIST + FAILED);

export const loadSessionStatusList = createDeferredAction(SESSION_LOG_PAGE_MODULE + LOAD_SESSION_STATUS_LIST);
export const loadSessionStatusListBegin = createAction(SESSION_LOG_PAGE_MODULE + LOAD_SESSION_STATUS_LIST + BEGIN);
export const loadSessionStatusListSuccessful = createAction(SESSION_LOG_PAGE_MODULE + LOAD_SESSION_STATUS_LIST + SUCCESSFUL);
export const loadSessionStatusListFailed = createAction(SESSION_LOG_PAGE_MODULE + LOAD_SESSION_STATUS_LIST + FAILED);

export const loadSessionId = createDeferredAction(SESSION_LOG_PAGE_MODULE + LOAD_SESSION_ID);
export const loadSessionIdBegin = createAction(SESSION_LOG_PAGE_MODULE + LOAD_SESSION_ID + BEGIN);
export const loadSessionIdSuccessful = createAction(SESSION_LOG_PAGE_MODULE + LOAD_SESSION_ID + SUCCESSFUL);
export const loadSessionIdFailed = createAction(SESSION_LOG_PAGE_MODULE + LOAD_SESSION_ID + FAILED);
