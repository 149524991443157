import { createAction } from 'redux-actions';
import { createDeferredAction } from '../../utils/redux';
import { MODULE_NAME } from './constants';

import {
  BEGIN,
  CREATE,
  FAILED,
  SUCCESSFUL,
} from '../../configs/actionNames';

export const LOAD_USER_LIST = '_LOAD_USER_LIST';
export const LOAD_ROLE_LIST = '_LOAD_ROLE_LIST';
export const LOAD_PERMISSION_LIST = '_LOAD_PERMISSION_LIST';
export const UPDATE_ROLE = '_UPDATE_ROLE';
export const UPDATE_USER = '_UPDATE_USER';
export const REMOVE_USER = '_REMOVE_USER';
export const REMOVE_ROLE = '_REMOVE_ROLE';
export const CREATE_USER = '_CREATE_USER';
export const UPDATE_AVATAR = '_UPDATE_AVATAR';
export const DELETE_USER = '_DELETE_USER';

export const create = createDeferredAction(MODULE_NAME + CREATE);
export const createSuccessful = createAction(MODULE_NAME + CREATE + SUCCESSFUL);
export const createFailed = createAction(MODULE_NAME + CREATE + FAILED);

export const loadUserList = createDeferredAction(MODULE_NAME + LOAD_USER_LIST);
export const loadUserListBegin = createAction(MODULE_NAME + LOAD_USER_LIST + BEGIN);
export const loadUserListSuccessful = createAction(MODULE_NAME + LOAD_USER_LIST + SUCCESSFUL);
export const loadUserListFailed = createAction(MODULE_NAME + LOAD_USER_LIST + FAILED);

export const loadRoleList = createDeferredAction(MODULE_NAME + LOAD_ROLE_LIST);
export const loadRoleListBegin = createAction(MODULE_NAME + LOAD_ROLE_LIST + BEGIN);
export const loadRoleListSuccessful = createAction(MODULE_NAME + LOAD_ROLE_LIST + SUCCESSFUL);
export const loadRoleListFailed = createAction(MODULE_NAME + LOAD_ROLE_LIST + FAILED);

export const loadPermissionList = createDeferredAction(MODULE_NAME + LOAD_PERMISSION_LIST);
export const loadPermissionListBegin = createAction(MODULE_NAME + LOAD_PERMISSION_LIST + BEGIN);
export const loadPermissionListSuccessful = createAction(MODULE_NAME + LOAD_PERMISSION_LIST + SUCCESSFUL);
export const loadPermissionListFailed = createAction(MODULE_NAME + LOAD_PERMISSION_LIST + FAILED);

export const updateRole = createDeferredAction(MODULE_NAME + UPDATE_ROLE);
export const updateRoleBegin = createAction(MODULE_NAME + UPDATE_ROLE + BEGIN);
export const updateRoleSuccessful = createAction(MODULE_NAME + UPDATE_ROLE + SUCCESSFUL);
export const updateRoleFailed = createAction(MODULE_NAME + UPDATE_ROLE + FAILED);

export const createUser = createDeferredAction(MODULE_NAME + CREATE_USER);
export const createUserSuccessful = createAction(MODULE_NAME + CREATE_USER + SUCCESSFUL);
export const createUserFailed = createAction(MODULE_NAME + CREATE_USER + FAILED);

export const updateUser = createDeferredAction(MODULE_NAME + UPDATE_USER);
export const updateUserSuccessful = createAction(MODULE_NAME + UPDATE_USER + SUCCESSFUL);
export const updateUserFailed = createAction(MODULE_NAME + UPDATE_USER + FAILED);

export const removeUser = createDeferredAction(MODULE_NAME + REMOVE_USER);
export const removeUserSuccessful = createAction(MODULE_NAME + REMOVE_USER + SUCCESSFUL);
export const removeUserFailed = createAction(MODULE_NAME + REMOVE_USER + FAILED);

export const removeRole = createDeferredAction(MODULE_NAME + REMOVE_ROLE);
export const removeRoleSuccessful = createAction(MODULE_NAME + REMOVE_ROLE + SUCCESSFUL);
export const removeRoleFailed = createAction(MODULE_NAME + REMOVE_ROLE + FAILED);

export const updateAvatar = createDeferredAction(MODULE_NAME + UPDATE_AVATAR);
export const updateAvatarSuccessful = createAction(MODULE_NAME + UPDATE_AVATAR + SUCCESSFUL);
export const updateAvatarFailed = createAction(MODULE_NAME + UPDATE_AVATAR + FAILED);

export const deleteUser = createDeferredAction(MODULE_NAME + DELETE_USER);
export const deleteUserSuccessful = createAction(MODULE_NAME + DELETE_USER + SUCCESSFUL);
export const deleteUserFailed = createAction(MODULE_NAME + DELETE_USER + FAILED);
