import React, { Component } from 'react';
import classNames from 'classnames';

export function Icons(props) {
  const {
    onClick,
    icon = '',
    className = '',
    width = 24,
    height = 24,
    fill = '#000000',
    style = {},
    customIcon = false,
  } = props;

  if (!icon) {
    return '';
  }
  const addProps = {};
  if (onClick) {
    addProps.onClick = onClick;
  }

  if (customIcon) {
    return (
      <svg className={classNames('bi', 'bootstrap-icon', className)} style={style} width={width} height={height} fill={fill} {...addProps}>
        <use xlinkHref={`${process.env.PUBLIC_URL}/icons/custom-icons.svg#${icon}`} />
      </svg>
    );
  }

  return (
    <svg className={classNames('bi', 'bootstrap-icon', className)} onClick={onClick} style={style} width={width} height={height} fill={fill} {...addProps}>
      <use xlinkHref={`${process.env.PUBLIC_URL}/icons/bootstrap-icons.svg#${icon}`} />
    </svg>
  );
}

export function Container(props) {
  const {
    className = '',
    children,
    fluid,
  } = props;
  const suffix = typeof fluid === 'string' ? `-${fluid}` : '-fluid';
  const prefix = 'container';
  const propsToComponent = { ...props };
  delete propsToComponent.fluid;
  return (
    <div
      {...propsToComponent}
      className={classNames(suffix ? `${prefix}${suffix}` : prefix, className)}
    >
      {children}
    </div>
  );
}

export function Row(props) {
  const {
    className = '',
    children,
  } = props;

  return (
    <div {...props} className={classNames('row', className)}>
      {children}
    </div>
  );
}

export function Col(props) {
  const {
    className = '',
    children,
    columns = null,
  } = props;

  return (
    <div {...props} className={classNames(columns ? `col-${columns}` : 'col', className)}>
      {children}
    </div>
  );
}

export function Alert(props) {
  const {
    className = '',
    children,
    variant = null,
    onClick,
  } = props;
  const addProps = {};
  if (onClick) {
    addProps.onClick = onClick;
  }
  return (
    <div className={classNames('alert', variant && `alert-${variant}`, className)} role="alert" {...addProps}>
      {children}
    </div>
  );
}

export class Button extends Component {
  render() {
    const {
      className = '',
      children,
      variant = 'primary',
      size,
      type = 'button',
      setRef,
    } = this.props;
    const prefix = 'btn';
    let addRef = {};
    if (setRef && typeof setRef === 'function') {
      addRef = { ref: setRef };
    }
    const clonedProps = { ...this.props };
    delete clonedProps.setRef;
    return (
      <button
        type={type}
        {...addRef}
        {...clonedProps}
        className={classNames(
          'btn',
          variant ? `btn-${variant}` : '',
          className,
          size && `${prefix}-${size}`,
        )}
      >
        {children}
      </button>
    );
  }
}

export class Menu extends Component {
  constructor(props) {
    super(props);
    this.wrapperRef = null;
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  setRef = ref => {
    this.wrapperRef = ref;
  };

  handleClickOutside = event => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      const { onClose = () => {} } = this.props;
      onClose();
    }
  };

  render() {
    const {
      className = '',
      children,
    } = this.props;
    return (
      <div {...this.props} className={classNames('menu', className)} ref={this.setRef}>
        {children}
      </div>
    );
  }
}
