import { takeLatest } from 'redux-saga/effects';
import { MODULE_NAME } from '../constants';
import { LOAD_HOST_LIST, LOAD_ACTION_HISTORY } from '../actions';

import loadHostList from './loadHostList';
import loadActionHistory from './loadActionHistory';
import loadClientTypeList from '../../Clients/sagas/loadClientTypeList';
import loadZoneList from '../../Zones/sagas/loadZoneList';

export default function* sagas() {
  yield takeLatest(MODULE_NAME + LOAD_HOST_LIST, loadHostList);
  yield takeLatest(MODULE_NAME + LOAD_ACTION_HISTORY, loadActionHistory);
  yield takeLatest(MODULE_NAME + LOAD_HOST_LIST, loadClientTypeList);
  yield takeLatest(MODULE_NAME + LOAD_HOST_LIST, loadZoneList);
}
