import React, { Component } from 'react';
import classNames from 'classnames';
import { DEFAULT_ITEMS_OF_PAGE, ITEMS_OF_PAGE } from '../../../configs';
import SelectInput from '../../fields/SelectInput/SelectInput';
import { Icons } from '../BootstrapComponents';
import './PaginationStyles.scss';

export default function Pagination(props) {
  const {
    meta = {
      limit: DEFAULT_ITEMS_OF_PAGE,
      page: 1,
      total: 0,
    },
    handleChangeMeta = () => {},
    list = [],
  } = props;
  const {
    limit = DEFAULT_ITEMS_OF_PAGE,
    page = 1,
    total = 0,
  } = meta;
  const countPages = Math.ceil(total / limit) || 1;
  const isDisabledPrev = Number(page) <= 1;
  const isDisabledNext = Number(page) >= countPages;
  let startPage = 1;
  if (page > 3) {
    startPage = page - 2;
  }
  if (startPage + 4 > countPages) {
    startPage = countPages - 4;
    if (startPage < 1) {
      startPage = 1;
    }
  }
  const endPage = startPage + ((countPages) < 5 ? countPages : 5);
  const handlePageChange = page => () => {
    handleChangeMeta({ page });
  };
  const handleLimitChange = event => {
    handleChangeMeta({ limit: Number(event.target.value) || DEFAULT_ITEMS_OF_PAGE, page: 1 });
  };

  const getPaginationItems = (page, countPages) => {
    const paginationItems = [];
    for (let number = startPage; number < endPage; number++) {
      paginationItems.push(renderItem({
        key: number,
        content: number,
        label: `Page ${number}`,
        className: number === page ? 'active' : '',
        onClick: handlePageChange(number),
      }));
    }
    return paginationItems;
  };

  const renderItem = opt => {
    const {
      disabled = false,
      content = null,
      className = '',
      key,
      onClick = () => {},
    } = opt;
    return (
      <span key={key || Math.random()} className={classNames('pagination-button', { disabled }, className)} onClick={disabled ? () => {} : onClick}>
        <span className="page-link">
          <span>{content}</span>
        </span>
      </span>
    );
  };
  return (
    <div className="grid-pagination">
      <div className="pagination-info">
        <span className="pagination-count-text">Строк</span>
        <SelectInput
          className="pagination-page-limit-select lite-select ms-3"
          withLabel={false}
          options={ITEMS_OF_PAGE || [DEFAULT_ITEMS_OF_PAGE]}
          input={{
            value: limit,
            onChange: handleLimitChange,
          }}
        />
        <span className="pagination-count-value ms-4">
          {list.length || 0}<span className="pagination-count-total">{' из '} {total}</span>
        </span>
      </div>
      <span className="pagination-pages">
        <span
          onClick={!isDisabledPrev ? handlePageChange(Number(page) - 1) : () => {}}
          className={classNames('pagination-button')}
        >
          <Icons
            className={classNames('item-icon', { disabled: isDisabledPrev })}
            icon="chevron-left"
            customIcon
          />
        </span>
        {startPage !== 1
          ? (
            <>
              <span className={classNames('pagination-button')} onClick={handlePageChange(1)}>
                <span className="page-link">
                  <span>1</span>
                </span>
              </span>
              <span className={classNames('pagination-button')}>
                ...
              </span>
            </>
          )
          : null}

        {getPaginationItems(Number(page), countPages)}
        {endPage - 1 !== countPages
          ? (
            <>
              <span className={classNames('pagination-button')}>
                ...
              </span>
              <span className={classNames('pagination-button')} onClick={handlePageChange(countPages)}>
                <span className="page-link">
                  <span>{countPages}</span>
                </span>
              </span>
            </>
          )
          : null}
        <span
          onClick={!isDisabledNext ? handlePageChange(Number(page) + 1) : () => {}}
          className={classNames('pagination-button')}
        >
          <Icons
            className={classNames('item-icon', { disabled: isDisabledNext })}
            icon="chevron-right"
            customIcon
          />
        </span>
      </span>
    </div>
  );
}
