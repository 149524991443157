import { handleActions } from 'redux-actions';

import { createLoadListReducerWithBegin } from '../../utils/getCrudConfig';
import { MODULE_NAME } from './constants';
import { EVENT_LOG_PAGE_MODULE } from '../../configs/moduleNames';
import {
  LOAD_EVENT_LIST, LOAD_EVENT_STATUS_LIST,
} from './actions';

const initialState = {
  eventList: [],
  eventListMeta: [],
  eventStatusList: [],
};

const reducer = handleActions({
  ...createLoadListReducerWithBegin(MODULE_NAME + LOAD_EVENT_LIST, 'eventList', 'loadingeventList'),
  ...createLoadListReducerWithBegin(MODULE_NAME + LOAD_EVENT_STATUS_LIST, 'eventStatusList', 'loadEventStatusList'),
}, initialState);

export default reducer;

export const getEventData = state => {
  const reducerData = state[EVENT_LOG_PAGE_MODULE];
  const {
    eventList,
    eventListMeta,
    eventStatusList,
  } = reducerData;

  return {
    eventList,
    eventStatusList,
    eventListMeta,
  };
};
