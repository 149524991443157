import { call, put } from 'redux-saga/effects';
import { BACKEND_URL } from '../../../configs';
import { rejectActionPromise } from '../../../utils/rejectActionPromise';
import { resolveActionPromise } from '../../../utils/resolveActionPromise';

import { createUserFailed, createUserSuccessful } from '../actions';
import { CREATED } from '../../../configs/serverStatusCodes';
import makeRequest from '../../../utils/makeRequest';
import processError from '../../../utils/processError';

export default function* createUser(action) {
  try {
    const pool = action.payload;
    const url = `${BACKEND_URL}/users`;
    const dataResponse = yield makeRequest(url, pool, action, 'POST', true);
    const data = yield dataResponse.json();
    if (dataResponse.status === CREATED
      && data
    ) {
      yield put(createUserSuccessful([]));
      yield call(resolveActionPromise(action, data));
    } else {
      yield put(createUserFailed([]));
      yield call(rejectActionPromise(action, data));
    }
  } catch (e) {
    yield put(createUserFailed([]));
    yield call(rejectActionPromise(action, e));
    yield processError(e);
  }
}
