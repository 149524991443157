import React, { useState } from 'react';
import Pagination from '../../../components/base/Pagination/Pagination';

import {
  EquipmentFormatter,
  visualFormatter,
  MoreFormatter,
  logFormatter,
  controlBoxFormatter,
  HostNameAndStatusFormatter,
} from '../../../utils/formatters';
import CustomizedGrid from '../../../components/base/Grid/CustomizedGrid';

export default function HostGrid(props) {
  const defaultColumns = [
    {
      dataField: 'host',
      text: 'Объект',
      formatter: (_, a) => (<HostNameAndStatusFormatter item={a} isComponentBase />),
      disableSort: true,
      hidden: true,
      headerClassName: 'column-0',
    },
    {
      dataField: 'equipments',
      text: 'Периферийные модули',
      formatter: (_, a) => (<EquipmentFormatter item={a} />),
      disableSort: true,
      dataClassName: 'align-middle',
      headerClassName: 'column-1',
      hidden: true,
    },
    {
      dataField: 'vehicle_detection_state',
      text: 'Визуализация',
      formatter: visualFormatter,
      disableSort: true,
      dataClassName: 'align-middle',
      headerClassName: 'column-2',
      hidden: true,
    },
    {
      dataField: 'description',
      text: 'Последние данные',
      formatter: logFormatter,
      disableSort: true,
      dataClassName: 'align-middle',
      headerClassName: 'column-4',
      hidden: true,
    },
    {
      dataField: 'available_commands',
      text: 'Доступные действия',
      formatter: controlBoxFormatter,
      disableSort: true,
      dataClassName: 'align-middle',
      headerClassName: 'align-middle column-5',
      hidden: true,
    },
  ];

  const {
    data,
    handleLoadingHostList,
    onClickByRow,
    meta,
    columns = defaultColumns,
  } = props;

  return (
    <div className="host-table g-0">
      <div className="frame">
        <div className="sticky-table-wrapper custom-scroll-theme">
          <CustomizedGrid className="host-grid" data={data} columns={columns} name="session-list" onClickByRow={onClickByRow} settingsFormatter={MoreFormatter} />
        </div>
      </div>
      <Pagination meta={meta} list={data} handleChangeMeta={handleLoadingHostList} />
    </div>
  );
}
