import { createAction } from 'redux-actions';
import { createDeferredAction } from '../../utils/redux';
import { MODULE_NAME } from './constants';
import {
  BEGIN,
  CREATE,
  DELETE_BY_ID,
  FAILED,
  LOAD_BY_ID,
  LOAD_LIST,
  SUCCESSFUL,
  UPDATE,
} from '../../configs/actionNames';

export const create = createDeferredAction(MODULE_NAME + CREATE);
export const createSuccessful = createAction(MODULE_NAME + CREATE + SUCCESSFUL);
export const createFailed = createAction(MODULE_NAME + CREATE + FAILED);

export const updateById = createDeferredAction(MODULE_NAME + UPDATE);
export const updateByIdSuccessful = createAction(MODULE_NAME + UPDATE + SUCCESSFUL);
export const updateByIdFailed = createAction(MODULE_NAME + UPDATE + FAILED);

export const loadById = createDeferredAction(MODULE_NAME + LOAD_BY_ID);
export const loadByIdSuccessful = createAction(MODULE_NAME + LOAD_BY_ID + SUCCESSFUL);
export const loadByIdFailed = createAction(MODULE_NAME + LOAD_BY_ID + FAILED);

export const loadList = createDeferredAction(MODULE_NAME + LOAD_LIST);
export const loadListBegin = createAction(MODULE_NAME + LOAD_LIST + BEGIN);
export const loadListSuccessful = createAction(MODULE_NAME + LOAD_LIST + SUCCESSFUL);
export const loadListFailed = createAction(MODULE_NAME + LOAD_LIST + FAILED);

export const deleteById = createDeferredAction(MODULE_NAME + DELETE_BY_ID);
export const deleteByIdSuccessful = createAction(MODULE_NAME + DELETE_BY_ID + SUCCESSFUL);
export const deleteByIdFailed = createAction(MODULE_NAME + DELETE_BY_ID + FAILED);
