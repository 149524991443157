module.exports = {
  name: 'gregorian_ru',
  months: [
    ['Январь', 'Янв'],
    ['Февраль', 'Февр'],
    ['Март', 'Март'],
    ['Апрель', 'Апр'],
    ['Май', 'Май'],
    ['Июнь', 'Июнь'],
    ['Июль', 'Июль'],
    ['Август', 'Авг'],
    ['Сентябрь', 'Сент'],
    ['Октябрь', 'Окт'],
    ['Ноябрь', 'Нояб'],
    ['Декабрь', 'Дек'],
  ],
  weekDays: [
    ['Saturday', 'Сб'],
    ['Sunday', 'Вс'],
    ['Monday', 'Пн'],
    ['Tuesday', 'Вт'],
    ['Wednesday', 'Ср'],
    ['Thursday', 'Чт'],
    ['Friday', 'Пт'],
  ],
  digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
  meridiems: [
    ['AM', 'am'], ['PM', 'pm'],
  ],
};
