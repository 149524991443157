import { HIDE_SYSTEM_ROUTE } from '../../configs';
import { GUEST_MODULE, SYSTEM_MODULE } from '../../configs/moduleNames';
import LogoutPage from './components/LogoutPage';
import LoginPage from './components/LoginPage';

const routes = [
  {
    path: '/logout',
    name: 'menu.logout',
    permission: SYSTEM_MODULE,
    hideOnMenu: HIDE_SYSTEM_ROUTE,
    icon: 'pe-7s-power',
    loginRequired: true,
    component: LogoutPage,
    weight: 999,
  },
  {
    path: '/logout',
    name: 'menu.logout',
    permission: SYSTEM_MODULE,
    hideOnMenu: HIDE_SYSTEM_ROUTE,
    icon: 'pe-7s-power',
    loginRequired: false,
    component: LogoutPage,
    weight: 999,
  },
  {
    path: '/',
    name: 'menu.login',
    permission: GUEST_MODULE,
    loginRequired: false,
    component: LoginPage,
    weight: 999,
  },
  {
    path: '/logout',
    name: 'menu.logout',
    permission: SYSTEM_MODULE,
    hideOnMenu: HIDE_SYSTEM_ROUTE,
    icon: 'pe-7s-power',
    loginRequired: true,
    forMobile: true,
    component: LogoutPage,
    weight: 999,
  },
  {
    path: '/logout',
    name: 'menu.logout',
    permission: SYSTEM_MODULE,
    hideOnMenu: HIDE_SYSTEM_ROUTE,
    icon: 'pe-7s-power',
    loginRequired: false,
    forMobile: true,
    component: LogoutPage,
    weight: 999,
  },
  {
    path: '/',
    name: 'menu.login',
    permission: GUEST_MODULE,
    loginRequired: false,
    component: LoginPage,
    forMobile: true,
    weight: 999,
  },
  // {
  //   path: '/registration',
  //   name: 'menu.registration',
  //   permission: GUEST_MODULE,
  //   hideOnMenu: HIDE_SYSTEM_ROUTE,
  //   loginRequired: false,
  //   component: RegistrationPage,
  //   weight: 999,
  // },
];

export default routes;
