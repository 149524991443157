import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { getClientPersonalData } from '../modules/Client/reducer';
import { Icons } from './base/BootstrapComponents';
import UpdatePeriodSelectInput from './fields/SelectInput/UpdatePeriodSelectInput';
import { updatePeriods } from '../modules/Hosts/components/moks';
import TooltipWithPortal from './base/Tooltip/TooltipWithPortal';

export default function CheckBoxInputWithLabel(props) {
  const {
    name,
    roles,
    avatar_url,
    updated_at,
  } = useSelector(getClientPersonalData);
  const defaultAvatar = `${process.env.PUBLIC_URL}/default-avatar.svg`;
  const avatar = avatar_url || defaultAvatar;
  const avatarLink = `${avatar}?v=${updated_at}`;
  const [filter, setFilter] = useState(0);

  return (
    <div className="mini-profile-anchor">
      <div className="mini-profile-wrapper">
        <UpdatePeriodSelectInput
          className="small parking-meter-state-select"
          name="updatePeriod"
          inputProps={{
            onChange: e => {
              setFilter(Number(e.target.value));
            },
            value: filter,
          }}
          options={updatePeriods}
        />
        <div className="mini-profile-separator" />
        <div className="mini-profile-data">
          <div className="profile-data-name">{name}</div>
          <div className="profile-data-position f8">
            <TooltipWithPortal content={roles ? roles.map(role => role.name).join(', ') : 'Пользователь'} />
          </div>
        </div>
        <div className="mini-profile-image">
          <img src={avatarLink} alt="A" onError={a => { a.target.src = defaultAvatar; }} />
        </div>
      </div>
    </div>
  );
}
