import React from 'react';
import Notifications from '../../modules/Notifications/components/Notifications';

export default function LoginLayout({ children }) {
  return (
    <>
      <Notifications />
      { children }
    </>
  );
}
