import React from 'react';
import classNames from 'classnames';
import {
  Dialog, DialogContent, DialogFooter, DialogTitle,
} from '../../../components/base/Dialog/Dialog';
import { Icons } from '../../../components/base/BootstrapComponents';
import CustomButton from '../../../components/base/Button/CustomButton';

export default function HostDeletePopUp(props) {
  const {
    onHide,
    user_ids,
    removeClient = () => {},
    setSelected,
  } = props;
  return (
    <Dialog
      className={classNames('client-popup-delete')}
      onClose={() => { onHide(); }}
      size="xl"
      open
    >
      <DialogTitle
        className="hff-title"
        title={(
          <div className="hff-title-text f2">{user_ids && user_ids.length > 1 ? 'Удалить хосты?' : `Удалить хост ${user_ids[0]}` }</div>
        )}
      />
      <DialogContent className="hff-content">
        {user_ids && user_ids.length > 1 ? (
          user_ids.map((id, index) => (
            <div key={index} className="delete-item">
              <div className="delete-item-text">{id}</div>
              <Icons
                customIcon
                className="hff-close"
                onClick={() => {
                  setSelected(user_ids.filter(userId => userId !== id));
                }}
                width={14}
                height={14}
                icon="clear"
              />
            </div>
          ))
        ) : null}
      </DialogContent>
      <DialogFooter className="hff-footer">
        <CustomButton text="Удалить" onClick={e => { removeClient(user_ids)(e); onHide(); }} />
        <CustomButton isCancel text="Отменить" onClick={() => { onHide(); setSelected([]); }} />
      </DialogFooter>
    </Dialog>
  );
}
