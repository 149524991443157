import { CLIENT_MODULE, SYSTEM_MODULE } from '../configs/moduleNames';
import { responseLog } from './utils';

const isPromise = val => val && typeof val.then === 'function';

export const promiseMiddleware = ({ dispatch }) => next => action => {
  const { payload, ...rest } = action;
  if (payload === undefined) {
    return isPromise(action)
      ? action.then(dispatch)
      : next(action);
  }
  const digested = next({
    ...rest,
    payload,
  });
  return digested;
};

export const deferredMiddleware = () => next => action => {
  const { promiseId } = action;
  next(action);
  return promiseId && window.actionsPromises[promiseId] ? window.actionsPromises[promiseId].promise : action;
};

export const createDeferredAction = (actionType, payloadCreator = a => a) => (...args) => {
  let resolvePromise;
  let rejectPromise;

  const promise = new Promise((resolve, reject) => {
    resolvePromise = resolve;
    rejectPromise = reject;
  });
  promise.catch(responseLog);
  const promiseId = String(new Date().getTime()) + Math.ceil(Math.random() * 10000000);
  window.actionsPromises[promiseId] = {
    promise,
    resolvePromise,
    rejectPromise,
  };
  return {
    type: actionType,
    payload: payloadCreator(...args),
    promiseId,
  };
};

export const getToken = state => {
  const {
    [CLIENT_MODULE]: {
      token = null,
    },
  } = state;
  return token;
};

export const getLocale = state => {
  const {
    [SYSTEM_MODULE]: {
      locale = null,
    },
  } = state;
  return locale;
};

export const getListData = (list = []) => list.map(item => (item.getData()));
