import { call, put } from 'redux-saga/effects';
import { rejectActionPromise } from '../../../utils/rejectActionPromise';
import { resolveActionPromise } from '../../../utils/resolveActionPromise';
import { MODULE_BACK_PATH, MODULE_DTO } from '../constants';
import { BACKEND_URL } from '../../../configs';
import makeRequest from '../../../utils/makeRequest';
import { OK } from '../../../configs/serverStatusCodes';
import processError from '../../../utils/processError';
import { updateByIdFailed, updateByIdSuccessful } from '../actions';

export default function* updateById(action) {
  try {
    const item = new MODULE_DTO(action.payload);
    const url = `${BACKEND_URL}/${MODULE_BACK_PATH}/${item.id}`;
    const pool = item.getData();
    const dataResponse = yield makeRequest(url, pool, action, 'PATCH', true);
    const data = yield dataResponse.json();
    if (dataResponse.status === OK) {
      yield put(updateByIdSuccessful(data));
      yield call(resolveActionPromise(action, data));
    } else {
      yield put(updateByIdFailed(data));
      yield call(rejectActionPromise(action, data));
    }
  } catch (e) {
    yield put(updateByIdFailed([]));
    yield processError(e);
  }
}
