import { handleActions } from 'redux-actions';

import {
  createLoadListReducerWithBegin, createOperationStateReducer, createOperationStateReducerWithBegin,
} from '../../utils/getCrudConfig';
import { MODULE_NAME } from './constants';
import { ROLE_MODULE } from '../../configs/moduleNames';
import {
  CREATE_USER,
  LOAD_PERMISSION_LIST,
  LOAD_ROLE_LIST,
  LOAD_USER_LIST,
  REMOVE_ROLE,
  REMOVE_USER, UPDATE_AVATAR, UPDATE_ROLE,
  UPDATE_USER,
} from './actions';
import { CREATE } from '../../configs/actionNames';

const initialState = {
  loadingUserList: false,
  userList: [],
  userListMeta: {},
  loadingRoleList: false,
  roleList: [],
  roleListMeta: {},
  loadingPermissionList: false,
  permissionList: [],
  permissionListMeta: {},
  loadingCreateUser: false,
  loadingUpdateUser: false,
  loadingRemoveUser: false,
  loadingCreateRole: false,
  loadingUpdateRole: false,
  loadingRemoveRole: false,
  loadingUpdateAvatar: false,
};

const reducer = handleActions({
  ...createLoadListReducerWithBegin(MODULE_NAME + LOAD_USER_LIST, 'userList', 'loadingUserList'),
  ...createLoadListReducerWithBegin(MODULE_NAME + LOAD_ROLE_LIST, 'roleList', 'loadingRoleList'),
  ...createLoadListReducerWithBegin(MODULE_NAME + LOAD_PERMISSION_LIST, 'permissionList', 'loadingPermissionList'),
  ...createOperationStateReducer(MODULE_NAME + CREATE_USER, 'loadingCreateUser'),
  ...createOperationStateReducer(MODULE_NAME + UPDATE_USER, 'loadingUpdateUser'),
  ...createOperationStateReducer(MODULE_NAME + REMOVE_USER, 'loadingRemoveUser'),
  ...createOperationStateReducer(MODULE_NAME + CREATE, 'loadingCreateRole'),
  ...createOperationStateReducerWithBegin(MODULE_NAME + UPDATE_ROLE, 'loadingUpdateRole'),
  ...createOperationStateReducer(MODULE_NAME + REMOVE_ROLE, 'loadingRemoveRole'),
  ...createOperationStateReducer(MODULE_NAME + UPDATE_AVATAR, 'loadingUpdateAvatar'),

}, initialState);

export default reducer;

export const getRoleData = state => {
  const reducerData = state[ROLE_MODULE];
  const {
    loadingUserList,
    userList,
    userListMeta,
    loadingRoleList,
    roleList,
    roleListMeta,
    loadingPermissionList,
    permissionList,
    permissionListMeta,
    loadingCreateUser,
    loadingUpdateUser,
    loadingRemoveUser,
    loadingCreateRole,
    loadingUpdateRole,
    loadingRemoveRole,
    loadingUpdateAvatar,
  } = reducerData;

  return {
    loadingUserList,
    userList,
    userListMeta,
    loadingRoleList,
    roleList,
    roleListMeta,
    loadingPermissionList,
    permissionList,
    permissionListMeta,
    loadingCreateUser,
    loadingUpdateUser,
    loadingRemoveUser,
    loadingCreateRole,
    loadingUpdateRole,
    loadingRemoveRole,
    loadingUpdateAvatar,
  };
};
