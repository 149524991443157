export const rejectActionPromise = (action, data) => () => {
  if (action
    && action.promiseId
    && window.actionsPromises[action.promiseId]
    && window.actionsPromises[action.promiseId].rejectPromise
    && !window.actionsPromises[action.promiseId].final
  ) {
    if (data) {
      window.actionsPromises[action.promiseId].rejectPromise(data);
    } else {
      window.actionsPromises[action.promiseId].rejectPromise();
    }

    window.actionsPromises[action.promiseId].final = true;
  }
};
