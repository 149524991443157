import ZonesPage from './components/ZonesPage';

const routes = [
  {
    path: '/zones-page/:page?',
    name: 'menu.zones-page',
    icon: 'mon-zones',
    customIcon: true,
    loginRequired: true,
    component: ZonesPage,
    weight: 101,
    permission: [
      'frontend_menu_zones',
    ],
    children: [],
  },
];

export default routes;
