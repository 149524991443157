/* eslint-disable no-unused-vars */
import { call, put, select } from 'redux-saga/effects';
import { getLocale, getToken } from './redux';
import { CONNECTION_ERROR } from '../configs/serverStatusCodes';
import { rejectActionPromise } from './rejectActionPromise';
import { clientLogout } from '../modules/Client/actions';

function* doRequest(url, method, token, pool, locale, headers, noStringify) {
  const data = {
    method,
    cache: 'no-cache',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Accept-Language': locale,
      Authorization: token,
    },
  };
  data.headers = { ...data.headers, ...headers };
  const urlWithQuery = new URL(url);
  if (pool) {
    if (method !== 'GET') {
      if (noStringify) {
        data.body = pool;
        delete data.headers['Content-Type'];
      } else {
        data.body = JSON.stringify(pool);
      }
    } else {
      Object.keys(pool).forEach(key => {
        if (key === 'filter' || key === 'sort') {
          pool[key].forEach(item => urlWithQuery.searchParams.append(key, item));
        } else {
          urlWithQuery.searchParams.append(key, pool[key]);
        }
      });
    }
  }
  let fetchData = null;
  try {
    fetchData = yield fetch(urlWithQuery, data);
  } catch (e) {
    fetchData = { status: CONNECTION_ERROR, message: 'Проблема с подключением к сети' };
  }
  return fetchData;
}

export default function* makeRequest(url, pool = {}, action, method = 'POST', removeToken = false, headers = {}, noStringify = false) {
  const token = yield select(getToken);
  const locale = yield select(getLocale);

  try {
    const userDataResponse = yield doRequest(url, method, token, pool, locale, headers, noStringify);
    if (userDataResponse.status === 401 && !url.includes('/auth/login')) {
      yield put(clientLogout());
      window.location.href = '/';
    }
    return userDataResponse;
  } catch (e) {
    const userDataResponse = { status: CONNECTION_ERROR, message: 'Проблема с подключением к сети' };
    yield call(rejectActionPromise(action, userDataResponse));
    return userDataResponse;
  }
}
