import React, { useState } from 'react';
import classNames from 'classnames';
import { Icons } from '../../../components/base/BootstrapComponents';
import { intlShape } from '../../../components/base/IntlProviderWrapper';
import ZonesEditPopUp from './ZonesEditPopUp';
import ZonesAddOptionPopUp from './ZonesAddOptionPopUp';

export default function ZoneCard(props) {
  const {
    item,
    isMiniatures,
    isDetail,
  } = props;
  const {
    formatMessage,
  } = intlShape;

  const [isEditPopUp, setIsEditPopUp] = useState(false);
  const [isAddPopUp, setIsAddPopUp] = useState(false);

  return (
    <div className={classNames('zone', { isMiniatures }, { isDetail })}>
      <div className="zone-title">
        {item.name}
        <div className="zone-title-control-button">
          <div className="zone-title-control-button-box" onClick={() => setIsEditPopUp(true)}>
            <Icons width={24} height={24} customIcon icon="pencile" className="button-icons" />
          </div>
          {/* <div className="zone-title-control-button-box" onClick={() => setIsAddPopUp(true)}>
            <Icons width={24} height={24} customIcon icon="squad" className="button-icons" />
          </div> */}
        </div>
      </div>
      <div className="zone-info">
        <div className="zone-info-statuses">
          <div className="status">
            <div className="status-title">
              <Icons width={24} height={24} customIcon icon="error" className="button-icons" />
              {formatMessage({ id: 'zones.busy' })}
            </div>
            <span className="status-count">{item.busy_spaces}</span>
          </div>
          <div className="status">
            <div className="status-title">
              <Icons width={24} height={24} customIcon icon="successful" className="button-icons" />
              {formatMessage({ id: 'zones.free' })}
            </div>
            <span className="status-count">{item.free_spaces}</span>
          </div>
          <div className="status">
            <div className="status-title">
              <Icons width={24} height={24} customIcon icon="reserver" className="button-icons" />
              {formatMessage({ id: 'zones.reserved' })}
            </div>
            <span className="status-count">{item.reserved_spaces}</span>
          </div>
          <div className="status">
            <div className="status-title">
              <Icons width={24} height={24} customIcon icon="warning" className="button-icons" />
              {formatMessage({ id: 'zones.lightweight' })}
            </div>
            <span className="status-count">{item.benefit_spaces}</span>
          </div>
        </div>
        <div className="separate" />
        <div className="zone_stat">
          <span className="zone_stat_title">
            {formatMessage({ id: 'zones.total' })}
          </span>
          <span className="zone-stat-count">
            {item.total_spaces}
          </span>
        </div>
      </div>
      { isEditPopUp ? <ZonesEditPopUp item={item} onHide={() => { setIsEditPopUp(false); }} /> : null}
      { isAddPopUp ? <ZonesAddOptionPopUp item={item} onHide={() => { setIsAddPopUp(false); }} /> : null }
    </div>
  );
}
