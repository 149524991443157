import { intlShape } from '../components/base/IntlProviderWrapper';

const {
  formatMessage,
} = intlShape;

export const required = value => (
  value
    || (Array.isArray(value) && value.length)
    || typeof value === 'number'
    || typeof value === 'boolean'
    ? {} : formatMessage({ id: 'errors.emptyData' }));

export const isJSON = value => {
  if (value) {
    try {
      JSON.parse(value);
    } catch (e) {
      return formatMessage({ id: 'errors.mustBeJSON' });
    }
    return {};
  }
};

export const maxLength = max => value => (value && value.length > max ? formatMessage({ id: 'errors.maxLength' }, { max }) : {});
export const minLength = min => value => (value && value.length < min ? formatMessage({ id: 'errors.minLength' }, { min }) : {});

export const number = value => (value && !(/^[\p{N}]+$/u.test(value))
  ? formatMessage({ id: 'errors.mustBeANumber' })
  : {});

export const email = value => (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
  ? formatMessage({ id: 'errors.invalidEmailAddress' })
  : {});

export const phoneNumber = value => (value && !/^(0|[1-9][0-9]{9})$/i.test(value)
  ? formatMessage({ id: 'errors.invalidPhoneNumber' })
  : {});

export const onlyDigitsAndLetters = value => (value && !(/^[0-9a-zA-Zа-яА-ЯёЁ]*$/.test(value))
  ? formatMessage({ id: 'errors.onlyDigitsAndLetters' })
  : {});

export const russianRegNumberValidation = value => (value && !(/^[ABEKMHOPCTYX0-9АВЕКМНОРСТУХ]+$/.test(value))
  ? formatMessage({ id: 'errors.russianRegNumberValidation' })
  : {});

export const russianRegNumberWithMask = value => (value && !(
  /^[АВЕКМНОРСТУХавекмнорстухABEKMHOPCTYXabekmhopctyx]\d{3}[АВЕКМНОРСТУХавекмнорстухABEKMHOPCTYXabekmhopctyx]{2}\d{2,3}$/.test(value)
    || /^[АВЕКМНОРСТУХавекмнорстухABEKMHOPCTYXabekmhopctyx]{2}\d{3}\d{2,3}$/.test(value)
    || /^[АВЕКМНОРСТУХавекмнорстухABEKMHOPCTYXabekmhopctyx]{2}\d{4}\d{2,3}$/.test(value)
    || /^\d{4}[АВЕКМНОРСТУХавекмнорстухABEKMHOPCTYXabekmhopctyx]{2}\d{2,3}$/.test(value)
    || /^[АВЕКМНОРСТУХавекмнорстухABEKMHOPCTYXabekmhopctyx]{2}\d{3}[АВЕКМНОРСТУХавекмнорстухABEKMHOPCTYXabekmhopctyx]\d{2,3}$/.test(value)
    || /^[T][АВЕКМНОРСТУХавекмнорстухABEKMHOPCTYXabekmhopctyx]{2}\d{3}\d{2,3}$/.test(value)
) ? formatMessage({ id: 'errors.russianRegNumberValidation' }) : {});

export const onlyLetters = value => (value && !(/^[a-zA-Zа-яА-ЯёЁ\s]+$/.test(value))
  ? formatMessage({ id: 'errors.onlyLetters' })
  : {});

export const onlyDigits = value => (value && !(/^[0-9]*$/.test(value))
  ? formatMessage({ id: 'errors.onlyDigits' })
  : {});

export const onlyLettersAndNumberAndHyphen = value => (value && !(/^[0-9-a-zA-Zа-яА-ЯёЁ.\s]*$/.test(value))
  ? formatMessage({ id: 'errors.onlyLettersAndNumberAndHyphen' })
  : {});

export const onlyAddressSym = value => (value && !(/^[0-9-a-zA-Zа-яёЁА-Я\\,\\.№\\#\s]*$/.test(value))
  ? formatMessage({ id: 'errors.onlyAddressSym' })
  : {});

export const onlyLettersAndHyphen = value => (value && !(/^[a-zA-Zа-яёЁА-Я-\s]*$/.test(value))
  ? formatMessage({ id: 'errors.onlyLettersAndHyphen' })
  : {});

export const onlyLettersAndPunctuation = value => (value && !(/^[-a-zA-Zа-яёЁА-Я\s?!,.—']*$/.test(value))
  ? formatMessage({ id: 'errors.onlyLettersAndHyphen' })
  : {});

export const onlyHouse = value => (value && !(/^[-0-9а-яёЁА-Я/—']*$/.test(value))
  ? formatMessage({ id: 'errors.onlyHouse' })
  : {});

export const noHyphenStartOrEnd = value => (value && /(^-+)|(-+$)/.test(value)
  ? formatMessage({ id: 'errors.noHyphenStartOrEnd' })
  : {});

export const noSpecStartOrEnd = value => (value && /(^-+)|(-+$)/.test(value)
  ? formatMessage({ id: 'errors.noHyphenStartOrEnd' })
  : {});

export const trimNoSpecStartOrEnd = value => (value && typeof value === 'string' && value.trim().length && /(^-+)|(-+$)/.test(value.trim())
  ? formatMessage({ id: 'errors.noHyphenStartOrEnd' })
  : {});

export const noSpaceStart = value => (value && typeof value === 'string' && value.length && /^(\s|\t)/.test(value)
  ? formatMessage({ id: 'errors.noSpaceStart' })
  : {});

export const noSpaceEnd = value => (value && typeof value === 'string' && value.length && /(\s|\t)$/.test(value)
  ? formatMessage({ id: 'errors.noSpaceEnd' })
  : {});

export const trimError = value => (value && typeof value === 'string' && !value.trim().length
  ? formatMessage({ id: 'errors.trimError' })
  : {});

export const noDigits = value => (value && (/^\p{N}+$/u.test(value))
  ? formatMessage({ id: 'errors.noDigits' })
  : {});

export const noLetters = value => (value && (/^\p{L}+$/u.test(value))
  ? formatMessage({ id: 'errors.onlyDigits' })
  : {});

export const isFirstCharInUpperCase = value => (value && value.length && !(value[0] === value[0].toUpperCase())
  ? formatMessage({ id: 'errors.isFirstCharInUpperCase' })
  : {});

export const USSRPassportBatch = value => (value && !(/^[IVXХLCDM]{2}-[а-яёА-ЯЁa-zA-Z]{2}$/.test(value))
  ? formatMessage({ id: 'errors.USSRPassportBatch' })
  : {});

export const regCertBatch = value => (value && !(/^\d{2}([а-яёА-ЯЁa-zA-Z]{2}|\d{2})$/.test(value))
  ? formatMessage({ id: 'errors.regCertBatch' })
  : {});

export const maxLength500 = maxLength(500);
export const maxLength400 = maxLength(400);
export const maxLength250 = maxLength(250);
export const maxLength150 = maxLength(150);
export const maxLength125 = maxLength(125);
export const maxLength100 = maxLength(100);
export const maxLength80 = maxLength(80);
export const maxLength60 = maxLength(60);
export const maxLength50 = maxLength(50);
export const maxLength30 = maxLength(30);
export const maxLength20 = maxLength(20);
export const maxLength25 = maxLength(25);
export const maxLength15 = maxLength(15);
export const maxLength14 = maxLength(14);
export const maxLength12 = maxLength(12);
export const maxLength10 = maxLength(10);
export const maxLength9 = maxLength(9);
export const maxLength7 = maxLength(7);
export const maxLength6 = maxLength(6);
export const maxLength5 = maxLength(5);
export const maxLength4 = maxLength(4);
export const maxLength3 = maxLength(3);
export const maxLength2 = maxLength(2);
export const maxLength1 = maxLength(1);
export const minLength2 = maxLength(2);
export const minLength3 = minLength(3);
export const minLength4 = minLength(4);
export const minLength6 = minLength(6);
export const minLength7 = minLength(7);
export const minLength9 = minLength(9);
export const minLength10 = minLength(10);
export const minLength12 = minLength(12);
export const minLength25 = minLength(25);
export const minLength80 = minLength(80);

export const min2words = value => {
  const spaceIndex = value.indexOf(' ');
  return (value && (spaceIndex <= 0 || spaceIndex === value.length - 1) ? formatMessage({ id: 'errors.min2words' }) : {});
};
