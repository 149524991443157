import React from 'react';
import classNames from 'classnames';
import {
  Dialog, DialogContent, DialogFooter, DialogTitle,
} from '../../../components/base/Dialog/Dialog';
import Grid from '../../../components/base/Grid/Grid';
import RangeDatePicker from '../../../components/fields/RangeDatePicker/RangeDatePicker';
import getDefaultDataRange from '../../../utils/getDefaultDataRange';
import Pagination from '../../../components/base/Pagination/Pagination';
import { hostStatusColumn } from './moks';

export default function HostHistoryPopUp(props) {
  const momentRangeObject = getDefaultDataRange(1, 'month');
  const {
    onHide,
    item,
    title,
    meta,
    handleActionHistory,
    setValue,
  } = props;
  return (
    <Dialog
      className={classNames('host-status-event-grid-popup')}
      onClose={() => { onHide(); }}
      size="xl"
      open
    >
      <DialogTitle
        className="hff-title"
        title={(
          <>
            <div className="hff-title-text f2">История команд хоста {title}</div>
            <RangeDatePicker
              value={momentRangeObject}
              hideSmallButtons
              usePortal
              hidePickerIcon
              showArrowIcon
              handleChangeDate={date => {}}
              className="no-border"
            />
          </>
        )}
      />
      <DialogContent className="hff-content">
        <div className="host-table g-0 setting-table">
          <div className="frame">
            <div className="sticky-table-wrapper custom-scroll-theme">
              <Grid
                className="host-grid"
                columns={hostStatusColumn}
                data={item}
              />
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogFooter className="hff-footer">
        <Pagination list={item} meta={meta} handleChangeMeta={handleActionHistory} />
      </DialogFooter>
    </Dialog>
  );
}
