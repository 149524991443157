/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from 'react';
import classNames from 'classnames';
import './RadioButtonStyles.scss';
import renderMessage from '../renderMessage';
import { required } from '../../../utils/validation';
import { intlShape } from '../../base/IntlProviderWrapper';

class RadioButton extends Component {
  renderItem = item => {
    const {
      disabled = false,
      inputProps = {},
      optionClassFormatter = null,
      formatter = null,
    } = this.props;
    const {
      formatMessage,
    } = intlShape;
    const { disabled: itemDisabled } = item;
    const inputId = `radio-input-${Math.random()}`;
    let classMod = '';
    if (!formatter && optionClassFormatter && typeof optionClassFormatter === 'function') {
      classMod = optionClassFormatter(item) || '';
    }
    const itemProps = {
      checked: false,
    };
    if (inputProps.value && item.value === inputProps.value) {
      itemProps.checked = true;
      itemProps.onClick = () => {
        inputProps.onChange({ target: { value: null } });
        document.getElementById(inputId).checked = false;
      };
    }
    return (
      <div className={classNames('form-check', { checked: itemProps.checked })} key={`radio-btn-${item.value}`}>
        <input disabled={disabled} className="form-check-input" type="radio" name="flexRadioDefault" id={inputId} {...inputProps} value={item.value} {...itemProps} />
        <label className={classNames('form-check-label', classMod)} htmlFor={inputId}>
          <div className="form-check-input-icon" />
          {!item.name && item.nameId ? formatter ? formatter(item.value) : formatMessage({ id: item.nameId }) : item.name || ''}
        </label>
      </div>
    );
  };

  render() {
    const {
      withLabel = false,
      label,
      options = [],
      fieldState = {
        error: '',
        touched: false,
      },
      textMuted,
      dtoObject,
      className = '',
    } = this.props;
    const {
      error,
      isTouched: touched,
    } = fieldState;
    const isError = Boolean(error);
    const isRequired = dtoObject
      && dtoObject.validators
      && dtoObject.validators.length
      && !!dtoObject.validators.find(validator => validator.toString() === required.toString());

    return (
      <div className={classNames('form-control-wrapper radio-control-list', className)}>
        {withLabel && (<div className="form-label">{label}{isRequired ? <span className="form-label-mark-required">*</span> : null}</div>)}
        <div className="radio-btn-group">
          {options.map(this.renderItem)}
        </div>
        {textMuted ? <div className="form-text">{renderMessage(textMuted)}</div> : null}
        {touched && isError ? <div className="invalid-feedback">{renderMessage(error)}</div> : null}
      </div>
    );
  }
}
export default RadioButton;
