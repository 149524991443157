import {
  BEGIN, CREATE, DELETE_BY_ID, FAILED, LOAD_BY_ID, LOAD_LIST, SUCCESSFUL, UPDATE,
} from '../configs/actionNames';
import MetaDTO from '../dto/MetaDTO';

export const initialStateCRUD = {
  loadingCreate: false,
  loadingUpdate: false,
  loadingDelete: false,
  loadingList: false,
  loadingCurrent: false,
  list: [],
  listMeta: {},
  current: {},
};

export const createLoadListReducer = (eventName, listName, loadingName, metaName) => ({
  [eventName]: state => ({
    ...state,
    [loadingName]: true,
  }),
  [eventName + SUCCESSFUL]: (state, action) => {
    const {
      payload: {
        result = [],
        meta = {},
      },
    } = action;
    return {
      ...state,
      [loadingName]: false,
      [listName]: result,
      [metaName || `${listName}Meta`]: new MetaDTO(meta).toString(),
    };
  },
  [eventName + FAILED]: state => ({
    ...state,
    [loadingName]: false,
    [listName]: [],
    [metaName || `${listName}Meta`]: {},
  }),
});

export const createLoadListReducerWithBegin = (eventName, listName, loadingName, metaName) => ({
  [eventName + BEGIN]: state => ({
    ...state,
    [loadingName]: true,
  }),
  [eventName + SUCCESSFUL]: (state, action) => {
    const {
      payload: {
        result = [],
        meta = {},
      },
    } = action;
    return {
      ...state,
      [loadingName]: false,
      [listName]: result,
      [metaName || `${listName}Meta`]: new MetaDTO(meta).toString(),
    };
  },
  [eventName + FAILED]: state => ({
    ...state,
    [loadingName]: false,
    [listName]: [],
    [metaName || `${listName}Meta`]: {},
  }),
});

export const createOperationStateReducer = (eventName, loadingName) => ({
  [eventName]: state => ({
    ...state,
    [loadingName]: true,
  }),
  [eventName + SUCCESSFUL]: state => ({
    ...state,
    [loadingName]: false,
  }),
  [eventName + FAILED]: state => ({
    ...state,
    [loadingName]: false,
  }),
});

export const createOperationStateReducerWithBegin = (eventName, loadingName) => ({
  [eventName + BEGIN]: state => ({
    ...state,
    [loadingName]: true,
  }),
  [eventName + SUCCESSFUL]: state => ({
    ...state,
    [loadingName]: false,
  }),
  [eventName + FAILED]: state => ({
    ...state,
    [loadingName]: false,
  }),
});

export const createLoadByIdReducer = (eventName, currentName, loadingName) => ({
  [eventName]: state => ({
    ...state,
    [loadingName]: true,
  }),
  [eventName + SUCCESSFUL]: (state, action) => {
    const data = action.payload;
    return {
      ...state,
      [loadingName]: false,
      [currentName]: data,
    };
  },
  [eventName + FAILED]: state => ({
    ...state,
    [loadingName]: false,
    [currentName]: {},
  }),
});

export const createLoadParameterReducer = (eventName, parameterName, loadingName, defaultValue) => ({
  [eventName]: state => ({
    ...state,
    [loadingName]: true,
    [parameterName]: defaultValue,
  }),
  [eventName + SUCCESSFUL]: (state, action) => ({
    ...state,
    [loadingName]: false,
    [parameterName]: action.payload,
  }),
  [eventName + FAILED]: state => ({
    ...state,
    [loadingName]: false,
    [parameterName]: defaultValue,
  }),
});

export const getActionsCRUD = MODULE_NAME => ({
  ...createLoadListReducer(MODULE_NAME + LOAD_LIST, 'list', 'loadingList'),
  ...createLoadByIdReducer(MODULE_NAME + LOAD_BY_ID, 'current', 'loadingCurrent'),
  ...createOperationStateReducer(MODULE_NAME + CREATE, 'loadingCreate'),
  ...createOperationStateReducer(MODULE_NAME + DELETE_BY_ID, 'loadingDelete'),
  ...createOperationStateReducer(MODULE_NAME + UPDATE, 'loadingUpdate'),
});
