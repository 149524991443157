import { takeLatest, takeEvery } from 'redux-saga/effects';
import { MODULE_NAME } from '../constants';
import {
  LOAD_CLIENT_LIST,
  DELETE_USER,
  UPDATE_USER,
  CREATE_USER,
} from '../actions';
import loadClientList from './loadClientList';
import deleteUser from './deleteUser';
import updateUser from './updateUser';
import createUser from './createUser';
import loadClientTypeList from './loadClientTypeList';
import loadZoneList from '../../Zones/sagas/loadZoneList';

export default function* sagas() {
  yield takeLatest(MODULE_NAME + LOAD_CLIENT_LIST, loadClientList);
  yield takeLatest(MODULE_NAME + LOAD_CLIENT_LIST, loadClientTypeList);
  yield takeLatest(MODULE_NAME + LOAD_CLIENT_LIST, loadZoneList);
  yield takeEvery(MODULE_NAME + DELETE_USER, deleteUser);
  yield takeEvery(MODULE_NAME + UPDATE_USER, updateUser);
  yield takeEvery(MODULE_NAME + CREATE_USER, createUser);
}
