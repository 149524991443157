import React from 'react';
import {
  MoreFormatter,
  govermentNumberTextFormatter,
  clientTypeFormatter,
  dateOrDateTimeFormatter,
  clientStatusFormatter,
  controlBoxFormatter,
} from '../../utils/formatters';
import { Icons } from '../../components/base/BootstrapComponents';

export const statusOptions = [
  { value: 'blocked', nameId: 'Заблокирован' },
  { value: 'active_outside', nameId: 'Активен за территорией' },
  { value: 'active_inside', nameId: 'Активен' }];

export const defaultFilterValues = {
  checkBox: '',
  search: '',
  id: '',
  type: '',
  lpn: '',
  parking_zone_id: '',
  period_start_dt: '',
  period_end_dt: '',
  status: '',
  control: '',
  card_num: '',
  dateOfUse: '',
  feedback: '',
};

export const defaultColumns = [
  {
    dataField: 'checkBox',
    disableSort: true,
    text: 'Выбор строки',
    hidden: true,
    headerClassName: 'column-0 text-center column-relative column-relative',
    dataClassName: 'align-middle column-relative',
  },
  {
    dataField: 'id',
    text: 'ИД клиента',
    disableSort: true,
    dataClassName: 'align-middle',
    headerClassName: 'column-1',
    hidden: true,
  },
  {
    dataField: 'type_label',
    text: 'Тип клиента',
    disableSort: true,
    hidden: true,
    headerClassName: 'column-2',
    formatter: clientTypeFormatter,
    dataClassName: 'align-middle',
  },
  {
    dataField: 'lpn',
    text: 'ГРЗ',
    disableSort: true,
    hidden: true,
    // formatter: govermentNumberTextFormatter,
    headerClassName: 'column-3',
    dataClassName: 'align-middle',
  },
  // {
  //   dataField: 'parking_zone',
  //   text: 'Зона клиента',
  //   disableSort: true,
  //   formatter: item => item.name,
  //   dataClassName: 'align-middle',
  //   headerClassName: 'column-4',
  //   // formatter: e => e.name,
  //   hidden: true,
  // },
  {
    dataField: 'period_start_dt',
    text: 'Срок действия с',
    disableSort: true,
    dataClassName: 'align-middle',
    headerClassName: 'column-5',
    formatter: date => dateOrDateTimeFormatter(date),
    hidden: true,
  },
  {
    dataField: 'period_end_dt',
    text: 'Срок действия по',
    disableSort: true,
    dataClassName: 'align-middle',
    headerClassName: 'column-6',
    formatter: date => dateOrDateTimeFormatter(date),
    hidden: true,
  },
  {
    dataField: 'status',
    text: 'Статус',
    disableSort: true,
    headerClassName: 'column-7',
    dataClassName: 'align-middle',
    formatter: clientStatusFormatter,
    hidden: true,
  },
  {
    dataField: 'control',
    text: 'Доступные действия',
    disableSort: true,
    dataClassName: 'align-middle',
    headerClassName: 'column-8',
    formatter: controlBoxFormatter,
    hidden: true,
  },
  {
    dataField: 'card_num',
    text: 'Номер карты',
    disableSort: true,
    headerClassName: 'column-7',
    dataClassName: 'align-middle',
    hidden: false,
  },
  {
    dataField: 'dateOfUse',
    text: 'Срок действия',
    disableSort: true,
    headerClassName: 'column-11',
    dataClassName: 'align-middle',
    hidden: false,
  },
  {
    dataField: 'description',
    text: 'Комментарий',
    disableSort: true,
    headerClassName: 'column-10',
    dataClassName: 'align-middle',
    hidden: false,
  },
  // {
  //   dataField: 'setting',
  //   text: <Icons width={24} height={24} customIcon icon=gear className=button-icons more-vert />,
  //   formatter: MoreFormatter,
  //   disableSort: true,
  //   dataClassName: 'row-full-click',
  //   headerClassName: 'text-center column-9',
  //   // onClick: () => setSetting(true),
  //   hidden: true,
  //   disable: true,
  // },
];
