import { createAction } from 'redux-actions';
import { createDeferredAction } from '../../utils/redux';

import {
  BEGIN,
  CREATE,
  FAILED,
  SUCCESSFUL,
} from '../../configs/actionNames';
import { ZONES_PAGE_MODULE } from '../../configs/moduleNames';

export const LOAD_ZONE_LIST = '_LOAD_ZONE_LIST';
export const LOAD_ZONE_STATUS_LIST = '_LOAD_ZONE_STATUS_LIST';

export const create = createDeferredAction(ZONES_PAGE_MODULE + CREATE);
export const createSuccessful = createAction(ZONES_PAGE_MODULE + CREATE + SUCCESSFUL);
export const createFailed = createAction(ZONES_PAGE_MODULE + CREATE + FAILED);

export const loadZoneList = createDeferredAction(ZONES_PAGE_MODULE + LOAD_ZONE_LIST);
export const loadZoneListBegin = createAction(ZONES_PAGE_MODULE + LOAD_ZONE_LIST + BEGIN);
export const loadZoneListSuccessful = createAction(ZONES_PAGE_MODULE + LOAD_ZONE_LIST + SUCCESSFUL);
export const loadZoneListFailed = createAction(ZONES_PAGE_MODULE + LOAD_ZONE_LIST + FAILED);

export const loadZoneStatusList = createDeferredAction(ZONES_PAGE_MODULE + LOAD_ZONE_STATUS_LIST);
export const loadZoneStatusListBegin = createAction(ZONES_PAGE_MODULE + LOAD_ZONE_STATUS_LIST + BEGIN);
export const loadZoneStatusListSuccessful = createAction(ZONES_PAGE_MODULE + LOAD_ZONE_STATUS_LIST + SUCCESSFUL);
export const loadZoneStatusListFailed = createAction(ZONES_PAGE_MODULE + LOAD_ZONE_STATUS_LIST + FAILED);
