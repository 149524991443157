import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import './hosts-directory-page.scss';
import { Icons } from '../../../components/base/BootstrapComponents';
import { defaultFilterValues } from './moks';
import TextInput from '../../../components/fields/TextInput/TextInput';
import FieldWithAttributeDTO from '../../../components/fields/Abstract/FieldWithAttributeDTO';
import PageHeader from '../../../components/base/PageHeader/PageHeader';
import HostsDirectoryGrid from './HostsDirectoryGrid';
import { loadHostList } from '../actions';
import IconBox from '../../../components/common/IconBox';
import SeparateBox from '../../../components/base/SeparateBox/SeparateBox';
import HostSettingPopup from './HostSettingPopup';
import HostSettingAddPopup from './HostSettingAddPopup';
import { responseLogWithNotification, getPaginationOpts } from '../../../utils/utils';
import { getHostData } from '../reducer';
import CustomButton from '../../../components/base/Button/CustomButton';
import HostDeletePopUp from './HostDeletePopUp';

export default function HostsDirectoryPage() {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState([]);
  const [mode, setMode] = useState('');
  const [selectedRow, setSelectedRow] = useState(false);
  const [isAddPopUp, setIsAddPopUp] = useState(false);

  const {
    hostList,
    hostListMeta,
  } = useSelector(getHostData);

  const {
    register, getValues, watch, setValue, reset,
  } = useForm({
    defaultValues: JSON.parse(localStorage.getItem('host-filter')) || { ...defaultFilterValues },
  });

  const handlerClickRow = item => {
    setSelectedRow(item);
  };

  const handleLoadingHostList = opts => {
    const filterData = getValues();
    if (opts && !opts.page) {
      opts.page = 1;
    }
    const paginationOpts = getPaginationOpts(hostListMeta, opts);
    const requestData = {
      ...paginationOpts,
      filter: filterData,
    };
    const action = loadHostList;
    dispatch(loadHostList(action(requestData))).catch(responseLogWithNotification);
  };

  watch();

  useEffect(() => {
    const subscription = watch(value => {
      handleLoadingHostList();
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => handleLoadingHostList(), []);

  return (
    <div className="hosts-page">
      <div className="hosts-wrapper">
        <div className="hosts-inputs-box">
          <div className={classNames('hosts-info', { variant: selectedRow })}>
            <PageHeader header="Справочник хостов" />
          </div>
          <div className="hosts-inputs">
            <FieldWithAttributeDTO
              inputBefore={<Icons customIcon className="search-icon" width={24} height={24} icon="search" />}
              type="text"
              component={TextInput}
              dtoObject={{
                name: 'search',
                type: String,
                validators: [],
                labelKey: 'search',
              }}
              inputProps={{
                ...register('search', { maxLength: 30 }),
                value: getValues('search'),
                onChange: e => {
                  setValue('search', e.target.value);
                  localStorage.setItem('columns-filter-order', JSON.stringify({ ...getValues() }));
                },
              }}
              className="small no-border search-field "
            />
            <FieldWithAttributeDTO
              type="text"
              component={TextInput}
              placeholder="Имя хоста (№)"
              dtoObject={{
                name: 'host',
                type: String,
                validators: [],
                labelKey: 'host.host',
              }}
              inputProps={{
                ...register('host', { maxLength: 30 }),
                value: getValues('host'),
                onChange: e => {
                  setValue('host', e.target.value);
                  localStorage.setItem('host-filter', JSON.stringify({ ...getValues() }));
                },
              }}
              className="small no-border"
            />
            <SeparateBox />
            <IconBox icon="export" />
            <SeparateBox />
            <IconBox icon="plus-add" onClick={() => setIsAddPopUp(true)} />
            <SeparateBox />
            {selected.length ? <CustomButton text="Удалить" onClick={() => { setMode('delete'); }} icon="trash" /> : null}
          </div>
        </div>
        <HostsDirectoryGrid meta={hostListMeta} setMode={setMode} selected={selected} handleLoadingHostList={handleLoadingHostList} setSelected={setSelected} data={hostList} onClickByRow={handlerClickRow} />
        { selected && selected.length && mode === 'delete' ? <HostDeletePopUp setSelected={setSelected} user_ids={selected} onHide={() => setMode(false)} /> : null }
        { isAddPopUp ? <HostSettingAddPopup onHide={() => setIsAddPopUp(null)} selectedRow={selectedRow} /> : null }
        { mode === 'edit' ? <HostSettingPopup onHide={() => { setMode(''); }} selectedRow={selectedRow} /> : null }
      </div>
    </div>
  );
}
