import React from 'react';
import {
  statusFormatter,
  historyStatusFormatter,
  dateOrDateTimeFormatter,
} from '../../../utils/formatters';

export const tabOptions = [{ label: 'Таблица', value: 1 },
  { label: 'Схема', value: 2 }];

export const centerMap = [55.742963, 37.307106];

export const zoomMap = 18;

export const imgCoord = [[55.743526, 37.304802], [55.742126, 37.309789]];

export const defaultFilterValues = {
  search: '',
  name: '',
  zones: '',
  group: '',
  lpn: '',
  typeClient: '',
  date: '',
  completed_at_to: '',
  completed_at_from: '',
};

export const defaultSettingColumnValues = {
  host: true,
  equipments: true,
  visual: true,
  govermentNumber: true,
  log: true,
  control: true,
  setting: true,
};

export const updatePeriods = [
  { value: 0, name: 'Не обновлять', icon: 'update-period-0' },
  { value: 10, name: '10 секунд', icon: 'update-period-10' },
  { value: 30, name: '30 секунд', icon: 'update-period-30' },
  { value: 60, name: '1 минута', icon: 'update-period-60' },
  { value: 300, name: '5 минут', icon: 'update-period-300' },
];

export const visualIcon = [
  { value: 'car_absent', icon: 'radar-state-1' },
  { value: 'car_present', icon: 'radar-state-2' },
  { value: 'car_passed', icon: 'radar-state-3' },
  { value: 'car_departed', icon: 'radar-state-4' },
  { value: 'car_reversed', icon: 'radar-state-5' },
  // { value: 6, icon: 'radar-state-6' },
];

export const hostStatusColumn = [
  {
    dataField: 'created_at',
    text: 'Дата и время',
    disableSort: true,
    hidden: true,
    dataClassName: 'align-middle',
    formatter: date => dateOrDateTimeFormatter(date),
    headerClassName: 'column-0',
  },
  {
    dataField: 'action',
    text: 'Команда',
    disableSort: true,
    dataClassName: 'align-middle',
    headerClassName: 'column-1',
    hidden: true,
  },
  {
    dataField: 'status',
    text: 'Статус команды',
    disableSort: true,
    dataClassName: 'align-middle',
    formatter: historyStatusFormatter,
    headerClassName: 'column-2',
    hidden: true,
  },
];
