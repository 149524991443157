export const zonesMock = [
  {
    id: 1,
    name: 'Общая зона',
    statuses: [
      {
        name: 'zones.free',
        total: 160,
        icon: 'successful',
      },
      {
        name: 'zones.busy',
        total: 209,
        icon: 'error',
      },
      {
        name: 'zones.reserved',
        total: 17,
        icon: 'reserver',
      },
      {
        name: 'zones.lightweight',
        total: 10,
        icon: 'warning',
      },
    ],
    total: 396,
  },
  {
    id: 1,
    name: 'Общая зона',
    statuses: [
      {
        name: 'zones.free',
        total: 160,
        icon: 'successful',
      },
      {
        name: 'zones.busy',
        total: 209,
        icon: 'error',
      },
      {
        name: 'zones.reserved',
        total: 17,
        icon: 'reserver',
      },
      {
        name: 'zones.lightweight',
        total: 10,
        icon: 'warning',
      },
    ],
    total: 396,
  },
  {
    id: 1,
    name: 'Общая зона',
    statuses: [
      {
        name: 'zones.free',
        total: 160,
        icon: 'successful',
      },
      {
        name: 'zones.busy',
        total: 209,
        icon: 'error',
      },
      {
        name: 'zones.reserved',
        total: 17,
        icon: 'reserver',
      },
      {
        name: 'zones.lightweight',
        total: 10,
        icon: 'warning',
      },
    ],
    total: 396,
  },
  {
    id: 1,
    name: 'Общая зона',
    statuses: [
      {
        name: 'zones.free',
        total: 160,
        icon: 'successful',
      },
      {
        name: 'zones.busy',
        total: 209,
        icon: 'error',
      },
      {
        name: 'zones.reserved',
        total: 17,
        icon: 'reserver',
      },
      {
        name: 'zones.lightweight',
        total: 10,
        icon: 'warning',
      },
    ],
    total: 396,
  },
  {
    id: 1,
    name: 'Общая зона',
    statuses: [
      {
        name: 'zones.free',
        total: 160,
        icon: 'successful',
      },
      {
        name: 'zones.busy',
        total: 209,
        icon: 'error',
      },
      {
        name: 'zones.reserved',
        total: 17,
        icon: 'reserver',
      },
      {
        name: 'zones.lightweight',
        total: 10,
        icon: 'warning',
      },
    ],
    total: 396,
  },
  {
    id: 1,
    name: 'Общая зона',
    statuses: [
      {
        name: 'zones.free',
        total: 160,
        icon: 'successful',
      },
      {
        name: 'zones.busy',
        total: 209,
        icon: 'error',
      },
      {
        name: 'zones.reserved',
        total: 17,
        icon: 'reserver',
      },
      {
        name: 'zones.lightweight',
        total: 10,
        icon: 'warning',
      },
    ],
    total: 396,
  },
  {
    id: 1,
    name: 'Общая зона',
    statuses: [
      {
        name: 'zones.free',
        total: 160,
        icon: 'successful',
      },
      {
        name: 'zones.busy',
        total: 209,
        icon: 'error',
      },
      {
        name: 'zones.reserved',
        total: 17,
        icon: 'reserver',
      },
      {
        name: 'zones.lightweight',
        total: 10,
        icon: 'warning',
      },
    ],
    total: 396,
  },
  {
    id: 1,
    name: 'Общая зона',
    statuses: [
      {
        name: 'zones.free',
        total: 160,
        icon: 'successful',
      },
      {
        name: 'zones.busy',
        total: 209,
        icon: 'error',
      },
      {
        name: 'zones.reserved',
        total: 17,
        icon: 'reserver',
      },
      {
        name: 'zones.lightweight',
        total: 10,
        icon: 'warning',
      },
    ],
    total: 396,
  },
  {
    id: 1,
    name: 'Общая зона',
    statuses: [
      {
        name: 'zones.free',
        total: 160,
        icon: 'successful',
      },
      {
        name: 'zones.busy',
        total: 209,
        icon: 'error',
      },
      {
        name: 'zones.reserved',
        total: 17,
        icon: 'reserver',
      },
      {
        name: 'zones.lightweight',
        total: 10,
        icon: 'warning',
      },
    ],
    total: 396,
  },
  {
    id: 1,
    name: 'Общая зона',
    statuses: [
      {
        name: 'zones.free',
        total: 160,
        icon: 'successful',
      },
      {
        name: 'zones.busy',
        total: 209,
        icon: 'error',
      },
      {
        name: 'zones.reserved',
        total: 17,
        icon: 'reserver',
      },
      {
        name: 'zones.lightweight',
        total: 10,
        icon: 'warning',
      },
    ],
    total: 396,
  },
  {
    id: 1,
    name: 'Общая зона',
    statuses: [
      {
        name: 'zones.free',
        total: 160,
        icon: 'successful',
      },
      {
        name: 'zones.busy',
        total: 209,
        icon: 'error',
      },
      {
        name: 'zones.reserved',
        total: 17,
        icon: 'reserver',
      },
      {
        name: 'zones.lightweight',
        total: 10,
        icon: 'warning',
      },
    ],
    total: 396,
  },
  {
    id: 1,
    name: 'Общая зона',
    statuses: [
      {
        name: 'zones.free',
        total: 160,
        icon: 'successful',
      },
      {
        name: 'zones.busy',
        total: 209,
        icon: 'error',
      },
      {
        name: 'zones.reserved',
        total: 17,
        icon: 'reserver',
      },
      {
        name: 'zones.lightweight',
        total: 10,
        icon: 'warning',
      },
    ],
    total: 396,
  },
  {
    id: 1,
    name: 'Общая зона',
    statuses: [
      {
        name: 'zones.free',
        total: 160,
        icon: 'successful',
      },
      {
        name: 'zones.busy',
        total: 209,
        icon: 'error',
      },
      {
        name: 'zones.reserved',
        total: 17,
        icon: 'reserver',
      },
      {
        name: 'zones.lightweight',
        total: 10,
        icon: 'warning',
      },
    ],
    total: 396,
  },
  {
    id: 1,
    name: 'Общая зона',
    statuses: [
      {
        name: 'zones.free',
        total: 160,
        icon: 'successful',
      },
      {
        name: 'zones.busy',
        total: 209,
        icon: 'error',
      },
      {
        name: 'zones.reserved',
        total: 17,
        icon: 'reserver',
      },
      {
        name: 'zones.lightweight',
        total: 10,
        icon: 'warning',
      },
    ],
    total: 396,
  },
  {
    id: 1,
    name: 'Общая зона',
    statuses: [
      {
        name: 'zones.free',
        total: 160,
        icon: 'successful',
      },
      {
        name: 'zones.busy',
        total: 209,
        icon: 'error',
      },
      {
        name: 'zones.reserved',
        total: 17,
        icon: 'reserver',
      },
      {
        name: 'zones.lightweight',
        total: 10,
        icon: 'warning',
      },
    ],
    total: 396,
  },
];

export const zonesIcon = [
  { value: 0, name: 'successful', icon: 'successful' },
  { value: 1, name: 'error', icon: 'error' },
  { value: 2, name: 'reserver', icon: 'reserver' },
  { value: 3, name: 'warning', icon: 'warning' },
];
