import { call, put } from 'redux-saga/effects';
import UserDTO from '../../../dto/UserDTO';
import { BACKEND_URL } from '../../../configs';
import makeRequest from '../../../utils/makeRequest';
import { OK } from '../../../configs/serverStatusCodes';
import { clientLoginFailed, clientLoginSuccessful } from '../actions';
import { resolveActionPromise } from '../../../utils/resolveActionPromise';
import { rejectActionPromise } from '../../../utils/rejectActionPromise';
import processError from '../../../utils/processError';

export default function* clientLogin(action) {
  try {
    // todo need refact client model and this code
    const client = new UserDTO(action.payload);
    const url = 'https://ultra.fast.its-profit.ru/api/auth/login';
    const pool = client.getLoginData();
    const userDataResponse = yield makeRequest(url, pool, action);
    const clientData = yield userDataResponse.json();
    if (clientData && clientData.data && clientData.data.token) {
      window.localStorage.clear();
      yield put(clientLoginSuccessful(clientData.data));
      yield call(resolveActionPromise(action, clientData));
    } else {
      yield put(clientLoginFailed());
      yield call(rejectActionPromise(action, clientData));
    }
  } catch (e) {
    yield put(clientLoginFailed(e));
    yield call(rejectActionPromise(action, e));
    yield processError(e);
  }
}
