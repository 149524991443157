import React from 'react';
import PageHeader from '../../../components/base/PageHeader/PageHeader';
import {
  Button, Col, Container, Icons, Row,
} from '../../../components/base/BootstrapComponents';
import TextInputWithLabel from '../../../components/fields/TextInput/TextInputWithLabel';
import ApexDonutChart from '../../../components/charts/ApexDonutChart';
import ApexColumnChart from '../../../components/charts/ApexColumnChart';
import ApexBarChart from '../../../components/charts/ApexBarChart';
import ApexAreaChart from '../../../components/charts/ApexAreaChart';
import HeatMap from '../../../components/charts/HeatMap';
import CustomSelectInput from '../../../components/fields/SelectInput/CustomSelectInput';
import SelectInput from '../../../components/fields/SelectInput/SelectInput';
import notificationDecorator from '../../Notifications/notificationDecorator';

export default function ComponentsPage() {
  const donutChartData = {
    series: [1, 2, 3, 4, 5],
    labels: ['Яблок', 'Груш', 'Апельсинов', 'Киви', 'Огурецов'],
    loading: false,
  };
  const columnChartData = {
    series: [[1, 2, 3, 4, 5], [2, 3, 4, 5, 6], [3, 4, 5, 6, 7]],
    labels: ['Яблок', 'Груш', 'Апельсинов', 'Киви', 'Огурецов'],
    loading: false,
  };
  const columnDistributedChartData = {
    series: [[1, 2, 3, 4, 5]],
    labels: ['Яблок', 'Груш', 'Апельсинов', 'Киви', 'Огурецов'],
    loading: false,
  };
  const barChartData = {
    series: [[1, 2, 3, 4, 5]],
    labels: ['Яблок', 'Груш', 'Апельсинов', 'Киви', 'Огурецов'],
    loading: false,
  };
  const areaChartData = {
    series: [{
      name: 'Яблок',
      data: [31, 40, 28, 51, 42, 109, 100],
    }, {
      name: 'Груш',
      data: [11, 32, 45, 32, 34, 52, 41],
    }],
    loading: false,
  };

  const generateData = (count, yrange) => {
    let i = 0;
    const series = [];
    while (i < count) {
      const x = (i + 1).toString();
      const y = Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;

      series.push({
        x,
        y,
      });
      i++;
    }
    return series;
  };

  const heatMapChartData = {
    series: [{
      name: 'Jan',
      data: generateData(20, {
        min: -30,
        max: 55,
      }),
    },
    {
      name: 'Feb',
      data: generateData(20, {
        min: -30,
        max: 55,
      }),
    },
    {
      name: 'Mar',
      data: generateData(20, {
        min: -30,
        max: 55,
      }),
    },
    {
      name: 'Apr',
      data: generateData(20, {
        min: -30,
        max: 55,
      }),
    },
    {
      name: 'May',
      data: generateData(20, {
        min: -30,
        max: 55,
      }),
    },
    {
      name: 'Jun',
      data: generateData(20, {
        min: -30,
        max: 55,
      }),
    },
    {
      name: 'Jul',
      data: generateData(20, {
        min: -30,
        max: 55,
      }),
    },
    {
      name: 'Aug',
      data: generateData(20, {
        min: -30,
        max: 55,
      }),
    },
    {
      name: 'Sep',
      data: generateData(20, {
        min: -30,
        max: 55,
      }),
    },
    ],
    loading: false,
  };

  const stateOptions = [
    { value: 0, name: 'Option 1' },
    { value: 1, name: 'Option 2' },
    { value: 2, name: 'Option 3' },
  ];

  const showNotification = () => {
    notificationDecorator({
      title: 'Восстановление пароля',
      message: `Успешно ${Math.random() * 1000000}`,
      isError: true,
    });
  };

  return (
    <div className="content g-0 pb-4">
      <PageHeader header="Components" />
      <Container className="components-table g-0 mt-4">
        <Row>
          <Col>
            <div className="frame">
              <div className="f4 p-3">Buttons</div>
              <Container className="components-table g-0 px-3 pb-3">
                <Row>
                  <Col>
                    <Button variant="primary" className="w-100 big" onClick={showNotification}>
                      <Icons width={16} height={16} customIcon icon="arrow-back-2" className="button-icons" />
                      Кнопка
                      <Icons width={16} height={16} customIcon icon="arrow-forward-2" className="button-icons" />
                    </Button>
                    <Button variant="primary" className="mt-3 w-100 big active">
                      <Icons width={16} height={16} customIcon icon="arrow-back-2" className="button-icons" />
                      Кнопка
                      <Icons width={16} height={16} customIcon icon="arrow-forward-2" className="button-icons" />
                    </Button>
                    <Button disabled variant="primary" className="mt-3 w-100 big">
                      <Icons width={16} height={16} customIcon icon="arrow-back-2" className="button-icons" />
                      Кнопка
                      <Icons width={16} height={16} customIcon icon="arrow-forward-2" className="button-icons" />
                    </Button>
                  </Col>
                  <Col>
                    <Button variant="secondary" className="w-100 big">
                      <Icons width={16} height={16} customIcon icon="arrow-back-2" className="button-icons" />
                      Кнопка
                      <Icons width={16} height={16} customIcon icon="arrow-forward-2" className="button-icons" />
                    </Button>
                    <Button variant="secondary" className="mt-3 big w-100 active">
                      <Icons width={16} height={16} customIcon icon="arrow-back-2" className="button-icons" />
                      Кнопка
                      <Icons width={16} height={16} customIcon icon="arrow-forward-2" className="button-icons" />
                    </Button>
                    <Button disabled variant="secondary" className="mt-3 big w-100">
                      <Icons width={16} height={16} customIcon icon="arrow-back-2" className="button-icons" />
                      Кнопка
                      <Icons width={16} height={16} customIcon icon="arrow-forward-2" className="button-icons" />
                    </Button>
                  </Col>
                  <Col>
                    <Button variant="link" className="w-100 big">
                      <Icons width={16} height={16} customIcon icon="arrow-back-2" className="button-icons" />
                      Кнопка
                      <Icons width={16} height={16} customIcon icon="arrow-forward-2" className="button-icons" />
                    </Button>
                    <Button variant="link" className="mt-3 w-100 big active">
                      <Icons width={16} height={16} customIcon icon="arrow-back-2" className="button-icons" />
                      Кнопка
                      <Icons width={16} height={16} customIcon icon="arrow-forward-2" className="button-icons" />
                    </Button>
                    <Button disabled variant="link" className="mt-3 big w-100">
                      <Icons width={16} height={16} customIcon icon="arrow-back-2" className="button-icons" />
                      Кнопка
                      <Icons width={16} height={16} customIcon icon="arrow-forward-2" className="button-icons" />
                    </Button>
                  </Col>
                </Row>
              </Container>
            </div>
            <div className="frame mt-3">
              <div className="f4 p-3">Small Buttons</div>
              <Container className="components-table g-0 px-3 pb-3">
                <Row>
                  <Col>
                    <Button variant="primary" className="w-100">
                      <Icons width={16} height={16} customIcon icon="arrow-back-2" className="button-icons" />
                      Кнопка
                      <Icons width={16} height={16} customIcon icon="arrow-forward-2" className="button-icons" />
                    </Button>
                    <Button variant="primary" className="mt-3 w-100 active">
                      <Icons width={16} height={16} customIcon icon="arrow-back-2" className="button-icons" />
                      Кнопка
                      <Icons width={16} height={16} customIcon icon="arrow-forward-2" className="button-icons" />
                    </Button>
                    <Button disabled variant="primary" className="mt-3 w-100">
                      <Icons width={16} height={16} customIcon icon="arrow-back-2" className="button-icons" />
                      Кнопка
                      <Icons width={16} height={16} customIcon icon="arrow-forward-2" className="button-icons" />
                    </Button>
                  </Col>
                  <Col>
                    <Button variant="secondary" className="w-100">
                      <Icons width={16} height={16} customIcon icon="arrow-back-2" className="button-icons" />
                      Кнопка
                      <Icons width={16} height={16} customIcon icon="arrow-forward-2" className="button-icons" />
                    </Button>
                    <Button variant="secondary" className="mt-3 w-100 active">
                      <Icons width={16} height={16} customIcon icon="arrow-back-2" className="button-icons" />
                      Кнопка
                      <Icons width={16} height={16} customIcon icon="arrow-forward-2" className="button-icons" />
                    </Button>
                    <Button disabled variant="secondary" className="mt-3 w-100">
                      <Icons width={16} height={16} customIcon icon="arrow-back-2" className="button-icons" />
                      Кнопка
                      <Icons width={16} height={16} customIcon icon="arrow-forward-2" className="button-icons" />
                    </Button>
                  </Col>
                  <Col>
                    <Button variant="link" className="w-100">
                      <Icons width={16} height={16} customIcon icon="arrow-back-2" className="button-icons" />
                      Кнопка
                      <Icons width={16} height={16} customIcon icon="arrow-forward-2" className="button-icons" />
                    </Button>
                    <Button variant="link" className="mt-3 w-100 active">
                      <Icons width={16} height={16} customIcon icon="arrow-back-2" className="button-icons" />
                      Кнопка
                      <Icons width={16} height={16} customIcon icon="arrow-forward-2" className="button-icons" />
                    </Button>
                    <Button disabled variant="link" className="mt-3 w-100">
                      <Icons width={16} height={16} customIcon icon="arrow-back-2" className="button-icons" />
                      Кнопка
                      <Icons width={16} height={16} customIcon icon="arrow-forward-2" className="button-icons" />
                    </Button>
                  </Col>
                </Row>
              </Container>
            </div>
          </Col>
          <Col>
            <div className="frame">
              <div className="f4 p-3">Inputs</div>
              <Container className="components-table g-0 px-3 pb-3">
                <Row>
                  <Col>
                    <TextInputWithLabel
                      withLabel
                      label="Текстовое поле"
                      autocomplete="new-password"
                      inputAfter={<Icons className="password-icon" icon="eye-fill" />}
                      inputProps={{
                        value: '',
                        onChange: () => {},
                      }}
                    />
                    <CustomSelectInput
                      withLabel
                      label="Custom select input"
                      autocomplete="new-password"
                      className="mt-3"
                      inputAfter={<Icons className="password-icon" icon="eye-fill" />}
                      options={stateOptions}
                      inputProps={{
                        value: '',
                        onChange: () => {},
                      }}
                    />
                    <SelectInput
                      withLabel
                      label="Select input"
                      addPlaceholder
                      placeholder="Select input"
                      autocomplete="new-password"
                      className="mt-3"
                      inputAfter={<Icons className="password-icon" icon="eye-fill" />}
                      inputProps={{
                        value: '',
                        onChange: () => {},
                      }}
                      options={stateOptions}
                    />
                  </Col>
                </Row>
              </Container>
            </div>
            <div className="frame mt-3">
              <div className="f4 p-3">Big Inputs</div>
              <Container className="components-table g-0 px-3 pb-3">
                <Row>
                  <Col>
                    <TextInputWithLabel
                      className="big"
                      withLabel
                      label="Текстовое поле"
                      autocomplete="new-password"
                      inputAfter={<Icons className="password-icon" icon="eye-fill" />}
                      inputProps={{
                        value: '',
                      }}
                      variant="material"
                    />
                    <CustomSelectInput
                      withLabel
                      label="Custom select input"
                      autocomplete="new-password"
                      className="mt-3 big"
                      inputAfter={<Icons className="password-icon" icon="eye-fill" />}
                      options={stateOptions}
                      inputProps={{
                        value: '',
                        onChange: () => {},
                      }}
                      variant="material"
                    />
                    <SelectInput
                      addPlaceholder
                      placeholder="Select input"
                      autocomplete="new-password"
                      className="mt-3 big"
                      inputAfter={<Icons className="password-icon" icon="eye-fill" />}
                      inputProps={{
                        value: '',
                        onChange: () => {},
                      }}
                      options={stateOptions}
                    />
                  </Col>
                </Row>
              </Container>
            </div>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <div className="frame p-3 min-vp-100">
              <ApexDonutChart
                {...donutChartData}
                title="Donut Chart"
                className=""
                showSum
              />
            </div>
          </Col>
          <Col>
            <div className="frame p-3">
              <ApexColumnChart
                {...columnChartData}
                title="Column Chart"
                className=""
                barLabels={['Было', 'Стало', 'Могло быть']}
              />
            </div>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <div className="frame p-3 min-vp-100">
              <ApexBarChart
                {...barChartData}
                title="Bar Chart"
                className=""
                showSum
              />
            </div>
          </Col>
          <Col>
            <div className="frame p-3 min-vp-100">
              <ApexColumnChart
                {...columnDistributedChartData}
                title="Column Distributed Chart"
                className=""
                barLabels={['Было', 'Стало', 'Могло быть']}
                distributed
              />
            </div>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <div className="frame p-3 min-vp-100">
              <ApexAreaChart
                {...areaChartData}
                title="Area Chart"
                className=""
              />
            </div>
          </Col>
          <Col>
            <div className="frame p-3 min-vp-100">
              <HeatMap
                {...heatMapChartData}
                title="Heat Map"
                className=""
              />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
