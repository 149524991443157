import { showErrorNotification, showSuccessNotification, showWarningNotification } from './actions';

const notificationDecorator = options => {
  const {
    dispatch,
  } = window.appStore;
  const {
    isError = false,
    isWarning = false,
    title = '',
    message = '',
    autoDismiss = 5,
  } = options;

  if (!dispatch) {
    window.console.error('Function dispatch not found');
    return;
  }
  const notificationOpts = {
    title,
    message,
    position: 'tr',
    autoDismiss,
  };
  if (isError) {
    return dispatch(showErrorNotification(notificationOpts));
  }
  if (isWarning) {
    return dispatch(showWarningNotification(notificationOpts));
  }
  return dispatch(showSuccessNotification(notificationOpts));
};

export default notificationDecorator;
