import { handleActions } from 'redux-actions';
import {
  END_LOAD_APP,
  HIDE_CONFIRM,
  SET_LOCALE,
  SET_MENU_STATE,
  SHOW_CONFIRM,
  START_LOAD_APP,
} from './actions';
import { LOCAL_STORAGE_LOCALE } from '../../configs/names';
import { DEFAULT_LOCALE_SHORT } from '../../configs';
import { SYSTEM_MODULE } from '../../configs/moduleNames';

const initialState = {
  locale: window.localStorage.getItem(LOCAL_STORAGE_LOCALE) || DEFAULT_LOCALE_SHORT,
  showConfirm: false,
  confirmTitle: '',
  confirmMessage: '',
  confirmClassName: '',
  promiseId: null,
  isOpenMenu: false,
  loadApp: true,
};

const reducer = handleActions({
  [SYSTEM_MODULE + SET_LOCALE]: (state, action) => {
    const { locale } = action.payload;
    window.localStorage.setItem(LOCAL_STORAGE_LOCALE, locale);
    return {
      ...state,
      locale,
    };
  },
  [SYSTEM_MODULE + SET_MENU_STATE]: (state, action) => ({
    ...state,
    isOpenMenu: action.payload,
  }),

  [SYSTEM_MODULE + SHOW_CONFIRM]: (state, action) => {
    const {
      payload: {
        confirmTitle = '',
        confirmMessage = '',
        confirmClassName = '',
        confirmButtonText = '',
        confirmCancelButtonText = '',
        hideSubmit = false,
      } = {
        confirmTitle: '',
        confirmMessage: '',
        confirmClassName: '',
        confirmButtonText: '',
        confirmCancelButtonText: '',
        hideSubmit: false,
      },
      promiseId,
    } = action;
    return {
      ...state,
      showConfirm: true,
      confirmTitle,
      confirmMessage,
      confirmClassName,
      confirmButtonText,
      confirmCancelButtonText,
      promiseId,
      hideSubmit,
    };
  },
  [SYSTEM_MODULE + HIDE_CONFIRM]: state => ({
    ...state,
    showConfirm: false,
    confirmTitle: '',
    confirmMessage: '',
    confirmClassName: '',
    promiseId: null,
    hideSubmit: false,
  }),
  [SYSTEM_MODULE + START_LOAD_APP]: state => ({
    ...state,
    loadApp: true,
  }),
  [SYSTEM_MODULE + END_LOAD_APP]: state => ({
    ...state,
    loadApp: false,
  }),
}, initialState);

export default reducer;

export const getGlobalConfirmData = state => {
  const reducerData = state[SYSTEM_MODULE];
  const {
    showConfirm = false,
    confirmTitle = '',
    confirmMessage = '',
    confirmClassName = '',
    confirmButtonText = '',
    confirmCancelButtonText = '',
    hideSubmit = false,
    promiseId = null,
  } = reducerData;
  return {
    showConfirm,
    confirmTitle,
    confirmMessage,
    confirmClassName,
    confirmButtonText,
    confirmCancelButtonText,
    hideSubmit,
    promiseId,
  };
};

export const getSystemData = state => {
  const reducerData = state[SYSTEM_MODULE];
  const {
    loadApp = false,
    locale = '',
  } = reducerData;
  return {
    locale,
    loadApp,
  };
};
