import { MODULE_NAME } from './constants';
import RolePage from './components/RolePage';
import Empty from '../../components/base/Empty';

const routes = [
  {
    path: '/roles/:page?',
    name: `menu.${MODULE_NAME}.group`,
    icon: 'mon-user',
    customIcon: true,
    loginRequired: true,
    component: RolePage,
    weight: 101,
    permission: ['frontend_menu_users'],
    children: [],
  },
  {
    path: '/roles/:page?',
    to: 'parking-meters',
    name: 'menu.dashboard',
    icon: 'dashboard',
    customIcon: true,
    loginRequired: true,
    component: Empty,
    weight: 1,
    permission: [],
    children: [],
    forMobile: true,
    redirect: true,
  },
];

export default routes;
