import { handleActions } from 'redux-actions';

import { createLoadListReducerWithBegin } from '../../utils/getCrudConfig';
import { MODULE_NAME } from './constants';
import { PAYMENT_LOG_PAGE_MODULE } from '../../configs/moduleNames';
import {
  LOAD_PAYMENT_LIST, LOAD_PAYMENT_TYPE_LIST, LOAD_PAYMENT_METHOD_LIST,
} from './actions';
import {
  LOAD_ZONE_LIST,
} from '../Zones/actions';

const initialState = {
  paymentList: [],
  paymentListMeta: [],
  paymentTypeList: [],
  paymentMethodList: [],
  parkingZoneList: [],
};

const ZONES_PAGE = 'ZONES_PAGE';

const reducer = handleActions({
  ...createLoadListReducerWithBegin(MODULE_NAME + LOAD_PAYMENT_LIST, 'paymentList', 'loadingpaymentList'),
  ...createLoadListReducerWithBegin(MODULE_NAME + LOAD_PAYMENT_TYPE_LIST, 'paymentTypeList', 'loadingpaymentTypeList'),
  ...createLoadListReducerWithBegin(MODULE_NAME + LOAD_PAYMENT_METHOD_LIST, 'paymentMethodList', 'loadingpaymentMethodList'),
  ...createLoadListReducerWithBegin(ZONES_PAGE + LOAD_ZONE_LIST, 'parkingZoneList', 'loadZoneList'),
}, initialState);

export default reducer;

export const getPaymentData = state => {
  const reducerData = state[PAYMENT_LOG_PAGE_MODULE];
  const {
    paymentList,
    paymentListMeta,
    paymentTypeList,
    paymentMethodList,
    parkingZoneList,
  } = reducerData;

  return {
    paymentList,
    paymentTypeList,
    paymentMethodList,
    paymentListMeta,
    parkingZoneList,
  };
};
