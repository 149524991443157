import { put, call, delay } from 'redux-saga/effects';
import addFilterQueryWithCondition from '../../../utils/addFilterQueryWithCondition';
import addSortQuery from '../../../utils/addSortQuery';
import { rejectActionPromise } from '../../../utils/rejectActionPromise';
import { resolveActionPromise } from '../../../utils/resolveActionPromise';
import { MODULE_BACK_PATH, MODULE_DTO } from '../constants';
import { BACKEND_URL, DEFAULT_ITEMS_OF_PAGE, LOADING_DELAY } from '../../../configs';
import makeRequest from '../../../utils/makeRequest';
import { OK } from '../../../configs/serverStatusCodes';
import processError from '../../../utils/processError';
import { loadListFailed, loadListSuccessful, loadListBegin } from '../actions';
import ListDTO from '../../../dto/ListDTO';

export default function* loadList(action) {
  try {
    const {
      payload: {
        offset = 0,
        limit = DEFAULT_ITEMS_OF_PAGE,
        filterData,
        sortedColumns = [],
      } = {},
    } = action;
    const url = `${BACKEND_URL}/${MODULE_BACK_PATH}`;
    let pool = {
      offset,
      limit,
    };
    if (typeof filterData === 'object' && Object.keys(filterData).length) {
      yield delay(LOADING_DELAY);
      pool = addFilterQueryWithCondition(filterData, pool);
    }
    if (sortedColumns && Array.isArray(sortedColumns)) {
      pool = addSortQuery(sortedColumns, pool);
    }
    yield put(loadListBegin());
    const dataResponse = yield makeRequest(url, pool, action, 'GET', true);
    const data = yield dataResponse.json();
    if (dataResponse.status === OK && data) {
      const dataListDTO = new ListDTO(MODULE_DTO, data);
      yield put(loadListSuccessful({
        list: [...dataListDTO.data],
        listCount: Number(data && data.properties ? data.properties.total : 0),
      }));
      yield call(resolveActionPromise(action));
    } else {
      yield put(loadListFailed());
      yield call(rejectActionPromise(action));
    }
  } catch (e) {
    yield put(loadListFailed());
    yield call(rejectActionPromise(action, e));
    yield processError(e);
  }
}
