import React from 'react';
import { useSelector } from 'react-redux';
import { IntlProvider, createIntl, createIntlCache } from 'react-intl';
import messages_ru from '../../translations/ru.json';
import messages_en from '../../translations/en.json';
import { DEFAULT_LOCALE_SHORT } from '../../configs';
import { LOCAL_STORAGE_LOCALE } from '../../configs/names';
import { SYSTEM_MODULE } from '../../configs/moduleNames';

const messages = {
  ru: messages_ru,
  en: messages_en,
};

const cache = createIntlCache();
export const intlShape = createIntl({
  locale: window.localStorage.getItem(LOCAL_STORAGE_LOCALE) || DEFAULT_LOCALE_SHORT,
  messages: messages[DEFAULT_LOCALE_SHORT],
}, cache);

export default function IntlProviderWrapper({ children }) {
  const locale = useSelector(state => state[SYSTEM_MODULE].locale);
  return (
    <IntlProvider
      key={locale}
      locale={locale}
      defaultLocale={DEFAULT_LOCALE_SHORT}
      messages={messages[locale]}
    >
      {children}
    </IntlProvider>
  );
}
