import React, { Component } from 'react';
import classNames from 'classnames';
import './CheckBoxInputStyles.scss';

class CheckBoxInput extends Component {
  componentDidMount() {
    const {
      inputProps,
      defaultValue = false,
    } = this.props;
    // hack for role list
    if (defaultValue) {
      inputProps.onChange(true);
    }
  }

  onChange = event => {
    const {
      inputProps,
    } = this.props;
    if (!inputProps || !inputProps.onChange || typeof inputProps.onChange !== 'function') {
      return false;
    }
    inputProps.onChange({ target: { value: event.target.checked } });
    return false;
  };

  render() {
    const {
      inputProps,
      className = '',
      disabled = false,
      id = '',
    } = this.props;
    const inputValue = inputProps && typeof inputProps.value !== 'undefined' ? inputProps.value : '';
    return (
      <input
        className={classNames('form-check-input', className)}
        {...inputProps}
        type="checkbox"
        value=""
        id={id}
        checked={inputValue}
        disabled={disabled}
        onChange={this.onChange}
      />
    );
  }
}
export default CheckBoxInput;
