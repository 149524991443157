import { call, put, delay } from 'redux-saga/effects';
import { BACKEND_URL } from '../../../configs';
import { rejectActionPromise } from '../../../utils/rejectActionPromise';
import { resolveActionPromise } from '../../../utils/resolveActionPromise';

import { updateRoleBegin, updateRoleFailed, updateRoleSuccessful } from '../actions';
import { OK } from '../../../configs/serverStatusCodes';
import makeRequest from '../../../utils/makeRequest';
import processError from '../../../utils/processError';

export default function* updateRole(action) {
  try {
    const {
      name = '',
      id,
      users,
      permissions,
      useDelay = false,
    } = action.payload;
    const url = `${BACKEND_URL}/roles/${id}`;
    const pool = {
      name,
    };
    if (users && Array.isArray(users)) {
      pool.user_ids = users;
      pool.locale = users.setting && users.setting.locale;
    }
    if (permissions && Array.isArray(permissions)) {
      pool.permission_ids = permissions.map(i => i);
    }
    if (useDelay) {
      yield delay(2000);
    }
    yield put(updateRoleBegin());
    const dataResponse = yield makeRequest(url, pool, action, 'PUT', true);
    const data = yield dataResponse.json();
    if (dataResponse.status === OK
      && data
    ) {
      yield put(updateRoleSuccessful([]));
      yield call(resolveActionPromise(action, data));
    } else {
      yield put(updateRoleFailed([]));
      yield call(rejectActionPromise(action, data));
    }
  } catch (e) {
    yield put(updateRoleFailed([]));
    yield call(rejectActionPromise(action, e));
    yield processError(e);
  }
}
