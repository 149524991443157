import { getPathNameWithOutParameters } from './utils';

export const getActiveBlockName = (currentPathName, routes = []) => {
  let activeBlock = '';
  if (currentPathName && routes.length) {
    routes.forEach(prop => {
      const path = getPathNameWithOutParameters(prop.path);
      const isChildren = Boolean(prop.children && prop.children.length);
      if (currentPathName.includes(path) && path !== '/') {
        activeBlock = prop.name;
      } else if (isChildren) {
        const name = getActiveBlockName(currentPathName, prop.children);
        if (name.length) {
          activeBlock = prop.name;
        }
      }
    });
  }
  return activeBlock;
};

export const sortLinksFunction = (a, b) => a.weight - b.weight;
