export default function addSortQuery(sortedColumns, pool) {
  pool.sort = sortedColumns.map(sortedColumn => {
    if (sortedColumn.sortField && sortedColumn.order) {
      const { sortField, order } = sortedColumn;
      return `${sortField},${order}`;
    }
    return '';
  });
  return pool;
}
