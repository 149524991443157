export default class Timer {
  constructor(callback, delay) {
    this.timerId = null;
    this.start = null;
    this.callback = callback;
    this.remaining = delay;
    this.resume();
  }

  pause = () => {
    clearTimeout(this.timerId);
    this.remaining -= new Date() - this.start;
  };

  resume = () => {
    this.start = new Date();
    clearTimeout(this.timerId);
    this.timerId = setTimeout(this.callback, this.remaining);
  };

  clear = () => {
    clearTimeout(this.timerId);
  };
}
