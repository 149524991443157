import { call, put } from 'redux-saga/effects';
import { BACKEND_URL } from '../../../configs';
import { rejectActionPromise } from '../../../utils/rejectActionPromise';
import { resolveActionPromise } from '../../../utils/resolveActionPromise';
import { deleteByIdFailed, deleteByIdSuccessful } from '../actions';
import { MODULE_BACK_PATH } from '../constants';
import makeRequest from '../../../utils/makeRequest';
import { NO_CONTENT } from '../../../configs/serverStatusCodes';
import processError from '../../../utils/processError';

export default function* deleteById(action) {
  try {
    const { payload } = action;
    const url = `${BACKEND_URL}/${MODULE_BACK_PATH}/${payload.id}`;
    const pool = {};
    const dataResponse = yield makeRequest(url, pool, action, 'DELETE', true);
    if (dataResponse.status === NO_CONTENT) {
      yield put(deleteByIdSuccessful([]));
      yield call(resolveActionPromise(action));
    } else {
      yield put(deleteByIdFailed([]));
      yield call(rejectActionPromise(action));
    }
  } catch (e) {
    yield put(deleteByIdFailed([]));
    yield processError(e);
  }
}
