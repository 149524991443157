import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import './event-log-page.scss';
import { Icons } from '../../../components/base/BootstrapComponents';
import { getEventData } from '../reducer';
import {
  defaultFilterValues, defaultSettingColumnValues, defaultColumns,
} from '../moks';
import { statusNotificationFormatter } from '../../../utils/formatters';
import { loadEventList } from '../actions';
import TextInput from '../../../components/fields/TextInput/TextInput';
import FieldWithAttributeDTO from '../../../components/fields/Abstract/FieldWithAttributeDTO';
import PageHeader from '../../../components/base/PageHeader/PageHeader';
import CustomSelectInput from '../../../components/fields/SelectInput/CustomSelectInput';
import EventLogGrid from './EventLogGrid';
import EventLogPopUp from './EventLogPopUp';
import EventLogFilterForm from './EventLogFilterForm';
import IconBox from '../../../components/common/IconBox';
import SeparateBox from '../../../components/base/SeparateBox/SeparateBox';
import { getColumnsForTable } from '../../../utils/getColumnsForTable';
import { responseLogWithNotification, getPaginationOpts } from '../../../utils/utils';

export default function EventLogPage() {
  const dispatch = useDispatch();
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [isPopup, setIsPopup] = useState(false);
  const [selectedRow, setSelectedRow] = useState(false);
  const [isOpenSettingColumn, setSettingColumn] = useState(false);
  const sessionLogColumn = getColumnsForTable(defaultColumns);
  const handlerClickRow = item => {
    setSelectedRow(item);
    setIsPopup(true);
  };

  const {
    eventList,
    eventListMeta,
    eventStatusList,
  } = useSelector(getEventData);

  const [data, setData] = useState(eventList);

  const eventStatusOptions = eventStatusList.map(event => ({ nameId: event.label, value: event.value }));

  const loadDataFromStorage = () => {
    const storage = JSON.parse(localStorage.getItem('columns-filter'));
    return storage ? storage.map(column => {
      const search = sessionLogColumn.find(e => e.dataField === column.name);
      return { ...search, ...column };
    }).sort((a, b) => a.top - b.top) : sessionLogColumn;
  };

  const [columns, setColumns] = useState(loadDataFromStorage());

  const {
    register, getValues, watch, setValue, reset,
  } = useForm({
    defaultValues: JSON.parse(localStorage.getItem('elp-filter')) || { ...defaultFilterValues },
  });

  const handleLoadingEventList = opts => {
    const filterData = getValues();
    if (opts && !opts.page) {
      opts.page = 1;
    }
    const paginationOpts = getPaginationOpts(eventListMeta, opts);
    const requestData = {
      ...paginationOpts,
      filter: filterData,
    };
    const action = loadEventList;
    dispatch(loadEventList(action(requestData))).catch(responseLogWithNotification);
  };

  watch();

  useEffect(() => {
    const subscription = watch(value => {
      handleLoadingEventList();
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => handleLoadingEventList(), []);

  return (
    <div className="event-log-page">
      <div className="elp-container">
        <div className="elp-inputs-box">
          <PageHeader header="Журнал событий" />
          <div className="elp-inputs">
            <FieldWithAttributeDTO
              inputBefore={<Icons customIcon className="search-icon" width={24} height={24} icon="search" />}
              type="text"
              component={TextInput}
              dtoObject={{
                name: 'message',
                type: String,
                validators: [],
                labelKey: 'search',
              }}
              inputProps={{
                ...register('message', { maxLength: 30 }),
                value: getValues('message'),
                onChange: e => {
                  setValue('message', e.target.value);
                  localStorage.setItem('elp-filter', JSON.stringify({ ...getValues() }));
                },
              }}
              className="small no-border search-field "
            />
            <FieldWithAttributeDTO
              type="text"
              dtoObject={{
                name: 'host_name',
                type: String,
                validators: [],
                labelKey: 'elp.hostName',
              }}
              placeholder="Имя хоста (№)"
              inputProps={{
                ...register('host_name', { maxLength: 30 }),
                value: getValues('host_name'),
                onChange: e => {
                  setValue('host_name', e.target.value);
                  localStorage.setItem('elp-filter', JSON.stringify({ ...getValues() }));
                },
              }}
              component={TextInput}
              className="no-border"
            />
            <FieldWithAttributeDTO
              type="text"
              dtoObject={{
                name: 'status',
                type: String,
                validators: [],
                labelKey: 'elp.status',
              }}
              renderOption={item => (
                statusNotificationFormatter(item.value)
              )}
              placeholder="Статус события"
              inputProps={{
                ...register('status', { maxLength: 30 }),
                onChange: e => {
                  setValue('status', e.target.value);
                  localStorage.setItem('elp-filter', JSON.stringify({ ...getValues() }));
                },
                value: getValues('status') || '',
              }}
              component={CustomSelectInput}
              options={eventStatusOptions}
              className="no-border select-field"
            />
            <SeparateBox />
            <IconBox icon="export" />
            <SeparateBox />
            <IconBox icon="filter" onClick={() => { setIsOpenFilter(true); }} />
            {isOpenFilter
              ? (
                <EventLogFilterForm
                  onHide={() => { setIsOpenFilter(false); }}
                  setValue={setValue}
                  getValues={getValues}
                  register={register}
                  reset={reset}
                  defaultValues={defaultFilterValues}
                  eventListMeta={eventListMeta}
                  eventStatusOptions={eventStatusOptions}
                />
              )
              : null}
          </div>
        </div>
        <EventLogGrid data={eventList} meta={eventListMeta} handleLoadingEventList={handleLoadingEventList} setData={setData} onClickByRow={handlerClickRow} columns={defaultColumns.filter(e => e.hidden !== false)} setSettingColumn={setSettingColumn} />
        { isPopup ? <EventLogPopUp defaultColumns={defaultColumns} onHide={() => setIsPopup(false)} selectedItem={selectedRow} /> : null }
      </div>
    </div>
  );
}
