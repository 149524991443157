import React from 'react';
import { FormattedMessage } from 'react-intl';
import './LoaderStyles.scss';
import * as ReactDOM from 'react-dom';
import classNames from 'classnames';

export default function ComponentsPage(props) {
  const { loading = false, global = false, hideScreen = false } = props;
  if (!loading) {
    return null;
  }
  if (global) {
    return ReactDOM.createPortal(
      <div className={classNames('spinner-wrapper locked global', { 'hide-screen': hideScreen })}>
        <div className="spinner-border" role="status">
          <span className="sr-only"><FormattedMessage id="app.loading" /></span>
        </div>
      </div>,
      document.body,
    );
  }
  return (
    <div className="spinner-wrapper locked">
      <div className="spinner-border" role="status">
        <span className="sr-only"><FormattedMessage id="app.loading" /></span>
      </div>
    </div>
  );
}
