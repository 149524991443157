import { put, call } from 'redux-saga/effects';
import { rejectActionPromise } from '../../../utils/rejectActionPromise';
import { resolveActionPromise } from '../../../utils/resolveActionPromise';
import { BACKEND_URL } from '../../../configs';
import makeRequest from '../../../utils/makeRequest';
import processError from '../../../utils/processError';
import { OK } from '../../../configs/serverStatusCodes';
import {
  loadUserListFailed,
  loadUserListSuccessful,
  loadUserListBegin,
} from '../actions';

export default function* loadUserList(action) {
  try {
    const url = `${BACKEND_URL}/users?with_roles=1`;
    const pool = { limit: 50 };
    yield put(loadUserListBegin());
    const dataResponse = yield makeRequest(url, pool, action, 'GET', true);
    const data = yield dataResponse.json();
    if (dataResponse.status === OK) {
      data.result = data.data;
      yield put(loadUserListSuccessful(data));
      yield call(resolveActionPromise(action, data));
    } else {
      yield put(loadUserListFailed());
      yield call(rejectActionPromise(action, data));
    }
  } catch (e) {
    yield put(loadUserListFailed());
    yield call(rejectActionPromise(action, e));
    yield processError(e);
  }
}
