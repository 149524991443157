import React from 'react';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import FieldWithAttributeDTO from '../../../components/fields/Abstract/FieldWithAttributeDTO';
import { Icons } from '../../../components/base/BootstrapComponents';
import CustomButton from '../../../components/base/Button/CustomButton';
import TextInputWithLabel from '../../../components/fields/TextInput/TextInputWithLabel';
import {
  Dialog, DialogContent, DialogFooter, DialogTitle,
} from '../../../components/base/Dialog/Dialog';

export default function ZonesEditPupUp(props) {
  const { item, onHide } = props;

  const {
    register, getValues, watch, setValue, reset,
  } = useForm({ defaultValues: { ...item } });

  watch();

  return (
    <Dialog
      className={classNames('zone-card-edit-popup')}
      onClose={() => { onHide(); }}
      size="xl"
      open
    >
      <DialogTitle
        className="hff-title"
        title={(
          <>
            <div className="hff-title-text f2">Редактирование зоны {item.name}</div>
            <div className="hff-close-wrapper">
              <Icons width={14} height={14} customIcon icon="clear" className="hff-close" onClick={() => { onHide(); }} />
            </div>
          </>
        )}
      />
      <DialogContent className="hff-content">
        <FieldWithAttributeDTO
          type="text"
          disabled
          component={TextInputWithLabel}
          placeholder=""
          dtoObject={{
            name: 'zones.total',
            type: String,
            validators: [],
            labelKey: 'zones.total',
          }}
          inputProps={{
            value: item.total_spaces,
            ...register,
          }}
        />
        <FieldWithAttributeDTO
          type="text"
          key={item.benefit_spaces}
          component={TextInputWithLabel}
          content={<div className="icon-edit"><Icons width={24} height={24} customIcon icon="edit" /></div>}
          placeholder=""
          dtoObject={{
            name: 'benefit_spaces',
            type: String,
            validators: [],
            labelKey: 'zones.lightweight',
          }}
          inputProps={{
            value: getValues('benefit_spaces'),
            ...register('benefit_spaces'),
          }}
        />
        <FieldWithAttributeDTO
          type="text"
          key={item.name}
          component={TextInputWithLabel}
          content={<div className="icon-edit"><Icons width={24} height={24} customIcon icon="edit" /></div>}
          placeholder=""
          dtoObject={{
            name: 'reserved_spaces',
            type: String,
            validators: [],
            labelKey: 'zones.reserved',
          }}
          inputProps={{
            value: getValues('reserved_spaces'),
            ...register('reserved_spaces'),
          }}
        />
        <FieldWithAttributeDTO
          type="text"
          key={item.name}
          component={TextInputWithLabel}
          content={<div className="icon-edit"><Icons width={24} height={24} customIcon icon="edit" /></div>}
          placeholder=""
          dtoObject={{
            name: 'free_spaces',
            type: String,
            validators: [],
            labelKey: 'zones.free',
          }}
          inputProps={{
            value: getValues('free_spaces'),
            ...register('free_spaces'),
          }}
        />
        <FieldWithAttributeDTO
          type="text"
          key={item.name}
          component={TextInputWithLabel}
          content={<div className="icon-edit"><Icons width={24} height={24} customIcon icon="edit" /></div>}
          placeholder=""
          dtoObject={{
            name: 'busy_spaces',
            type: String,
            validators: [],
            labelKey: 'zones.busy',
          }}
          inputProps={{
            value: getValues('busy_spaces'),
            ...register('busy_spaces'),
          }}
        />
      </DialogContent>
      <DialogFooter className="hff-footer">
        <CustomButton text="Сохранить" onClick={() => {}} />
        <CustomButton isCancel text="Закрыть" onClick={() => { onHide(); }} />
      </DialogFooter>
    </Dialog>
  );
}
