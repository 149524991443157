import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import Loader from '../../../components/base/Loader/Loader';
import './role-page.scss';
import PageHeader from '../../../components/base/PageHeader/PageHeader';
import TabMenu from '../../../components/base/TabMenuMonotoring/TabMenu';
import { Button, Icons } from '../../../components/base/BootstrapComponents';
import FieldDTO from '../../../components/fields/Abstract/FieldDTO';
import FieldWithAttributeDTO from '../../../components/fields/Abstract/FieldWithAttributeDTO';
import TextInput from '../../../components/fields/TextInput/TextInput';
import CheckBoxInputWithLabel from '../../../components/fields/CheckBoxInput/CheckBoxInputWithLabel';
import { filterByName, responseLogWithNotification, useFilterData } from '../../../utils/utils';
import { getRoleData } from '../reducer';
import {
  loadRoleList, loadUserList, loadPermissionList, create, updateRole, removeRole, createUser, updateUser, updateAvatar,
  deleteUser,
} from '../actions';
import {
  Dialog, DialogClose, DialogContent, DialogFooter, DialogTitle,
} from '../../../components/base/Dialog/Dialog';
import TextInputWithLabel from '../../../components/fields/TextInput/TextInputWithLabel';
import notificationDecorator from '../../Notifications/notificationDecorator';
import TooltipWithPortal from '../../../components/base/Tooltip/TooltipWithPortal';
import Dropdown from '../../../components/base/Dropdown/Dropdown';
import CheckBoxInput from '../../../components/fields/CheckBoxInput/CheckBoxInput';
import UserDTO from '../../../dto/UserDTO';
import FileInput from '../../../components/fields/FileInput/FileInput';
import CustomSelectInput from '../../../components/fields/SelectInput/CustomSelectInput';
import { CLIENT_MODULE } from '../../../configs/moduleNames';
import { clientCheck } from '../../Client/actions';
import { showGlobalConfirm } from '../../System/actions';
import TextPasswordInput from '../../../components/fields/TextInput/TextPasswordInput';
import PermissionAccessWrapper from '../../../components/base/PermissionAccessWrapper';
import { getClientData } from '../../Client/reducer';
import RoleDTO from '../../../dto/RoleDTO';

const tabOptions = [
  { value: 0, label: 'Пользователи', permission: ['view_any_user'] },
  { value: 1, label: 'Роли', permission: ['view_any_role'] },
];

const getRoleName = roleList => roles => {
  if (!roleList || !roleList.length || !roles) {
    return '';
  }
  const role = roleList.filter(i => roles.includes(i.id));
  if (!role || !role.length) {
    return '';
  }
  return role.map(i => i.name).join(', ');
};

export default function RolePage() {
  const {
    loadingUserList,
    loadingRoleList,
    loadingPermissionList,
    loadingCreateUser,
    loadingUpdateUser,
    loadingRemoveUser,
    loadingCreateRole,
    loadingUpdateRole,
    loadingRemoveRole,
    loadingUpdateAvatar,
  } = useSelector(getRoleData);
  const { clientData } = useSelector(getClientData);
  const { permissions = [] } = clientData;
  const permission_keys = permissions.map(permission => permission.name);

  const dispatch = useDispatch();
  const [index, setIndex] = useState(0);
  const [timer, setTimer] = useState(null);
  const [isAnimation, setAnimation] = useState(false);
  const handleLoadingList = () => {
    const loadPermission = dispatch(loadPermissionList());
    const loadUsers = dispatch(loadUserList());
    const loadRoles = dispatch(loadRoleList());
    Promise.all([loadUsers, loadRoles, loadPermission]).then(() => { setIndex(index + 1); }).catch(responseLogWithNotification);
  };

  const startTimer = () => {
    setAnimation(true);
    if (timer) {
      clearInterval(timer);
    }
    const int = setInterval(() => {
      setAnimation(false);
    }, 2000);
    setTimer(int);
  };

  let initialTab = tabOptions[0];
  if (permission_keys && permission_keys.length) {
    const findAccessTab = tabOptions.find(t => {
      const a = !!t.permission.every(routePermission => !!permission_keys.find(item => item === routePermission));
      return a;
    });
    if (findAccessTab) {
      initialTab = findAccessTab;
    }
  }

  useEffect(handleLoadingList, []);
  const isAdmin = false; // todo откуда?
  const [selectedTab, setTab] = useState(initialTab);
  return (
    <div className={classNames('role-page content g-0 pb-4')}>
      <Loader global loading={loadingUserList || loadingRoleList || loadingPermissionList || loadingCreateUser || loadingUpdateUser || loadingRemoveUser || loadingCreateRole || loadingUpdateRole || loadingRemoveRole || loadingUpdateAvatar} />
      <PageHeader header="Пользователи и роли" />
      <div className="role-page-content">
        {isAdmin
          ? <div className="frame only-admin">Доступ к разделу «Пользователи, группы и роли»<br /> имеет только администратор</div>
          : (
            <>
              <div>
                <TabMenu
                  selectedTab={selectedTab}
                  options={tabOptions}
                  onSelectOptions={item => setTab(item)}
                />
                {/* <div className={classNames('before-save-timer', { 'animation-start': isAnimation })}>
                  <Loader loading />
                </div> */}
              </div>
              {selectedTab.value === 0
                ? (
                  <UserTabContent update={handleLoadingList} />
                )
                : (
                  <RoleTabContent update={handleLoadingList} setTimer={startTimer} />
                )}
            </>
          )}
      </div>
    </div>
  );
}

export function UserForm(props) {
  const dispatch = useDispatch();
  const { roleList } = useSelector(getRoleData);
  const { clientData = null } = useSelector(state => state[CLIENT_MODULE]);
  const { selectedUser = {}, clearState = () => {}, update = () => {} } = props;
  const uData = { ...selectedUser } || {};
  const isUpdate = selectedUser && selectedUser.id;
  uData.locale = selectedUser && selectedUser.locale ? selectedUser.locale : 'ru';
  uData.is_active = selectedUser
    && selectedUser.settings
    && selectedUser.settings.is_active
    && typeof selectedUser.settings.is_active !== 'undefined'
    ? selectedUser.settings.is_active : true;
  uData.roles = uData.roles ? uData.roles.map(role => role.id) : [];
  const {
    control, handleSubmit,
  } = useForm({
    mode: 'onBlur',
    defaultValues: uData,
  });
  const [file, setFile] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const setFileClear = f => { window.clearFileFunction = f; };
  const handleUpdateAvatar = user_id => {
    const formData = new FormData();
    formData.append('avatar', file.get('file'), 'avatar');
    dispatch(updateAvatar({ formData, id: user_id }))
      .then(user => {
        notificationDecorator({
          title: 'Обновление аватара',
          message: 'Обновление аватара прошло успешно',
        });
        setTimeout(() => {
          update();
        }, 200);
        if (window.clearFileFunction && typeof window.clearFileFunction === 'function') {
          window.clearFileFunction();
        }
      })
      .catch(responseLogWithNotification);
  };
  const handleSave = data => {
    const formData = {
      ...selectedUser,
      ...data,
      password: (showPassword || !isUpdate) && data.password ? data.password : '',
    };

    const user = new UserDTO(formData);
    const userData = user.getData();
    const action = isUpdate ? updateUser({ userData: { ...userData }, id: selectedUser.id }) : createUser(userData);
    dispatch(action)
      .then(user => {
        notificationDecorator({
          title: isUpdate ? 'Редактирование пользователя' : 'Создание пользователя',
          message: isUpdate ? 'Редактирование пользователя прошло успешно' : 'Создание пользователя прошло успешно',
        });
        if (user && user.data && (user.data.id || isUpdate) && file) {
          handleUpdateAvatar((user.data.id || isUpdate));
        } else {
          update();
        }
        if (!isUpdate) {
          clearState();
        }
        if (clientData && clientData.id && clientData.id === user.data.id) {
          setTimeout(() => {
            dispatch(clientCheck());
          }, 400);
        }
      })
      .catch(responseLogWithNotification);
  };

  const DTO = new UserDTO();
  const attributes = DTO.getAttributesByName();
  const defaultAvatar = `${process.env.PUBLIC_URL}/default-avatar.svg`;
  const avatarLink = selectedUser && selectedUser.avatar_url ? selectedUser.avatar_url : defaultAvatar;
  const roleListOptions = roleList.map(i => ({ value: i.id, name: i.name }));
  const activeOptions = [{ value: true, name: 'Разрешен' }, { value: false, name: 'Не разрешен' }];
  const localeOptions = [{ value: 'ru', name: 'RU' }, { value: 'en', name: 'EN' }];
  const [scroll, setScroll] = useState(null);
  useEffect(() => {
    if (scroll) {
      scroll.refresh();
    } else {
      const a = new window.IScroll('#user-form-scroll-wrapper', {
        scrollbars: true,
        mouseWheel: true,
        interactiveScrollbars: true,
        preventDefault: false,
      });
      setScroll(a);
    }
  }, [roleList]);
  const renderLocaleItem = item => (
    <div className="locale-selector-item">
      <div className="locale-item-image"><Icons customIcon className="locale-icon" width={28} height={28} icon={`locale-${item.value}`} /></div>
      <div className="locale-item-name">{item.name}</div>
    </div>
  );
  return (
    <form className="selected-user frame" onSubmit={handleSubmit(handleSave)}>
      <div className="scroll-wrapper" id="user-form-scroll-wrapper">
        <div className="scroll">
          <div className="su-info">
            <div className="su-common-data">
              <div className="su-header">{isUpdate ? 'Основная информация' : 'Добавление пользователя'}</div>
              <PermissionAccessWrapper requiredPermissions={['update_user']} locked>
                <FieldDTO
                  type="text"
                  component={TextInputWithLabel}
                  control={control}
                  dtoObject={attributes.name}
                />
                <FieldDTO
                  type="text"
                  component={TextInputWithLabel}
                  control={control}
                  dtoObject={attributes.email}
                />
                {isUpdate ? <Button className="form-control-wrapper" variant="link" onClick={() => setShowPassword(!showPassword)}>Изменить пароль</Button> : null}
              </PermissionAccessWrapper>
              {showPassword || !isUpdate
                ? (
                  <PermissionAccessWrapper requiredPermissions={['update_user']} locked>
                    <FieldDTO
                      type="password"
                      control={control}
                      component={TextPasswordInput}
                      dtoObject={attributes.password}
                      withLabel
                    />
                  </PermissionAccessWrapper>
                )
                : null }
              <PermissionAccessWrapper requiredPermissions={['update_user']} locked>
                <FieldDTO
                  type="text"
                  control={control}
                  component={CustomSelectInput}
                  dtoObject={attributes.roles}
                  options={roleListOptions}
                  withLabel
                  multiple
                />
                <FieldDTO
                  type="text"
                  control={control}
                  component={CustomSelectInput}
                  dtoObject={attributes.is_active}
                  options={activeOptions}
                  withLabel
                />
                <FieldDTO
                  type="text"
                  control={control}
                  component={CustomSelectInput}
                  dtoObject={attributes.locale}
                  options={localeOptions}
                  withLabel
                  renderOption={renderLocaleItem}
                  customToggleContent={(props, value, options = [], anchorElement) => {
                    const inputOption = options.find(i => i.value === value);
                    return (
                      <div className="form-control-wrapper default" ref={anchorElement}>
                        <div className="form-label">Локаль</div>
                        <div className="input-group with-after">
                          <div className="locale-selector">
                            {inputOption && inputOption.value ? renderLocaleItem(inputOption) : null }
                          </div>
                          <span className="input-group-text">
                            <div className="select-arrow-wrapper">
                              <Icons customIcon className="select-arrow" width={12} height={8} icon="expand-more" />
                            </div>
                          </span>
                        </div>
                      </div>
                    );
                  }}
                />
              </PermissionAccessWrapper>
            </div>
            <div className="su-avatar">
              <div className="su-header">Фотография</div>
              <div className="su-avatar-block" style={{ backgroundImage: `url(${avatarLink})`, backgroundPosition: 'center', backgroundSize: 'cover' }} />
              <div className="su-avatar-load-block">
                <PermissionAccessWrapper requiredPermissions={['update_user']} locked>
                  <FileInput
                    control={control}
                    variant="button"
                    className="avatar-load"
                    label={<><Icons width={24} height={24} customIcon icon="save-alt" className="button-icons" /> Загрузить фотографию</>}
                    input={{
                      onChange: setFile,
                    }}
                    setClear={setFileClear}
                  />
                </PermissionAccessWrapper>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="su-buttons">
        <PermissionAccessWrapper requiredPermissions={['update_user']} locked>
          <Button className="su-save" type="submit">Сохранить</Button>
        </PermissionAccessWrapper>
        <Button className="su-cancel" variant="secondary" onClick={clearState}>Закрыть</Button>
      </div>
    </form>
  );
}

export function UserTabContent(props) {
  const [selectedUser, setSelectedUser] = useState(null);
  const [isCreateUser, setIsCreateUser] = useState(false);
  const { update } = props;
  return (
    <div className="role-list-wrapper">
      <div className="users-block">
        <UserList
          update={update}
          setSelectedUser={value => { setSelectedUser(value); setIsCreateUser(false); }}
          selectedUser={selectedUser}
          handleCreateUser={() => { setIsCreateUser(true); setSelectedUser(null); }}
        />
      </div>
      <div className="permission-block">
        {selectedUser || isCreateUser
          ? <UserForm key={selectedUser ? selectedUser.id : 'create'} selectedUser={selectedUser} update={update} clearState={() => { setSelectedUser(null); setIsCreateUser(false); }} />
          : <div className="not-select-user frame">Выберите пользователя<br /> и тут появится актуальная информация</div>}
      </div>
    </div>
  );
}

export function RoleTabContent(props) {
  const [selectedRole, setSelectedRole] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const { update, setTimer } = props;
  return (
    <div className="role-list-wrapper">
      <div className="group-block">
        <div className="group-names">
          <PermissionAccessWrapper requiredPermissions={['view_any_role']} hided>
            <RoleList update={update} setSelectedRole={setSelectedRole} selectedRole={selectedRole} />
          </PermissionAccessWrapper>
        </div>
        <div className="group-users">
          <PermissionAccessWrapper requiredPermissions={['view_any_role', 'view_role']} hided>
            <UserList update={update} setSelectedUser={setSelectedUser} selectedUser={selectedUser} onlyRoleUsers selectedRole={selectedRole} key={selectedRole && selectedRole.id ? selectedRole.id : null} isRoleTab />
          </PermissionAccessWrapper>
        </div>
      </div>
      <div className="permission-block">
        <PermissionAccessWrapper requiredPermissions={['view_any_role', 'view_role']} hided>
          <RolePermissionList update={update} setTimer={setTimer} selectedRole={selectedRole} key={selectedRole && selectedRole.id ? selectedRole.id : null} />
        </PermissionAccessWrapper>
      </div>
    </div>
  );
}

export function UserList(props) {
  const {
    userList = [],
    roleList,
  } = useSelector(getRoleData);
  const {
    handleChangeFilter, filter, getFilterValue, clearFilter,
  } = useFilterData({}, () => {});
  const dispatch = useDispatch();
  const value = getFilterValue('query');
  let filteredUserList = userList.filter(filterByName(value));

  const {
    update = () => {},
    setSelectedUser = () => {},
    handleCreateUser = () => {},
    selectedUser = null,
    onlyRoleUsers = false,
    selectedRole = null,
    isRoleTab,
  } = props;
  const [scroll, setScroll] = useState(null);
  useEffect(() => {
    if (selectedUser) {
      const user = userList.find(u => u.id === selectedUser.id);
      if (user) {
        setSelectedUser(user);
      } else {
        setSelectedUser(null);
      }
    }
    if (scroll) {
      scroll.refresh();
    } else {
      const a = new window.IScroll('#user-list-scroll-wrapper', {
        scrollbars: true,
        mouseWheel: true,
        interactiveScrollbars: true,
        preventDefault: false,
      });
      setScroll(a);
    }
  }, [filteredUserList]);

  if (onlyRoleUsers) {
    const users = selectedRole && selectedRole.users && selectedRole.users.length ? selectedRole.users : [];
    filteredUserList = users.filter(filterByName(value));
  }
  const disableAdd = onlyRoleUsers && !selectedRole;
  const removeFromRole = user => event => {
    event.preventDefault();
    event.stopPropagation();
    if (!selectedRole) {
      return;
    }
    const users = filteredUserList.map(i => i.id).filter(j => j !== user.id);
    dispatch(showGlobalConfirm({
      confirmTitle: 'Удаление пользователя из роли',
      confirmMessage: `Вы уверены, что хотите удалить пользователя ${user.name} из роли ${selectedRole.name}?`,
      confirmButtonText: 'Удалить',
    })).then(() => {
      dispatch(updateRole({ ...selectedRole, users, permissions: selectedRole.permissions.map(i => i.id) }))
        .then(() => {
          update();
          notificationDecorator({
            title: 'Редактирование роли',
            message: 'Редактирование роли успешно',
          });
        })
        .catch(responseLogWithNotification);
    }).catch(() => {});
  };
  const removeUser = user => event => {
    const {
      id: user_id,
      name,
    } = user;
    event.preventDefault();
    event.stopPropagation();

    dispatch(showGlobalConfirm({
      confirmTitle: 'Удаление пользователя',
      confirmMessage: `Вы уверены, что хотите удалить пользователя ${name}?`,
      confirmButtonText: 'Удалить',
    })).then(() => {
      dispatch(deleteUser(user_id))
        .then(() => {
          update();
          notificationDecorator({
            title: 'Удаление пользователя',
            message: 'Удаление пользователя успешно',
          });
        })
        .catch(responseLogWithNotification);
    }).catch(() => {});
  };

  return (
    <div className="frame role-block">
      <div className="role-block-header">
        <div className="role-block-header-title">Пользователи</div>
        <div className="role-block-header-button">
          <SelectUserList handleCreateUser={handleCreateUser} roleUserList={filteredUserList} disableAdd={disableAdd} onlyRoleUsers={onlyRoleUsers} selectedRole={selectedRole} update={update} />
        </div>
      </div>
      { selectedRole || !isRoleTab ? (
        <div className="role-block-search">
          <FieldWithAttributeDTO
            inputAfter={<Icons customIcon className="search-icon" width={24} height={24} icon="search" />}
            type="text"
            component={TextInput}
            dtoObject={{
              name: 'query',
              type: String,
              validators: [],
              labelKey: 'search',
            }}
            inputProps={{
              value: getFilterValue('query'),
              onChange: handleChangeFilter('query'),
              maxLength: 30,
            }}
            className="small"
          />
        </div>
      ) : null}
      <div className="role-block-list-wrapper">
        <div className="role-block-list">
          <div className="scroll-wrapper" id="user-list-scroll-wrapper">
            <div className="scroll">
              {filteredUserList.map((user, key) => {
                const defaultAvatar = `${process.env.PUBLIC_URL}/default-avatar.svg`;
                const avatarLink = user && user.avatar_url ? user.avatar_url : defaultAvatar;
                const selected = selectedUser && selectedUser.id === user.id;
                const roleName = getRoleName(roleList)(user.role_ids);
                return (
                  <PermissionAccessWrapper key={key + avatarLink + user.name + roleName} requiredPermissions={['view_user']} lockedOnClick>
                    <div
                      className={classNames('rbl-item user-item', { selected })}
                      onClick={() => { setSelectedUser(selected ? null : user); }}
                    >
                      <div className="user-item-avatar" style={{ backgroundImage: `url(${avatarLink})`, backgroundPosition: 'center', backgroundSize: 'cover' }} />
                      <div className="user-item-info">
                        <div className="rbl-item-name">{user.name}</div>
                        <div className="user-item-role">{user.roles ? user.roles.map(role => role.name).join(',') : null}</div>
                      </div>
                      <div className="user-item-remove">
                        <PermissionAccessWrapper requiredPermissions={['delete_user']} lockedOnClick>
                          <Icons width={24} height={24} customIcon icon="delete-outline" className="delete-outline" onClick={onlyRoleUsers ? removeFromRole(user) : removeUser(user)} />
                        </PermissionAccessWrapper>
                      </div>
                    </div>
                  </PermissionAccessWrapper>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function RoleList(props) {
  const { roleList = [] } = useSelector(getRoleData);
  const {
    handleChangeFilter, filter, getFilterValue,
  } = useFilterData({}, () => {});
  const dispatch = useDispatch();
  const value = getFilterValue('query');
  const filteredRoleList = roleList.filter(item => String(item.name).toLowerCase().includes(String(value).toLowerCase()));
  const [open, setOpen] = useState(false);
  const [editRole, setEditRole] = useState(null);
  const { update = () => {}, setSelectedRole = () => {}, selectedRole } = props;
  const [scroll, setScroll] = useState(null);
  useEffect(() => {
    if (selectedRole) {
      const role = roleList.find(r => r.id === selectedRole.id);
      if (role) {
        setSelectedRole(role);
      } else {
        setSelectedRole(null);
      }
    } else {
      // set clear filter
    }
    if (scroll) {
      scroll.refresh();
    } else {
      const a = new window.IScroll('#role-list-scroll-wrapper', {
        scrollbars: true,
        mouseWheel: true,
        interactiveScrollbars: true,
        preventDefault: false,
      });
      setScroll(a);
    }
  }, [filteredRoleList]);
  const closeModal = () => {
    setOpen(false);
    setEditRole(null);
  };
  const handleEditRole = role => () => {
    setOpen(true);
    setEditRole(role);
  };
  const handleRemoveRole = role => () => {
    if (!role || !role.id) {
      return;
    }

    dispatch(showGlobalConfirm({
      confirmTitle: 'Удаление роли',
      confirmMessage: `Вы уверены, что хотите удалить роль ${role.name}?`,
      confirmButtonText: 'Удалить',
    })).then(() => {
      dispatch(removeRole(role.id))
        .then(() => {
          update();
          notificationDecorator({
            title: 'Удаление роли',
            message: 'Удаление прошло успешно',
          });
        })
        .catch(responseLogWithNotification);
    }).catch(() => {});
  };
  return (
    <div className="frame role-block">
      <div className="role-block-header">
        <div className="role-block-header-title">Роли</div>
        <div className="role-block-header-button">
          <PermissionAccessWrapper requiredPermissions={['create_role']} locked>
            <Button variant="secondary" className="small" onClick={() => { setOpen(true); }}>
              <Icons width={16} height={16} customIcon icon="add" className="button-icons" />
              Добавить
            </Button>
          </PermissionAccessWrapper>
        </div>
      </div>
      <div className="role-block-list-wrapper">
        <div className="role-block-list">
          <div className="scroll-wrapper" id="role-list-scroll-wrapper">
            <div className="scroll">
              {filteredRoleList.map((role, key) => {
                const selected = selectedRole && selectedRole.id === role.id;
                return (
                  <div
                    className={classNames('rbl-item role-item', { selected })}
                    key={key}
                    onClick={() => { setSelectedRole(selected ? null : role); }}
                  >
                    <div className="rbl-item-name">{role.name}</div>
                    <div className="rbl-item-more">
                      <PermissionAccessWrapper requiredPermissions={['update_role']} lockedOnClick>
                        <Icons width={24} height={24} customIcon icon="rename-outline" className="rename-outline" onClick={handleEditRole(role)} />
                      </PermissionAccessWrapper>
                      <PermissionAccessWrapper requiredPermissions={['delete_role']} lockedOnClick>
                        <Icons width={24} height={24} customIcon icon="delete-outline" className="delete-outline" onClick={handleRemoveRole(role)} />
                      </PermissionAccessWrapper>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      {open ? <CreateRoleModal handleClose={closeModal} update={update} editRole={editRole} /> : null}
    </div>
  );
}

export function RolePermissionList(props) {
  const {
    permissionList = [],
  } = useSelector(getRoleData);
  const dispatch = useDispatch();
  const { selectedRole = null, update = () => {}, setTimer = () => {} } = props;
  const disabled = !selectedRole;
  const defaultPermissions = selectedRole && selectedRole.permissions && selectedRole.permissions.length ? selectedRole.permissions.map(p => p.id) : [];
  const [selectedPermissions, setSelectedPermissions] = useState(defaultPermissions);
  const [scroll, setScroll] = useState(null);
  const [openedAc, setOpenedAc] = useState(null);
  const setOpened = () => {
    setOpenedAc(Math.random());
  };
  useEffect(() => {
    if (scroll) {
      scroll.refresh();
    } else {
      const a = new window.IScroll('#permission-list-scroll-wrapper', {
        scrollbars: true,
        mouseWheel: true,
        interactiveScrollbars: true,
        preventDefault: false,
      });
      setScroll(a);
    }
  }, [permissionList, openedAc]);
  const setPermissions = permissionsIds => {
    if (!selectedRole) {
      return;
    }
    setSelectedPermissions(permissionsIds);
    setTimer();
    dispatch(updateRole({
      ...selectedRole, permissions: permissionsIds, users: selectedRole.users.map(i => i.id), useDelay: true,
    }))
      .then(() => {
        update();
        notificationDecorator({
          title: 'Редактирование роли',
          message: 'Редактирование роли успешно',
        });
      })
      .catch(responseLogWithNotification);
  };
  return (
    <div className="frame role-block permission-block-content role-accordion-list">
      <div className="role-block-header">
        <div className="role-block-header-title">Доступ к функциям сервиса</div>
      </div>
      <div className="role-block-list-wrapper">
        <div className="role-block-list">
          <div className="scroll-wrapper" id="permission-list-scroll-wrapper">
            <div className="scroll">
              {permissionList.map((e, gKey) => (
                <RoleAccordion
                  key={gKey}
                  permissions={e.permissions}
                  label={e.label}
                  disabled={disabled}
                  setPermissions={setPermissions}
                  selectedPermissions={selectedPermissions}
                  index={gKey}
                  setOpened={setOpened}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function RoleAccordion(props) {
  const {
    permissions = [],
    label,
    disabled,
    selectedPermissions = [],
    index = 0,
    setPermissions = () => {},
    setOpened = () => {},
  } = props;
  const [open, setOpen] = useState(false);
  const permissionsIds = permissions.map(i => i.id);
  const isAll = permissions.every(p => selectedPermissions.includes(p.id));
  const isSome = permissions.some(p => selectedPermissions.includes(p.id)) && !isAll;
  return (
    <div className={classNames('rbl-accordion', { open })}>
      <div className="rbl-accordion-header">
        <div className="rbl-accordion-name">
          <div className="bordered-checkbox">
            <PermissionAccessWrapper requiredPermissions={['update_role']} locked>
              <CheckBoxInput
                className={classNames('rbl-group-checkbox', { 'some-child': isSome }, { 'every-child': isAll })}
                id={`rbl-group-checkbox-${index}`}
                disabled={disabled}
                inputProps={{
                  value: isAll,
                  onChange: e => {
                    if (isAll) {
                      setPermissions(selectedPermissions.filter(sp => !permissionsIds.includes(sp)));
                    } else {
                      setPermissions([...selectedPermissions.filter(sp => !permissionsIds.includes(sp)), ...permissionsIds]);
                    }
                  },
                }}
              />
            </PermissionAccessWrapper>
          </div>
          <div className={classNames('rbl-group-name', { disabled })} onClick={() => { setOpen(!open); setOpened(); }}>
            {label}
          </div>
        </div>
        <div className="rbl-accordion-arrow" onClick={() => { setOpen(!open); setOpened(); }}>
          <Icons customIcon className="select-arrow" width={12} height={8} icon="expand-more" />
        </div>
      </div>
      <div className="rbl-accordion-permissions">
        {permissions.map((permission, pKey) => {
          const { id, key, label } = permission;
          return (
            <div key={pKey} className="rbl-accordion-item">
              <PermissionAccessWrapper requiredPermissions={['update_role']} locked>
                <FieldWithAttributeDTO
                  type="text"
                  component={CheckBoxInputWithLabel}
                  withLabel
                  dtoObject={{
                    name: key,
                    type: String,
                    validators: [],
                    labelKey: 'empty',
                  }}
                  label={label}
                  inputProps={{
                    value: !!selectedPermissions.includes(id),
                    onChange: e => {
                      if (!selectedPermissions.includes(id)) {
                        setPermissions([...selectedPermissions, id]);
                      } else {
                        const newValue = selectedPermissions;
                        const index = selectedPermissions.indexOf(id);
                        if (index > -1) {
                          newValue.splice(index, 1);
                        }
                        setPermissions([...newValue]);
                      }
                    },
                    maxLength: 30,
                  }}
                  className="permission-field"
                  variant="material"
                  disabled={disabled}
                />
              </PermissionAccessWrapper>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export function CreateRoleModal(props) {
  const { handleClose = () => {}, update = () => {}, editRole = {} } = props;
  const dispatch = useDispatch();

  const {
    control, handleSubmit,
  } = useForm({
    mode: 'onBlur',
    defaultValues: editRole,
  });

  const DTO = new RoleDTO();
  const attributes = DTO.getAttributesByName();

  const handleSave = data => {
    const action = editRole && editRole.id ? updateRole({ name: data.name, display_name: data.name, id: editRole.id }) : create({ name: data.name, display_name: data.name });
    dispatch(action)
      .then(() => {
        update();
        handleClose();
        notificationDecorator({
          title: editRole && editRole.id ? 'Редактирование роли' : 'Создание роли',
          message: editRole && editRole.id ? 'Редактирование роли успешно' : 'Создано успешно',
        });
      })
      .catch(responseLogWithNotification);
  };

  return (
    <Dialog
      onClose={handleClose}
      open
      className="role-create-popup"
      size="xl"
    >
      <form onSubmit={handleSubmit(handleSave)}>
        <DialogTitle header="Введите название роли" title={<DialogClose onClick={handleClose} />} />
        <DialogContent>
          <Loader global loading={false} />
          <FieldDTO
            type="text"
            component={TextInput}
            control={control}
            dtoObject={attributes.name}
            withLabel
            label="Название роли"
            placeholder="Введите название роли"
            className="permission-field"
          />
        </DialogContent>
        <DialogFooter>
          <Button className="hff-clear-show" type="submit">Сохранить</Button>
          <Button className="hff-clear-data" variant="secondary" onClick={handleClose}>Отменить</Button>
        </DialogFooter>
      </form>
    </Dialog>
  );
}
export function SelectUserList(props) {
  const {
    userList = [],
    roleList,
  } = useSelector(getRoleData);
  const dispatch = useDispatch();
  const {
    handleChangeFilter, filter, getFilterValue, clearFilter,
  } = useFilterData({}, () => {});

  const {
    disableAdd,
    onlyRoleUsers,
    update = () => {},
    handleCreateUser = () => {},
    selectedRole,
    roleUserList,
  } = props;
  // const defaultUsers = selectedRole && selectedRole.users && selectedRole.users.length ? selectedRole.users : [];
  const defaultUsers = roleUserList && roleUserList.length ? roleUserList.map(i => i.id) : [];
  const [isOpen, setOpen] = useState(false);
  const [selectedUser, setSelectedUsers] = useState(defaultUsers);

  const anchorElement = useRef(null);
  const value = getFilterValue('query');
  const filteredUserList = userList.filter(filterByName(value));
  const handleSave = () => {
    if (!selectedRole) {
      return;
    }
    dispatch(updateRole({ ...selectedRole, users: selectedUser, permissions: selectedRole.permissions.map(i => i.id) }))
      .then(() => {
        update();
        setOpen(false);
        notificationDecorator({
          title: 'Редактирование роли',
          message: 'Редактирование роли успешно',
        });
      })
      .catch(responseLogWithNotification);
  };
  const handleAddUser = () => {
    if (onlyRoleUsers) {
      setOpen(true);
      setSelectedUsers(defaultUsers);
    } else {
      handleCreateUser();
    }
  };
  return (
    <Dropdown
      usePortal
      customControl
      customOpen={isOpen}
      customHandleClose={() => { setOpen(false); }}
    >
      <Dropdown.Toggle>
        <div ref={anchorElement}>
          <PermissionAccessWrapper requiredPermissions={onlyRoleUsers ? ['update_role'] : ['create_user']} locked>
            <Button variant="secondary" className="small" disabled={disableAdd} onClick={handleAddUser}>
              <Icons width={16} height={16} customIcon icon="add" className="button-icons" />
              Добавить
            </Button>
          </PermissionAccessWrapper>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu
        className={classNames('select-user-list-wrapper')}
        anchorElement={anchorElement}
        classNamePortal={classNames('select-user-list-portal')}
        useResizeContent={false}
      >
        <UserDropdown
          filteredUserList={filteredUserList}
          handleSave={handleSave}
          handleCancel={() => {
            setOpen(false);
            setSelectedUsers([]);
          }}
          roleList={roleList}
          getFilterValue={getFilterValue}
          handleChangeFilter={handleChangeFilter}
          selectedUser={selectedUser}
          setSelectedUsers={setSelectedUsers}
        />
      </Dropdown.Menu>
    </Dropdown>
  );
}

export function UserDropdown(props) {
  const {
    handleSave = () => {},
    handleCancel = () => {},
    filteredUserList = [],
    roleList = [],
    getFilterValue,
    handleChangeFilter,
    selectedUser,
    setSelectedUsers,
  } = props;
  const [scroll, setScroll] = useState(null);
  useEffect(() => {
    if (scroll) {
      scroll.refresh();
    } else {
      const a = new window.IScroll('#su-list-scroll-wrapper', {
        scrollbars: true,
        mouseWheel: true,
        interactiveScrollbars: true,
        preventDefault: false,
      });
      setScroll(a);
    }
  }, [roleList, filteredUserList]);
  return (
    <div className="frame role-block">
      <div className="role-block-header">
        <div className="role-block-header-title">Пользователи</div>
      </div>
      <div className="role-block-search">
        <FieldWithAttributeDTO
          inputAfter={<Icons customIcon className="search-icon" width={24} height={24} icon="search" />}
          type="text"
          component={TextInput}
          dtoObject={{
            name: 'query',
            type: String,
            validators: [],
            labelKey: 'search',
          }}
          inputProps={{
            value: getFilterValue('query'),
            onChange: handleChangeFilter('query'),
            maxLength: 30,
          }}
          className="small search-field"
        />
      </div>
      <div className="role-block-list-wrapper">
        <div className="role-block-list">
          <div className="scroll-wrapper" id="su-list-scroll-wrapper">
            <div className="scroll">
              {filteredUserList.map((user, key) => {
                const defaultAvatar = `${process.env.PUBLIC_URL}/default-avatar.svg`;
                const avatar = user && user.avatar_fullpath && user.avatar_fullpath.thumb ? user.avatar_fullpath.thumb : defaultAvatar;
                const avatarLink = `${avatar}?v=${user && user.updated_at ? user.updated_at : '1'}`;
                const roleName = getRoleName(roleList)(user.role_ids);
                return (
                  <label
                    className={classNames('rbl-item user-item')}
                    key={key}
                    htmlFor={`user-item-checkbox-${key}`}
                  >
                    <div className="user-item-select">
                      <CheckBoxInput
                        className="user-item-checkbox"
                        id={`user-item-checkbox-${key}`}
                        inputProps={{
                          value: !!selectedUser.includes(user.id),
                          onChange: e => {
                            if (!selectedUser.includes(user.id)) {
                              setSelectedUsers([...selectedUser, user.id]);
                            } else {
                              const newValue = selectedUser;
                              const index = selectedUser.indexOf(user.id);
                              if (index > -1) {
                                newValue.splice(index, 1);
                              }
                              setSelectedUsers([...newValue]);
                            }
                          },
                        }}
                      />
                    </div>
                    <div className="user-item-avatar" style={{ backgroundImage: `url(${avatarLink})`, backgroundPosition: 'center', backgroundSize: 'cover' }} />
                    <div className="user-item-info">
                      <div className="rbl-item-name">{user.name}</div>
                      <div className="user-item-role">{roleName}</div>
                    </div>
                  </label>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="role-block-list-footer">
        <Button className="rbl-save" onClick={handleSave}>Сохранить</Button>
        <Button className="rbl-cancel" variant="secondary" onClick={handleCancel}>Отменить</Button>
      </div>
    </div>
  );
}
