import { takeLatest } from 'redux-saga/effects';
import { MODULE_NAME } from '../constants';
import { LOAD_SESSION_LIST, LOAD_SESSION_ID } from '../actions';

import loadSessionList from './loadSessionList';
import loadSessionId from './loadSessionId';
import loadSessionStatusList from './loadSessionStatusList';
import loadZoneList from '../../Zones/sagas/loadZoneList';
import loadClientTypeList from '../../Clients/sagas/loadClientTypeList';

export default function* sagas() {
  yield takeLatest(MODULE_NAME + LOAD_SESSION_LIST, loadSessionList);
  yield takeLatest(MODULE_NAME + LOAD_SESSION_ID, loadSessionId);
  yield takeLatest(MODULE_NAME + LOAD_SESSION_LIST, loadSessionStatusList);
  yield takeLatest(MODULE_NAME + LOAD_SESSION_LIST, loadZoneList);
  yield takeLatest(MODULE_NAME + LOAD_SESSION_LIST, loadClientTypeList);
}
