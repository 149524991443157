import moment from 'moment';
import DTO from './DTO';
import {
  maxLength30,
  minLength3,
} from '../utils/validation';
import ListType from './converters/ListType';
import RoutesDTO from './RoutesDTO';

class RoleDTO extends DTO {
  constructor(params, isValidInit) {
    const attributes = [
      {
        name: 'id',
        type: String,
        validators: {},
        labelKey: 'role.id',
      },
      {
        name: 'display_name',
        type: String,
        validators: { maxLength30, minLength3 },
        labelKey: 'role.display_name',
      },
      {
        name: 'name',
        type: String,
        validators: { maxLength30, minLength3 },
        labelKey: 'role.name',
      },
      {
        name: 'is_root',
        type: Boolean,
        validators: {},
        labelKey: 'role.is_root',
      },
      {
        name: 'privileges',
        type: ListType(RoutesDTO),
        validators: {},
        labelKey: 'role.privileges',
      },
      {
        name: 'created_at',
        type: moment,
        validators: {},
        labelKey: 'role.created_at',
      },
      {
        name: 'updated_at',
        type: moment,
        validators: {},
        labelKey: 'role.updated_at',
      },
    ];
    super(attributes, params, isValidInit);
  }

  getData() {
    return {
      name: this.name,
      privileges: this.privileges.map(i => i.getDataForRole()),
    };
  }
}

export default RoleDTO;
