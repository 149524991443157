export const resolveActionPromise = (action, data) => () => {
  if (action
    && action.promiseId
    && window.actionsPromises[action.promiseId]
    && window.actionsPromises[action.promiseId].resolvePromise
    && !window.actionsPromises[action.promiseId].final
  ) {
    window.actionsPromises[action.promiseId].resolvePromise(data);
    window.actionsPromises[action.promiseId].final = true;
  }
};
