import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import './hosts-page.scss';
import { Icons } from '../../../components/base/BootstrapComponents';
import {
  tabOptions, defaultFilterValues,
} from './moks';
import TextInput from '../../../components/fields/TextInput/TextInput';
import FieldWithAttributeDTO from '../../../components/fields/Abstract/FieldWithAttributeDTO';
import PageHeader from '../../../components/base/PageHeader/PageHeader';
import TabMenu from '../../../components/base/TabMenu/TabMenu';
import CustomSelectInput from '../../../components/fields/SelectInput/CustomSelectInput';
import HostGrid from './HostsGrid';
import { loadHostList, loadActionHistory } from '../actions';
import { loadEventList } from '../../EventLog/actions';
import HostsFilterForm from './HostsFilterForm';
import IconBox from '../../../components/common/IconBox';
import SeparateBox from '../../../components/base/SeparateBox/SeparateBox';
import HostStatus from './HostStatus';
import YandexMap from '../../../components/base/Map/YandexMap';
import { responseLogWithNotification, getPaginationOpts } from '../../../utils/utils';
import { getHostData } from '../reducer';
import { getEventData } from '../../EventLog/reducer';

export default function HostsPage() {
  const dispatch = useDispatch();
  const [selectedTab, isSelectedTab] = useState(1);
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [selectedRow, setSelectedRow] = useState(false);

  const saveTab = item => {
    isSelectedTab(item.value);
  };

  const {
    eventList,
    eventListMeta,
  } = useSelector(getEventData);

  const {
    hostList,
    hostListMeta,
    hostActionHistory,
    hostActionHistoryMeta,
    clientTypeList,
    parkingZoneList,
  } = useSelector(getHostData);

  const {
    register, getValues, watch, setValue, reset,
  } = useForm({
    defaultValues: JSON.parse(localStorage.getItem('host-filter')) || { ...defaultFilterValues },
  });

  const clientTypeOptions = clientTypeList.map(clientType => ({ name: clientType.label, value: clientType.value }));
  const zoneOptions = parkingZoneList.map(zone => ({ name: zone.name, value: zone.id }));

  const handlerClickRow = item => {
    setSelectedRow(item);
  };

  const handleLoadingEventList = opts => {
    if (opts && !opts.page) {
      opts.page = 1;
    }
    const paginationOpts = getPaginationOpts(eventListMeta, opts);
    const requestData = {
      ...paginationOpts,
      filter: { session_num: selectedRow.num },
    };

    const action = loadEventList;
    dispatch(loadEventList(action(requestData))).catch(responseLogWithNotification);
  };

  const handleLoadingHostList = opts => {
    const filterData = getValues();
    if (opts && !opts.page) {
      opts.page = 1;
    }
    const paginationOpts = getPaginationOpts(hostListMeta, opts);
    const requestData = {
      ...paginationOpts,
      filter: filterData,
    };
    const action = loadHostList;
    dispatch(loadHostList(action(requestData))).catch(responseLogWithNotification);
  };

  const handleActionHistory = opts => {
    const filterData = getValues();
    if (opts && !opts.page) {
      opts.page = 1;
    }
    const paginationOpts = getPaginationOpts(hostActionHistoryMeta, opts);
    const requestData = {
      ...paginationOpts,
      filter: { host_id: selectedRow.id },
    };
    const action = loadActionHistory;
    dispatch(loadActionHistory(action(requestData))).catch(responseLogWithNotification);
  };

  watch();

  useEffect(() => {
    const subscription = watch(value => {
      handleLoadingHostList();
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => handleLoadingHostList(), []);

  return (
    <div className="hosts-page">
      <div className="hosts-wrapper">
        <div className="hosts-inputs-box">
          <div className={classNames('hosts-info', { variant: selectedRow })}>
            {
                selectedRow ? (
                  <div className="path">
                    <span className="path-main" onClick={() => setSelectedRow(null)}>Мониторинг хостов</span> / <span>Статус хоста</span>
                  </div>
                ) : null
              }
            <PageHeader header={selectedRow ? 'Статус хоста' : 'Мониторинг хостов'} />
            {/* { !selectedRow ? (
              <TabMenu
                selectedTab={selectedTab}
                options={tabOptions}
                onSelectOptions={item => { saveTab(item); }}
              />
            ) : null } */}
          </div>
          {
            !selectedRow ? (
              <div className="hosts-inputs">
                <FieldWithAttributeDTO
                  inputBefore={<Icons customIcon className="search-icon" width={24} height={24} icon="search" />}
                  type="text"
                  component={TextInput}
                  dtoObject={{
                    name: 'search',
                    type: String,
                    validators: [],
                    labelKey: 'search',
                  }}
                  inputProps={{
                    ...register('search', { maxLength: 30 }),
                    value: getValues('search'),
                    onChange: e => {
                      setValue('search', e.target.value);
                      localStorage.setItem('columns-filter-order', JSON.stringify({ ...getValues() }));
                    },
                  }}
                  className="small no-border search-field "
                />
                <FieldWithAttributeDTO
                  type="text"
                  dtoObject={{
                    name: 'zones',
                    type: String,
                    validators: [],
                    labelKey: 'zones',
                  }}
                  inputProps={{
                    ...register('zones', { maxLength: 30 }),
                    onChange: e => {
                      setValue('zones', e.target.value);
                      localStorage.setItem('columns-filter-order', JSON.stringify({ ...getValues() }));
                    },
                    value: getValues('zones') || '',
                  }}
                  component={CustomSelectInput}
                  options={zoneOptions}
                  className="no-border select-field"
                />
                {/* <FieldWithAttributeDTO
                  type="text"
                  dtoObject={{
                    name: 'group',
                    type: String,
                    validators: [],
                    labelKey: 'group',
                  }}
                  inputProps={{
                    ...register('group', { maxLength: 30 }),
                    onChange: e => {
                      setValue('group', e.target.value);
                      localStorage.setItem('columns-filter-order', JSON.stringify({ ...getValues() }));
                    },
                    value: getValues('group') || '',
                  }}
                  component={CustomSelectInput}
                  options={clientTypeList}
                  className="no-border select-field"
                /> */}
                {/* <SeparateBox />
                <IconBox icon="export" /> */}
                {/* <SeparateBox />
                <IconBox icon="filter" onClick={() => { setIsOpenFilter(true); }} /> */}
                {/* {isOpenFilter
                  ? (
                    <HostsFilterForm
                      onHide={() => { setIsOpenFilter(false); }}
                      setValue={setValue}
                      getValues={getValues}
                      register={register}
                      reset={reset}
                      parkingZoneList={parkingZoneList}
                      defaultValues={defaultFilterValues}
                      clientTypeList={clientTypeOptions}
                      meta={hostListMeta}
                    />
                  )
                  : null} */}
              </div>
            )
              : null
          }
        </div>
        { selectedTab === 1 && !selectedRow ? <HostGrid meta={hostListMeta} handleLoadingEventList={handleLoadingEventList} handleLoadingHostList={handleLoadingHostList} data={hostList} onClickByRow={handlerClickRow} /> : null }
        { selectedTab === 1 && selectedRow ? <HostStatus setValue={setValue} hostActionHistoryMeta={hostActionHistoryMeta} handleActionHistory={handleActionHistory} hostActionHistory={hostActionHistory} eventList={eventList} eventListMeta={eventListMeta} handleLoadingEventList={handleLoadingEventList} hostList={selectedRow} /> : null }
        { selectedTab === 2 ? <YandexMap zones={[]} selectedZone={{}} setZones={() => {}} /> : null }
        {/* <HostSettingPopup selectedRow={hostList[0]} /> */}
      </div>
    </div>
  );
}
