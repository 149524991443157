import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import {
  Dialog, DialogContent, DialogFooter, DialogTitle,
} from './Dialog';
import { Button } from '../BootstrapComponents';

export class ConfirmModal extends Component {
  render() {
    const {
      show = false,
      onHide,
      onSubmit,
      title = '',
      confirmMessage = '',
      buttonText = '',
      cancelButtonText = '',
      className = '',
      hideSubmit = false,
    } = this.props;

    return (
      <Dialog
        className={classNames('confirm-modal', className)}
        onClose={onHide}
        open={show}
      >
        {title && <DialogTitle header={title} />}
        <DialogContent>
          {confirmMessage}
        </DialogContent>
        <DialogFooter>
          <Button variant="light" onClick={onHide}>{cancelButtonText || <FormattedMessage id="app.cancel" />}</Button>
          {!hideSubmit ? <Button onClick={onSubmit}>{buttonText}</Button> : null}
        </DialogFooter>
      </Dialog>
    );
  }
}

export default ConfirmModal;
