import { takeLatest } from 'redux-saga/effects';
import { MODULE_NAME } from '../constants';
import { LOAD_PAYMENT_LIST } from '../actions';

import loadPaymentTypeList from './loadPaymentTypeList';
import loadPaymentList from './loadPaymentList';
import loadPaymentMethodList from './loadPaymentMethodList';
import loadZoneList from '../../Zones/sagas/loadZoneList';

export default function* sagas() {
  yield takeLatest(MODULE_NAME + LOAD_PAYMENT_LIST, loadPaymentList);
  yield takeLatest(MODULE_NAME + LOAD_PAYMENT_LIST, loadPaymentTypeList);
  yield takeLatest(MODULE_NAME + LOAD_PAYMENT_LIST, loadPaymentMethodList);
  // yield takeLatest(MODULE_NAME + LOAD_PAYMENT_LIST, loadZoneList);
}
