import React, { useState, useRef } from 'react';
import {
  YMaps,
  Map,
  Rectangle,
  Polygon,
} from '@pbe/react-yandex-maps';
import { Icons } from '../BootstrapComponents';
import './map.scss';

export default function YandexMap(props) {
  const { selectedZone, zones, setZones } = props;
  const [isEditMode, setIsEditMode] = useState(false);
  const mapRef = useRef(null);
  const polygon = useRef(null);
  const [zoom, setZoom] = useState(18);
  const centerMap = selectedZone && selectedZone.map ? selectedZone.map.centerMap : [55.750620, 37.617253];

  const changeMode = () => {
    setIsEditMode(!isEditMode);
    polygon.current.editor.startDrawing();
    if (!isEditMode) {
      polygon.current.editor.stopDrawing();
      if (setZones) {
        selectedZone.map.geometry = polygon.current.geometry.getCoordinates();
        setZones([selectedZone, ...zones.filter(zone => zone.id !== selectedZone.id)]);
      }
    }
  };

  return (
    <div className="map">
      <YMaps>
        <Map instanceRef={mapRef} modules={['geoObject.addon.editor']} state={{ center: centerMap, zoom }} style={{ width: '100%', height: '100%' }}>
          { selectedZone && selectedZone.map && selectedZone.map.img
            ? (
              <Rectangle
                geometry={selectedZone.map.imgCoord}
                options={{
                  fillImageHref: selectedZone.map && selectedZone.map.img,
                  fillOpacity: 1,
                  strokeWidth: 0,
                }}
              />
            ) : null}
          {zones.filter(zone => zone.map.geometry).map(zone => (
            <Polygon
              key={zone}
              geometry={zone.map.geometry}
              options={{
                editorDrawingCursor: 'crosshair',
                editorMaxPoints: 5,
                fillColor: '#FFF4E580',
                strokeColor: '#E38905',
                fillOpacity: 1,
                strokeWidth: 1,
              }}
            />
          ))}
          <Polygon
            instanceRef={polygon}
            geometry={[]}
            options={{
              dragstart: () => console.log('test'),
              editorDrawingCursor: 'crosshair',
              editorMaxPoints: 5,
              fillColor: '#FFF4E580',
              strokeColor: '#E38905',
              fillOpacity: 1,
              strokeWidth: 2,
              draggable: true,
            }}
          />
          <div className="map-button-editor" onClick={() => changeMode()}>
            <Icons width={24} height={24} customIcon icon="edit-polygon" className="button-icons" />
          </div>
          <div className="map-button-center" onClick={() => mapRef.current.setCenter(centerMap)}>
            <Icons width={24} height={24} customIcon icon="center-map" className="button-icons" />
          </div>
          <div className="map-button-box">
            <div className="map-button" onClick={() => setZoom(zoom + 1)}>
              <Icons width={28} height={28} customIcon icon="plus" className="button-icons" />
            </div>
            <div className="map-button" onClick={() => setZoom(zoom - 1)}>
              <Icons width={28} height={28} customIcon icon="minus" className="button-icons" />
            </div>
          </div>
        </Map>
      </YMaps>
    </div>
  );
}
