import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../components/base/BootstrapComponents';

function NotFound() {
  const navigate = useNavigate();
  return (
    <div className="login-page-wrapper not-found-page-wrapper">
      <div className="not-found-page">
        <div className="ground" />
        <div className="parking-meter" />
        <div className="camera-1" />
        <div className="car" />
        <div className="cloud-1" />
        <div className="cloud-2" />
        <div className="cloud-3" />
        <div className="cloud-4" />
        <div className="not-found-block-wrapper">
          <div className="not-found-block-border">
            <div className="not-found-block">
              <div className="text-1">404</div>
              <div className="text-2">Страница не найдена</div>
              <div className="text-3">
                <Button className="big" onClick={() => { navigate(window.baseRoute || '/'); }}>
                  Вернуться на главную страницу
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
