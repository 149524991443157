import { put, call } from 'redux-saga/effects';
import { rejectActionPromise } from '../../../utils/rejectActionPromise';
import { resolveActionPromise } from '../../../utils/resolveActionPromise';
import { BACKEND_URL } from '../../../configs';
import makeRequest from '../../../utils/makeRequest';
import processError from '../../../utils/processError';
import { OK } from '../../../configs/serverStatusCodes';
import {
  loadPermissionListFailed,
  loadPermissionListSuccessful,
  loadPermissionListBegin,
} from '../actions';

export default function* loadPermissionList(action) {
  try {
    const url = `${BACKEND_URL}/permissions/all?group=1`;
    const pool = {};
    yield put(loadPermissionListBegin());
    const dataResponse = yield makeRequest(url, pool, action, 'GET', true);
    const data = yield dataResponse.json();
    const result = { result: data };
    if (dataResponse.status === OK) {
      yield put(loadPermissionListSuccessful(result));
      yield call(resolveActionPromise(action, result));
    } else {
      yield put(loadPermissionListFailed());
      yield call(rejectActionPromise(action, data));
    }
  } catch (e) {
    yield put(loadPermissionListFailed());
    yield call(rejectActionPromise(action, e));
    yield processError(e);
  }
}
