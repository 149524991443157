import React from 'react';
import classNames from 'classnames';
import './hosts-directory-page.scss';
import {
  Dialog, DialogContent, DialogFooter, DialogTitle,
} from '../../../components/base/Dialog/Dialog';
import CustomButton from '../../../components/base/Button/CustomButton';
import { Icons } from '../../../components/base/BootstrapComponents';
import TextInputWithLabel from '../../../components/fields/TextInput/TextInputWithLabel';
import FieldWithAttributeDTO from '../../../components/fields/Abstract/FieldWithAttributeDTO';

export default function HostSettingPopup(props) {
  const {
    onHide,
    mode,
    selectedRow,
  } = props;

  return (
    <Dialog
      className={classNames('host-popup')}
      onClose={() => { onHide(); }}
      size="xl"
      open
    >
      <DialogTitle
        className="hff-title"
        title={(
          <>
            <div className="hff-title-text f2">Выбор периферийных модулей и ПО</div>
            <div className="hff-close-wrapper">
              <Icons width={14} height={14} customIcon icon="clear" className="hff-close" onClick={onHide} />
            </div>
          </>
        )}
      />
      <DialogContent className="hff-content custom-scroll-theme">
        <div className="form-description">
          <span className="form-description-title">Все оборудование и ПО</span>
          <div>Чтобы добавить оборудование и ПО к хосту, нажмите на него. Выбранное оборудование становится серого цвета. Чтобы убрать оборудование нажмите на него повторно. </div>
        </div>
        <div className="form-equpment">
          <span className="form-equpment-title">Периферийные модули</span>
          <div className="equipments-group">
            {selectedRow.components.filter(item => item.type !== 'recognition').map(item => (
              <div className="equipments-group-items" key={item}>
                <Icons width={32} height={32} customIcon icon={item.type} className="button-icons" />
                <div className="equipments-group-items-title">{item.name}</div>
              </div>
            ))}
          </div>
        </div>
      </DialogContent>
      <DialogFooter className="hff-footer">
        <CustomButton text="Сохранить" onClick={() => {}} />
        <CustomButton isCancel text="Отменить" onClick={() => { onHide(); }} />
      </DialogFooter>
    </Dialog>
  );
}
