import notificationDecorator from '../modules/Notifications/notificationDecorator';
import { intlShape } from '../components/base/IntlProviderWrapper';

export const processFormError = response => {
  const {
    formatMessage,
  } = intlShape;
  if (!response) {
    return window.console.log('Сервер не вернул тело ошибки.');
  }
  if (response instanceof Error) {
    window.console.error(response.message);
    notificationDecorator({
      title: formatMessage({ id: 'app.error.title' }),
      message: response.message || formatMessage({ id: 'app.unknown.error' }),
      isError: true,
    });
  }
  if (response.error && typeof response.error.message !== 'string') {
    return window.console.error(response.error.message);
  }
  return response.error ? response.error.message : '';
};
