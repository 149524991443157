import { put, call } from 'redux-saga/effects';
import { rejectActionPromise } from '../../../utils/rejectActionPromise';
import { resolveActionPromise } from '../../../utils/resolveActionPromise';
import { BACKEND_URL } from '../../../configs';
import makeRequest from '../../../utils/makeRequest';
import processError from '../../../utils/processError';
import { OK } from '../../../configs/serverStatusCodes';
import {
  loadSessionIdFailed,
  loadSessionIdSuccessful,
  loadSessionIdBegin,
} from '../actions';

export default function* loadSessionId(action) {
  try {
    const {
      id,
    } = action.payload;

    const url = `${BACKEND_URL}/sessions/${id}`;
    yield put(loadSessionIdBegin());
    const dataResponse = yield makeRequest(url, {}, action, 'GET', true);
    const data = yield dataResponse.json();
    if (dataResponse.status === OK) {
      data.result = data.data;
      yield put(loadSessionIdSuccessful(data));
      yield call(resolveActionPromise(action, data));
    } else {
      yield put(loadSessionIdFailed());
      yield call(rejectActionPromise(action, data));
    }
  } catch (e) {
    yield put(loadSessionIdFailed());
    yield call(rejectActionPromise(action, e));
    yield processError(e);
  }
}
