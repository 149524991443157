import moment from 'moment';
import DTO from './DTO';

class MetaDTO extends DTO {
  constructor(params, isValidInit) {
    const attributes = [
      {
        name: 'limit',
        type: String,
      },
      {
        name: 'page',
        type: String,
      },
      {
        name: 'server_time',
        type: moment,
        couldBeNull: true,
      },
      {
        name: 'total',
        type: String,
      },
    ];
    super(attributes, params, isValidInit);
  }
}

export default MetaDTO;
