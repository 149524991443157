export default function addFilterQueryWithCondition(filterData, pool) {
  const filter = [];
  Object.entries(filterData).forEach(el => {
    const [field, { condition, value }] = el;
    if (typeof value === 'undefined') {
      filter.push(`${field}||${condition}`);
    } else if (value !== '' && value.length && field && condition) {
      filter.push(`${field}||${condition}||${value}`);
    } else if (typeof value === 'boolean') {
      filter.push(`${field}||${condition}||${value}`);
    } else {
      delete filter[field];
    }
  });
  pool.filter = filter;
  return pool;
}
