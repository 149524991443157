export default class CustomEventListener {
  constructor() {
    this.listenerList = [];
  }

  emit(event) {
    for (let i = 0; i < window.customEventListener.listenerList.length; i++) {
      if (window.customEventListener.listenerList[i].event === event.type) {
        window.customEventListener.listenerList[i].callback.call(this, event);
      }
    }
  }

  addEventListener(event, callback, key) {
    window.customEventListener.listenerList.push({ event, callback, key });
  }

  removeEventListener(event, key) {
    const index = window.customEventListener.listenerList.findIndex(i => event === i.event && key === i.key);
    if (index > -1) {
      window.customEventListener.listenerList.splice(index, 1);
    }
  }
}
