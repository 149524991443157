import * as classNames from 'classnames';
import React from 'react';
import './PageHeaderStyles.scss';

export default function PageHeader(props) {
  const {
    children,
    className = '',
    header = '',
  } = props;

  return (
    <div className={classNames('page-header', className)}>
      <div className="header-text">
        {header}
      </div>
      <div className="header-buttons">
        {children}
      </div>
    </div>
  );
}
