import moment from 'moment';
import ListType from './converters/ListType';
import DTO from './DTO';
import {
  email,
  maxLength50,
  minLength3,
  trimError, onlyLettersAndHyphen,
} from '../utils/validation';

class UserDTO extends DTO {
  constructor(params, isValidInit) {
    const attributes = [
      {
        name: 'id',
        type: String,
        validators: {},
        labelKey: 'user.id',
      },
      {
        name: 'token',
        type: String,
        validators: {},
        labelKey: 'user.token',
      },
      {
        name: 'user_id',
        type: String,
        validators: {},
        labelKey: 'user.user_id',
      },
      {
        name: 'login',
        type: String,
        validators: { maxLength50 },
        labelKey: 'login',
      },
      {
        name: 'email',
        type: String,
        validators: { maxLength50, email },
        labelKey: 'user.email',
      },
      {
        name: 'password',
        type: String,
        // validators: {required, maxLength255, minLength5},
        validators: { maxLength50 },
        labelKey: 'password',
      },
      {
        name: 'password_confirmation',
        type: String,
        // validators: {required, maxLength255, minLength5},
        validators: { maxLength50 },
        labelKey: 'password_confirmation',
      },
      {
        name: 'name',
        type: String,
        validators: {
          maxLength50, minLength3, onlyLettersAndHyphen, trimError,
        },
        labelKey: 'name',
      },
      {
        name: 'is_active',
        type: Boolean,
        validators: {},
        labelKey: 'is_active',
      },
      {
        name: 'role_id',
        type: String,
        validators: {},
        labelKey: 'role_id',
      },
      {
        name: 'roles',
        type: ListType(Object),
        validators: {},
        labelKey: 'roles',
      },
      {
        name: 'avatar',
        type: String,
        validators: {},
        labelKey: 'avatar',
      },
      {
        name: 'avatar_url',
        type: Object,
        validators: {},
        labelKey: 'avatar_url',
      },
      {
        name: 'settings',
        type: ListType(Object),
        validators: {},
        labelKey: 'avatar',
      },
      {
        name: 'email_verified_at',
        type: moment,
        validators: {},
        labelKey: 'user.email_verified_at',
      },
      {
        name: 'created_at',
        type: moment,
        validators: {},
        labelKey: 'created_at',
      },
      {
        name: 'updated_at',
        type: moment,
        validators: {},
        labelKey: 'user.updatedAt',
      },
      {
        name: 'roles',
        type: ListType(Object),
        validators: {},
        labelKey: 'roles',
      },
      {
        name: 'is_active',
        type: Boolean,
        validators: {},
        labelKey: 'is_active',
      },
      {
        name: 'locale',
        type: String,
        validators: {},
        labelKey: 'locale',
      },
    ];
    super(attributes, params, isValidInit);
  }

  getRegistrationData() {
    return {
      email: this.email,
      password: this.password,
    };
  }

  getData() {
    const data = {
      email: this.email,
      name: this.name,
      role_ids: this.roles,
      is_active: this.is_active,
      settings: {
        locale: this.locale,
      },
    };
    if (this.password) {
      data.password = this.password;
      data.password_confirmation = this.password;
    }
    return data;
  }

  getLoginData() {
    return {
      email: this.email,
      password: this.password,
    };
  }

  getForgotData() {
    return {
      email: this.email,
    };
  }
}

export default UserDTO;
