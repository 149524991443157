import EventLogPage from './components/EventLogPage';

const routes = [
  {
    path: '/event-log/:page?',
    name: 'menu.event-log',
    icon: 'mon-event',
    customIcon: true,
    loginRequired: true,
    component: EventLogPage,
    weight: 101,
    permission: ['frontend_menu_event_logs'],
    children: [],
  },
];

export default routes;
