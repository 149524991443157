import PaymentLogPage from './components/PaymentLogPage';

const routes = [
  {
    path: '/payment-log/:page?',
    name: 'menu.payment-log',
    icon: 'mon-payment',
    customIcon: true,
    loginRequired: true,
    component: PaymentLogPage,
    weight: 101,
    permission: ['frontend_menu_payments'],
    children: [],
  },
];

export default routes;
