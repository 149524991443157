import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import './SelectInputStyles.scss';
import { Icons } from '../../base/BootstrapComponents';
import Dropdown from '../../base/Dropdown/Dropdown';

export default function UpdatePeriodSelectInput(props) {
  const {
    inputProps = null,
    className = '',
    options = [],
    renderOption = null,
    multiple = false,
    disabled = false,
  } = props;
  const intl = useIntl();
  const { formatMessage } = intl;
  const [toggleFunction, setToggleFunction] = useState(() => () => {});
  const anchorElement = useRef(null);
  const defaultRenderOption = item => (!item.name && item.nameId ? formatMessage({ id: item.nameId }) : item.name || '');
  let renderOptionFunction = defaultRenderOption;
  if (renderOption && typeof renderOption === 'function') {
    renderOptionFunction = renderOption;
  }
  const value = inputProps.value || 0;

  const onChange = item => {
    if (inputProps && inputProps.onChange) {
      let changeValue = item.value;
      if (multiple) {
        const newValue = [...value];
        const index = value.indexOf(item.value);
        if (index > -1) {
          newValue.splice(index, 1);
        } else {
          newValue.push(item.value);
        }
        changeValue = newValue;
      }
      inputProps.onChange({ target: { value: changeValue } });
    }
  };

  let iconName = '';
  let inputValue = '';

  if (!multiple) {
    const inputOption = options.find(i => i.value === value);
    iconName = inputOption ? inputOption.icon : 'update-period-0';
    inputValue = inputOption ? defaultRenderOption(inputOption) : '';
  } else {
    const selectedItems = options.filter(item => value.includes(item.value)).map(defaultRenderOption);
    inputValue = selectedItems.join(', ');
  }

  return (
    <div className={classNames('form-control-wrapper', className)}>
      <Dropdown
        dropBottom
        usePortal
        disabled={disabled}
        setToggleFunction={a => setToggleFunction(() => a)}
      >
        <Dropdown.Toggle className="select-toggle">
          <div ref={anchorElement} className="mini-profile-update-period">
            <Icons width={30} height={30} customIcon icon={iconName} className="button-icons" />
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu disableClickToggle={multiple} rightPadding={32} className="select-options-wrapper center" anchorElement={anchorElement} classNamePortal="portal-select-options">
          <div className="select-options-list">
            {options.map((item, key) => {
              let isSelected = item.value === value;
              if (multiple) {
                isSelected = !!value.includes(item.value);
              }
              return (
                <div
                  key={key}
                  className={classNames('select-option', { updatePeriod: true })}
                  onClick={() => { onChange(item); }}
                >
                  {renderOptionFunction(item)}
                </div>
              );
            })}
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}
