import Empty from '../../components/base/Empty';
import HostsDirectoryPage from './components/HostsDirectoryPage';

const routes = [
  {
    path: '/hosts-directory-page/:page?',
    name: 'menu.hosts-directory-page',
    icon: 'host-directory',
    customIcon: true,
    loginRequired: true,
    component: HostsDirectoryPage,
    weight: 101,
    permission: ['frontend_menu_hosts'],
    children: [],
  },
];

export default routes;
