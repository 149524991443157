import React from 'react';
import { Controller } from 'react-hook-form';
import { intlShape } from '../../base/IntlProviderWrapper';

export default function FieldDTO(props) {
  const {
    intl,
    dtoObject,
    labelKey,
    namePrefix = '',
    additionalValidators = [],
    inputProps = {},
    control,
    component: Componet = null,
  } = props;

  if (!Componet) { return null; }

  const { formatMessage } = intlShape;
  let name = 'not-found-dto';
  let dtoLabelKey = 'not-found-dto';
  let dtoValidators = [];
  if (!dtoObject || !dtoObject.labelKey) {
    // throw new Error('Dto object not found');
    window.console.error('Dto object not found');
  } else {
    name = dtoObject.name;
    dtoLabelKey = dtoObject.labelKey;
    dtoValidators = dtoObject.validators;
  }

  if (namePrefix) {
    name = `${namePrefix}.${name}`;
  }

  const validators = {
    ...dtoValidators,
    ...additionalValidators,
  };

  return (
    <Controller
      name={dtoObject.name}
      control={control}
      rules={{ validate: { ...validators } }}
      render={({
        field,
        fieldState,
        formState,
      }) => (
        <Componet
          fieldState={fieldState}
          formState={formState}
          label={formatMessage({ id: labelKey || dtoLabelKey })}
          placeholder={formatMessage({ id: `${dtoLabelKey}.placeholder`, defaultMessage: ' ' })}
          inputProps={{ ...field, ...inputProps }}
          {...props}
        />
      )}
    />
  );
}
