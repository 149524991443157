import {
  sessionNumberFormatter,
  dateOrDateTimeFormatter,
} from '../../utils/formatters';

export const defaultFilterValues = {
  search: '',
  paymentMethod: '',
  paymentType: '',
  session_num: '',
  parking_zone_id: '',
  client_id: '',
  session_id: '',
  host_name: '',
  paid_at: '',
  amount: '',
  method: '',
  type: '',
  card_num: '',
  rrn: '',
  auth_code: '',
};

export const defaultColumns = [
  // {
  //   dataField: 'checkBox',
  //   disableSort: true,
  //   text: 'Выбор строки',
  //   hidden: true,
  //   headerClassName: 'column-0 text-center column-relative',
  //   dataClassName: 'align-middle column-relative',
  // },
  {
    dataField: 'client_id',
    text: 'ИД клиента',
    disableSort: true,
    dataClassName: 'align-middle',
    headerClassName: 'column-1',
    hidden: true,
  },
  {
    dataField: 'session',
    text: 'Номер сессии',
    disableSort: true,
    hidden: true,
    headerClassName: 'column-2',
    formatter: e => sessionNumberFormatter(e.id),
    dataClassName: 'align-middle',
  },
  {
    dataField: 'host',
    text: 'Имя хоста',
    formatter: e => e.name,
    disableSort: true,
    hidden: true,
    headerClassName: 'column-3',
    dataClassName: 'align-middle',
  },
  {
    dataField: 'paid_at',
    text: 'Дата, время оплаты',
    disableSort: true,
    dataClassName: 'align-middle',
    formatter: date => dateOrDateTimeFormatter(date),
    headerClassName: 'column-4',
    hidden: true,
  },
  {
    dataField: 'amount',
    text: 'Сумма оплаты, руб',
    disableSort: true,
    dataClassName: 'align-middle',
    headerClassName: 'column-5',
    hidden: true,
  },
  {
    dataField: 'method',
    text: 'Способ оплаты',
    disableSort: true,
    dataClassName: 'align-middle',
    headerClassName: 'column-6',
    hidden: true,
  },
  {
    dataField: 'type',
    text: 'Тип платежа',
    disableSort: true,
    headerClassName: 'column-7',
    dataClassName: 'align-middle',
    hidden: true,
  },
  {
    dataField: 'card_num',
    text: 'Номер карты',
    disableSort: true,
    dataClassName: 'align-middle',
    headerClassName: 'column-8',
    hidden: true,
  },
  {
    dataField: 'rrn',
    text: 'RRN',
    disableSort: true,
    dataClassName: 'align-middle',
    headerClassName: 'column-9',
    hidden: true,
  },
  {
    dataField: 'auth_code',
    text: 'Код авторизации',
    disableSort: true,
    dataClassName: 'align-middle',
    headerClassName: 'column-10',
    hidden: true,
  },
];
