import { createAction } from 'redux-actions';
import { createDeferredAction } from '../../utils/redux';

import {
  BEGIN,
  CREATE,
  FAILED,
  SUCCESSFUL,
} from '../../configs/actionNames';
import { CLIENTS_MODULE } from '../../configs/moduleNames';

export const LOAD_CLIENT_LIST = '_LOAD_CLIENT_LIST';
export const LOAD_CLIENT_TYPE_LIST = '_LOAD_CLIENT_TYPE_LIST';
export const DELETE_USER = '_DELETE_USER';
export const UPDATE_USER = '_UPDATE_USER';
export const CREATE_USER = '_CREATE_USER';

export const create = createDeferredAction(CLIENTS_MODULE + CREATE);
export const createSuccessful = createAction(CLIENTS_MODULE + CREATE + SUCCESSFUL);
export const createFailed = createAction(CLIENTS_MODULE + CREATE + FAILED);

export const loadCliientList = createDeferredAction(CLIENTS_MODULE + LOAD_CLIENT_LIST);
export const loadCliientListBegin = createAction(CLIENTS_MODULE + LOAD_CLIENT_LIST + BEGIN);
export const loadCliientListSuccessful = createAction(CLIENTS_MODULE + LOAD_CLIENT_LIST + SUCCESSFUL);
export const loadCliientListFailed = createAction(CLIENTS_MODULE + LOAD_CLIENT_LIST + FAILED);

export const deleteUser = createDeferredAction(CLIENTS_MODULE + DELETE_USER);
export const deleteUserSuccessful = createAction(CLIENTS_MODULE + DELETE_USER + SUCCESSFUL);
export const deleteUserFailed = createAction(CLIENTS_MODULE + DELETE_USER + FAILED);

export const updateUser = createDeferredAction(CLIENTS_MODULE + UPDATE_USER);
export const updateUserSuccessful = createAction(CLIENTS_MODULE + UPDATE_USER + SUCCESSFUL);
export const updateUserFailed = createAction(CLIENTS_MODULE + UPDATE_USER + FAILED);

export const createUser = createDeferredAction(CLIENTS_MODULE + CREATE_USER);
export const createUserSuccessful = createAction(CLIENTS_MODULE + CREATE_USER + SUCCESSFUL);
export const createUserFailed = createAction(CLIENTS_MODULE + CREATE_USER + FAILED);

export const loadCliientTypeList = createDeferredAction(CLIENTS_MODULE + LOAD_CLIENT_TYPE_LIST);
export const loadCliientTypeListBegin = createAction(CLIENTS_MODULE + LOAD_CLIENT_TYPE_LIST + BEGIN);
export const loadCliientTypeListSuccessful = createAction(CLIENTS_MODULE + LOAD_CLIENT_TYPE_LIST + SUCCESSFUL);
export const loadCliientTypeListFailed = createAction(CLIENTS_MODULE + LOAD_CLIENT_TYPE_LIST + FAILED);
