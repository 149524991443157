import React from 'react';
import { FormattedMessage } from 'react-intl';

export default function renderMessage(data) {
  if (!data) {
    return null;
  }
  if (data && data.id) {
    return (
      <FormattedMessage
        id={data.id}
        values={data.values}
      />
    );
  }
  return data.message || data;
}
