import React, { useState } from 'react';
import TextInput from './TextInput';
import { Icons } from '../../base/BootstrapComponents';

export default function TextPasswordInput(props) {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <TextInput
      {...props}
      type={showPassword ? 'text' : 'password'}
      // autocomplete="new-password"
      inputAfter={<Icons className="password-icon" onClick={() => setShowPassword(!showPassword)} icon={showPassword ? 'eye-fill' : 'eye-slash-fill'} />}
    />
  );
}
