import { HIDE_SYSTEM_ROUTE } from '../../configs';
import { SYSTEM_MODULE } from '../../configs/moduleNames';
import PermissionDeniedPage from './components/PermissionDeniedPage';
import NotFoundPage from './components/NotFoundPage';
import ComponentsPage from './components/ComponentsPage';
import NotFoundPageWithOutLogin from './components/NotFoundPageWithOutLogin';

const routes = [
  {
    path: '/components',
    name: 'menu.components',
    icon: 'bank',
    loginRequired: true,
    component: ComponentsPage,
    weight: 999,
    permission: [],
    children: [],
    hideOnMenu: true,
    forMobile: false,
  },
  {
    path: '*',
    name: 'menu.notFound',
    icon: 'pe-7s-note2',
    permission: SYSTEM_MODULE,
    hideOnMenu: HIDE_SYSTEM_ROUTE,
    loginRequired: true,
    forMobile: true,
    component: NotFoundPage,
    weight: 999,
  },
  {
    path: '*',
    name: 'menu.notFound',
    icon: 'pe-7s-note2',
    permission: SYSTEM_MODULE,
    hideOnMenu: HIDE_SYSTEM_ROUTE,
    loginRequired: true,
    forMobile: false,
    component: NotFoundPage,
    weight: 999,
  },
  {
    path: '*',
    name: 'menu.notFound',
    icon: 'pe-7s-note2',
    permission: SYSTEM_MODULE,
    hideOnMenu: HIDE_SYSTEM_ROUTE,
    loginRequired: false,
    forMobile: false,
    component: null,
    weight: 999,
  },
  {
    loginRequired: false,
    redirect: true,
    path: '*',
    permission: SYSTEM_MODULE,
    hideOnMenu: HIDE_SYSTEM_ROUTE,
    to: '/',
    weight: 999,
  },
  {
    loginRequired: false,
    redirect: true,
    path: '*',
    permission: SYSTEM_MODULE,
    hideOnMenu: HIDE_SYSTEM_ROUTE,
    to: '/',
    forMobile: true,
    weight: 999,
  },
];

export default routes;
