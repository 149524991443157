import ru from 'moment/locale/ru';
import moment from 'moment';
import { DateObject } from 'react-multi-date-picker';

moment.locale('ru', ru);

export default function getDefaultDataRange(count, type) {
  const today = moment();
  const subtract = today.clone()
    .startOf('day')
    .subtract(count, type);
  return [new DateObject({ date: subtract.toDate() }), new DateObject({ date: today.clone().endOf('day').toDate() })];
}
