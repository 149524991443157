import React, { useState } from 'react';
import * as ReactDOM from 'react-dom';
import classNames from 'classnames';
import {
  Dialog, DialogContent, DialogFooter, DialogTitle,
} from '../Dialog/Dialog';
import CheckBoxInputWithLabel from '../../fields/CheckBoxInput/CheckBoxInputWithLabel';
import './setting-column.scss';
import { Button, Icons } from '../BootstrapComponents';

export default function SettingColumn(props) {
  const {
    open = false,
    lockOnPopup = false,
    availableColumns = [],
    columnsOrder = [],
    onHide = () => {},
    setColumnsOrder = () => {},
    setVisibleColumns = () => {},
    resetVisible = () => {},
    resetOrder = () => {},
    saveVisible = () => {},
    saveOrder = () => {},
    visibleColumns = {},
  } = props;
  const height = 52.5;
  const maxIndex = availableColumns.length - 1;
  const columns = availableColumns.map((e, index) => ({
    ...e,
    style: Object.assign(
      {
        height,
        top: columnsOrder.indexOf(e.id) * height,
      },
      maxIndex !== columnsOrder.indexOf(e.id) && {
        borderBottom: '1px solid #E2E8F1',
      },
    ),
  }));
  const [targetElement, setTargetElement] = useState(null);
  const [styleGhost, setStyleGhost] = useState(null);
  const [startPos, setStartPos] = useState(0);
  const [targetPos, setTargetPos] = useState(null);

  if (!open) {
    return null;
  }

  const calculateOrder = elem => {
    const y = elem.clientY - startPos;
    let element = null;
    if (y < 0 && columnsOrder.indexOf(targetElement.id)) {
      element = columns.find(i => i.id === columnsOrder[0]);
    } else if (y > (columns.length * height) && columnsOrder.indexOf(targetElement.id) < (columns.length - 1)) {
      element = columns.find(i => i.id === columnsOrder[columns.length - 1]);
    } else {
      element = columns.find(i => checkRange(y, i) && i.id !== targetElement.id);
    }
    if (element) {
      const tmp = [...columnsOrder];
      const currentIndex = columnsOrder.indexOf(targetElement.id);
      const newIndex = columnsOrder.indexOf(element.id);
      const el = tmp.splice(currentIndex, 1);
      const before = tmp.splice(0, newIndex);
      const res = [...before, ...el, ...tmp];
      setColumnsOrder(res);
    }
  };

  const cancel = () => {
    resetVisible();
    resetOrder();
  };

  const save = () => {
    saveVisible(visibleColumns);
    saveOrder(columnsOrder);
    onHide();
  };

  const checkRange = (cursorY, e) => cursorY >= e.style.top && cursorY <= (e.style.top + height);

  const onMouseUp = e => {
    document.body.classList.remove('drag-cursor');
    setTargetPos(null);
    setTargetElement(null);
    setStyleGhost(null);
  };

  const onMouseMove = elem => {
    if (targetElement) {
      setStyleGhost({
        top: elem.clientY - targetPos.y,
        left: lockOnPopup ? targetPos.left : (elem.clientX - targetPos.x),
        width: targetPos.width,
        borderBottom: '1px solid #E2E8F1',
        borderTop: '1px solid #E2E8F1',
      });
      calculateOrder(elem);
    }
  };

  const onMouseDown = e => {
    const selected = columns.find(c => c.id === e.target.id);
    if (selected && !selected.disablePosition) {
      document.body.classList.add('drag-cursor');
      const targetRect = e.target.getBoundingClientRect();
      const currentTargetRect = e.currentTarget.getBoundingClientRect();
      setStartPos(currentTargetRect.y);
      setTargetPos({
        y: e.clientY - targetRect.y,
        x: e.clientX - currentTargetRect.x,
        left: targetRect.left,
        width: currentTargetRect.width,
      });
      setTargetElement(e.target);
    }
  };

  const renderCheckBox = (item, index) => (
    <CheckBoxInputWithLabel
      key={index}
      type="text"
      name={item.id}
      label={item.text}
      withLabel
      afterContent={<Icons customIcon className="move-icon" width={24} height={24} icon="move-icon" />}
      style={item.style}
      blockId={item.id}
      className={classNames(item.classNames, 'column-row')}
      addPlaceholder
      disabled={item.disableVisible}
      inputProps={{
        value: visibleColumns[item.id],
        onChange: e => {
          setVisibleColumns({ ...visibleColumns, [item.id]: e.target.value });
        },
      }}
    />
  );

  function TargetElement() {
    if (!targetElement || !targetPos) {
      return null;
    }
    const elem = { ...columns.find(e => e.id === targetElement.id) };
    const props = {
      ...elem,
      classNames: 'ghost',
      style: styleGhost,
      name: targetElement.id,
      inputProps: {
        value: visibleColumns[targetElement.id],
      },
    };
    return styleGhost ? (ReactDOM.createPortal(renderCheckBox({ ...props }, 0), document.body)) : null;
  }
  return (
    <Dialog
      className={classNames('setting-column')}
      onClose={() => { cancel(); onHide(); }}
      onMouseMove={onMouseMove}
      onMouseUp={onMouseUp}
      open
    >
      <DialogTitle header="Выберите столбцы" title={<Icons onClick={() => { cancel(); onHide(); }} customIcon icon="close" className="close" />} />
      <DialogContent className="hff-content">
        <div className="hff-wrapper" onMouseDown={onMouseDown}>
          {columns.map(renderCheckBox)}
          <TargetElement />
        </div>
      </DialogContent>
      <DialogFooter className="hff-footer">
        <div className="hff-footer-buttons">
          <Button className="hff-clear-show" onClick={save}>Сохранить</Button>
          <Button className="hff-clear-data" variant="secondary" onClick={cancel}>Отменить</Button>
        </div>
      </DialogFooter>
    </Dialog>
  );
}
