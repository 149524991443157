import React from 'react';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import FieldWithAttributeDTO from '../../../components/fields/Abstract/FieldWithAttributeDTO';
import TextInputWithLabel from '../../../components/fields/TextInput/TextInputWithLabel';
import SelectInput from '../../../components/fields/SelectInput/SelectInput';
import DatePicker from '../../../components/fields/DatePicker/DatePicker';
import CustomButton from '../../../components/base/Button/CustomButton';
import { statusOptions } from '../moks';
import ClientCardInfo from './ClientCardInfo';
import { clientStatusFormatter } from '../../../utils/formatters';
import CustomSelectInput from '../../../components/fields/SelectInput/CustomSelectInput';

export default function ClientForm(props) {
  const {
    setMode,
    setSelectedRow,
    selectedItem = null,
    mode,
    zoneOptions,
    clientTypeOptions,
    updateClient,
  } = props;

  const {
    register, getValues, watch, setValue,
  } = useForm({
    defaultValues: {
      ...selectedItem,
      card_num: '',
      isEdit: selectedItem,
    },
  });

  watch();

  return (
    <div className="client-form">
      <span className="client-form-title">Основная информация</span>
      <div className="client-form-data">
        <div className="client-form-data-box">
          { mode === 'edit' ? (
            <FieldWithAttributeDTO
              type="text"
              className="short"
              disabled
              component={TextInputWithLabel}
              dtoObject={{
                name: 'id',
                type: String,
                validators: [],
                labelKey: 'client.client_id',
              }}
              inputProps={{
                value: (selectedItem && selectedItem.id) || '',
              }}
            />
          ) : null}
          <FieldWithAttributeDTO
            type="text"
            className="short"
            dtoObject={{
              name: 'type',
              type: String,
              validators: [],
              labelKey: 'client.clientType',
            }}
            inputProps={{
              value: getValues('type'),
              ...register('type'),
            }}
            withLabel
            addPlaceholder
            component={SelectInput}
            options={clientTypeOptions}
          />
          <FieldWithAttributeDTO
            type="text"
            component={TextInputWithLabel}
            addPlaceholder="Номер карты"
            className={mode === 'create' ? 'short' : ''}
            dtoObject={{
              name: 'card_num',
              type: String,
              validators: [],
              labelKey: 'client.card_num',
            }}
            inputProps={{
              value: getValues('card_num'),
              ...register('card_num'),
            }}
          />
          <FieldWithAttributeDTO
            type="text"
            className="short"
            component={TextInputWithLabel}
            dtoObject={{
              name: 'lpn',
              type: String,
              validators: [],
              labelKey: 'client.1pn',
            }}
            inputProps={{
              value: getValues('lpn'),
              ...register('lpn'),
            }}
          />
          <FieldWithAttributeDTO
            type="text"
            className="short"
            dtoObject={{
              name: 'parking_zone_id',
              type: String,
              validators: [],
              labelKey: 'client.zone',
            }}
            inputProps={{
              value: getValues('parking_zone_id'),
              ...register('parking_zone_id'),
            }}
            withLabel
            addPlaceholder
            component={SelectInput}
            options={zoneOptions}
          />
          <DatePicker
            hideSmallButtons
            showArrowIcon
            withLabel
            label="Срок действия с"
            dtoObject={{
              name: 'period_start_dt',
              type: String,
              validators: [],
              labelKey: 'client.dateStart',
            }}
            usePortal
            handleChangeDate={date => { setValue('period_start_dt')({ target: { value: selectedItem.period_start_dt } }); }}
            className="short"
            inputProps={{
              value: getValues('period_start_dt'),
              ...register('period_start_dt'),
            }}
          />
          <DatePicker
            hideSmallButtons
            showArrowIcon
            usePortal
            withLabel
            // withFormat="YYYY-MM-DDTHH:mm:ssZ"
            label="Срок действия по"
            dtoObject={{
              name: 'period_end_dt',
              type: String,
              validators: [],
              labelKey: 'client.dateEnd',
            }}
            wi
            handleChangeDate={date => { setValue('period_end_dt')({ target: { value: selectedItem.period_end_dt } }); }}
            className="short"
            inputProps={{
              value: getValues('period_end_dt'),
              ...register('period_end_dt'),
            }}
          />
          <FieldWithAttributeDTO
            type="text"
            className={mode === 'create' ? 'short' : ''}
            dtoObject={{
              name: 'status',
              type: String,
              validators: [],
              labelKey: 'client.clientStatus',
            }}
            renderOption={item => (
              clientStatusFormatter(item.value)
            )}
            inputProps={{
              ...register('status'),
              value: getValues('status'),
              onChange: e => {
                setValue('status', e.target.value);
              },
            }}
            withLabel
            addPlaceholder
            component={CustomSelectInput}
            options={statusOptions}
          />
          <FieldWithAttributeDTO
            type="text"
            className={mode === 'create' ? 'short' : ''}
            component={TextInputWithLabel}
            dtoObject={{
              name: 'description',
              type: String,
              validators: [],
              labelKey: 'client.description',
            }}
            inputProps={{
              value: getValues('description'),
              ...register('description'),
            }}
          />
          <div className="empty" />
        </div>
        { selectedItem && mode === 'edit' ? (
          <div className="client-form-data-card-wrapper">
            <ClientCardInfo selectedItem={selectedItem} />
            {/* <div className="client-form-data-controls">
              <CustomButton isCancel text="Распечатать" className="extend" />
            </div> */}
          </div>
        ) : null }
      </div>
      <div className="client-form-control">
        <CustomButton text="Сохранить" onClick={() => { updateClient(getValues()); setMode(false); setSelectedRow(null); }} />
        <CustomButton isCancel text="Закрыть" onClick={() => { setMode(false); setSelectedRow(null); }} />
      </div>
    </div>
  );
}
