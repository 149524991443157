import DTO from './DTO';
import ListType from './converters/ListType';

class ListDTO extends DTO {
  constructor(itemType, params, isValidInit) {
    const attributes = [
      {
        name: 'data',
        type: ListType(itemType),
        validators: [],
      },
    ];
    super(attributes, params, isValidInit);
  }
}

export default ListDTO;
