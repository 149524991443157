import React from 'react';
import ReactDOM from 'react-dom/client';
import { configureStore } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import createSagaMiddleware from 'redux-saga';
import { isMobile } from 'react-device-detect';
import { deferredMiddleware, promiseMiddleware } from './utils/redux';
import Main from './components/Main';
import IntlProviderWrapper from './components/base/IntlProviderWrapper';
import CustomEventListener from './utils/customEventListener';
import reducers from './configs/reducers';
import rootSaga from './configs/rootSaga';

window.activeDialogList = [];
window.actionsPromises = {};

export default class BaseEntryPoint {
  constructor() {
    this.init();
  }

  init() {
    window.customEventListener = new CustomEventListener();
    const sagaMiddleware = createSagaMiddleware();
    const middlewares = [
      deferredMiddleware,
      sagaMiddleware,
      promiseMiddleware,
    ];
    const store = configureStore({
      reducer: reducers,
      middleware: getDefaultMiddleware => getDefaultMiddleware({
        serializableCheck: false,
      }).concat(middlewares),
      devTools: true,
    });
    sagaMiddleware.run(rootSaga);
    this.rootRender(store);
    window.appStore = store;
  }

  rootRender(store) {
    const root = ReactDOM.createRoot(window.document.getElementById('root'));
    if (isMobile) {
      window.document.body.classList.add('mobile-layout');
    }
    root.render(
      <Provider store={store}>
        <IntlProviderWrapper>
          <BrowserRouter>
            <Main />
          </BrowserRouter>
        </IntlProviderWrapper>
      </Provider>,
    );
  }
}
