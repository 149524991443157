import { takeEvery } from 'redux-saga/effects';
import clientCheck from './clientCheck';
import clientLogin from './clientLogin';
import clientLogout from './clientLogout';
import clientRegistration from './clientRegistration';
import {
  CLIENT_CHECK, CLIENT_LOGIN, CLIENT_LOGOUT, CLIENT_REGISTRATION, FORGOT_PASSWORD,
} from '../actions';
import { CLIENT_MODULE } from '../../../configs/moduleNames';
import forgotPassword from './forgotPassword';

export default function* watchClient() {
  yield takeEvery(CLIENT_MODULE + CLIENT_REGISTRATION, clientRegistration);
  yield takeEvery(CLIENT_MODULE + CLIENT_LOGIN, clientLogin);
  yield takeEvery(CLIENT_MODULE + CLIENT_LOGOUT, clientLogout);
  yield takeEvery(CLIENT_MODULE + CLIENT_CHECK, clientCheck);
  yield takeEvery(CLIENT_MODULE + FORGOT_PASSWORD, forgotPassword);
}
