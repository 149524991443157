import { handleActions } from 'redux-actions';

import { createLoadListReducerWithBegin } from '../../utils/getCrudConfig';
import { MODULE_NAME } from './constants';
import { SESSION_LOG_PAGE_MODULE } from '../../configs/moduleNames';
import {
  LOAD_SESSION_LIST, LOAD_SESSION_STATUS_LIST, LOAD_SESSION_ID,
} from './actions';
import {
  LOAD_ZONE_LIST,
} from '../Zones/actions';
import {
  LOAD_CLIENT_TYPE_LIST,
} from '../Clients/actions';
import {
  LOAD_EVENT_LIST,
} from '../EventLog/actions';
import {
  LOAD_PAYMENT_LIST,
} from '../PaymentLog/actions';

const initialState = {
  sessionList: [],
  sessionStatusList: [],
  parkingZoneList: [],
  clientTypeList: [],
  sessionListMeta: [],
  paymentList: [],
  eventList: [],
  session: {},
};
const ZONES_PAGE = 'ZONES_PAGE';
const CLIENT_PAGE = 'CLIENTS_PAGE';
const EVENT_LOG_PAGE = 'EVENT_LOG_PAGE';
const PAYMENT_LOG_PAGE = 'PAYMENT_LOG_PAGE';

const reducer = handleActions({
  ...createLoadListReducerWithBegin(MODULE_NAME + LOAD_SESSION_LIST, 'sessionList', 'loadingSessionList'),
  ...createLoadListReducerWithBegin(MODULE_NAME + LOAD_SESSION_STATUS_LIST, 'sessionStatusList', 'loadSessionStatus'),
  ...createLoadListReducerWithBegin(MODULE_NAME + LOAD_SESSION_ID, 'session', 'loadSessionId'),
  ...createLoadListReducerWithBegin(ZONES_PAGE + LOAD_ZONE_LIST, 'parkingZoneList', 'loadZoneList'),
  ...createLoadListReducerWithBegin(CLIENT_PAGE + LOAD_CLIENT_TYPE_LIST, 'clientTypeList', 'loadClientTypeList'),
}, initialState);

export default reducer;

export const getSessionData = state => {
  const reducerData = state[SESSION_LOG_PAGE_MODULE];
  const {
    sessionList,
    parkingZoneList,
    sessionStatusList,
    sessionListMeta,
    clientTypeList,
    session,
  } = reducerData;

  return {
    sessionList,
    session,
    parkingZoneList,
    sessionStatusList,
    clientTypeList,
    sessionListMeta,
  };
};
