import Empty from '../../components/base/Empty';
import ServiceMonitoringPage from './components/ServiceMonitoringPage';

const routes = [
  {
    path: '/',
    name: 'menu.service-monitoring',
    icon: 'mon-service',
    customIcon: true,
    loginRequired: true,
    component: ServiceMonitoringPage,
    weight: 101,
    permission: ['frontend_menu_services'],
    children: [],
  },
];

export default routes;
