import {
  call, put, delay,
} from 'redux-saga/effects';
import { BACKEND_URL } from '../../../configs';
import { rejectActionPromise } from '../../../utils/rejectActionPromise';
import makeRequest from '../../../utils/makeRequest';
import { OK } from '../../../configs/serverStatusCodes';
import { resolveActionPromise } from '../../../utils/resolveActionPromise';
import processError from '../../../utils/processError';
import { clientCheckSuccess, clientLoginFailed, clientLogoutSuccessful } from '../actions';
import { endLoadApp } from '../../System/actions';

export default function* clientCheck(action) {
  try {
    const url = 'https://ultra.fast.its-profit.ru/api/auth/user';
    const pool = {};
    const userDataResponse = yield makeRequest(url, pool, action, 'GET');
    const clientData = yield userDataResponse.json();
    if (userDataResponse) {
      yield put(clientCheckSuccess(clientData.data));
      yield call(resolveActionPromise(action, clientData.datas));
    } else {
      yield put(clientLoginFailed(clientData));
      yield call(rejectActionPromise(action, clientData));
    }
  } catch (e) {
    yield put(clientLogoutSuccessful(e));
    yield processError(e);
  }
}
