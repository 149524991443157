import { createAction } from 'redux-actions';
import { createDeferredAction } from '../../utils/redux';

import {
  BEGIN,
  FAILED,
  SUCCESSFUL,
} from '../../configs/actionNames';
import { HOSTS_MODULE } from '../../configs/moduleNames';

export const LOAD_HOST_LIST = '_LOAD_HOST_LIST';
export const LOAD_ACTION_HISTORY = '_LOAD_ACTION_HISTORY';

export const loadHostList = createDeferredAction(HOSTS_MODULE + LOAD_HOST_LIST);
export const loadHostListBegin = createAction(HOSTS_MODULE + LOAD_HOST_LIST + BEGIN);
export const loadHostListSuccessful = createAction(HOSTS_MODULE + LOAD_HOST_LIST + SUCCESSFUL);
export const loadHostListFailed = createAction(HOSTS_MODULE + LOAD_HOST_LIST + FAILED);

export const loadActionHistory = createDeferredAction(HOSTS_MODULE + LOAD_ACTION_HISTORY);
export const loadActionHistoryBegin = createAction(HOSTS_MODULE + LOAD_ACTION_HISTORY + BEGIN);
export const loadActionHistorySuccessful = createAction(HOSTS_MODULE + LOAD_ACTION_HISTORY + SUCCESSFUL);
export const loadActionHistoryFailed = createAction(HOSTS_MODULE + LOAD_ACTION_HISTORY + FAILED);
