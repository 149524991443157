import { handleActions } from 'redux-actions';

import { createLoadListReducerWithBegin } from '../../utils/getCrudConfig';
import { MODULE_NAME } from './constants';
import { ZONES_PAGE_MODULE } from '../../configs/moduleNames';
import {
  LOAD_ZONE_LIST, LOAD_ZONE_STATUS_LIST,
} from './actions';

const initialState = {
  zoneList: [],
  zoneListMeta: [],
  zoneStatusList: [],
};

const reducer = handleActions({
  ...createLoadListReducerWithBegin(MODULE_NAME + LOAD_ZONE_LIST, 'zoneList', 'loadingZoneList'),
  ...createLoadListReducerWithBegin(MODULE_NAME + LOAD_ZONE_STATUS_LIST, 'zoneStatusList', 'loadZoneStatusList'),
}, initialState);

export default reducer;

export const getZoneData = state => {
  const reducerData = state[ZONES_PAGE_MODULE];
  const {
    zoneList,
    zoneStatusList,
    zoneListMeta,
  } = reducerData;

  return {
    zoneList,
    zoneStatusList,
    zoneListMeta,
  };
};
