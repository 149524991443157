import routes from './routes';
import sagas from './sagas';
import reducer from './reducer';

const addModule = {
  routes,
  sagas,
  reducer,
};
export default addModule;
