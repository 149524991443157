import { handleActions } from 'redux-actions';
import {
  createLoadByIdReducer, createLoadListReducerWithBegin, createOperationStateReducer, initialStateCRUD,
} from '../../utils/getCrudConfig';
import { MODULE_NAME } from './constants';
import {
  CREATE, DELETE_BY_ID, LOAD_BY_ID, LOAD_LIST, UPDATE,
} from '../../configs/actionNames';

const initialState = {
  ...initialStateCRUD,
  loadingStats: false,
};

const reducer = handleActions({
  ...createLoadListReducerWithBegin(MODULE_NAME + LOAD_LIST, 'list', 'loadingList'),
  ...createLoadByIdReducer(MODULE_NAME + LOAD_BY_ID, 'current', 'loadingCurrent'),
  ...createOperationStateReducer(MODULE_NAME + CREATE, 'loadingCreate'),
  ...createOperationStateReducer(MODULE_NAME + DELETE_BY_ID, 'loadingDelete'),
  ...createOperationStateReducer(MODULE_NAME + UPDATE, 'loadingUpdate'),
}, initialState);

export default reducer;
