import classNames from 'classnames';
import React from 'react';
import Chart from 'react-apexcharts';
import { DEFAULT_CHART_COLORS } from '../../configs';
import Loader from '../base/Loader/Loader';

export default function ApexColumnChart(props) {
  const {
    series = [],
    labels = [],
    barLabels = [],
    title = '',
    className = '',
    renderFooter = () => {
    },
    loading = false,
    distributed = false,
    height = 'auto',
    hideLegend = false,
    onClick,
  } = props;

  const data = series.map((row, i) => ({ data: row, name: barLabels[i] }));
  const options = {
    chart: {
      height,
      width: '100%',
      type: 'bar',
      redrawOnParentResize: true,
      redrawOnWindowResize: true,
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 800,
        animateGradually: {
          enabled: true,
          delay: 150,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350,
        },
      },
      offsetX: 0,
    },
    colors: DEFAULT_CHART_COLORS,
    plotOptions: {
      bar: {
        borderRadius: 4,
        borderRadiusApplication: 'end',
        dataLabels: {
          position: 'top',
        },
        // columnWidth: '70%',
        distributed,
      },
    },
    dataLabels: {
      enabled: true,
      offsetY: -22,
      background: {
        enabled: true,
        foreColor: '#758192',
      },
      style: {
        fontSize: '10px',
        fontWeight: 'normal',
        fontFamily: '"GOSTUI2", "Helvetica Neue", Helvetica, Arial, sans-serif',
        colors: ['#fff'],
      },
      formatter(val) {
        return String(val);
      },
    },
    xaxis: {
      categories: labels,
      position: 'bottom',
      tooltip: {
        enabled: false,
      },
      labels: {
        show: true,
        rotate: 0,
        // trim: true,
        style: {
          colors: '#758192',
          fontSize: '10px',
          fontWeight: 'normal',
          fontFamily: '"GOSTUI2", "Helvetica Neue", Helvetica, Arial, sans-serif',
          cssClass: 'chart-data-label',
        },
      },
    },
    yaxis: {
      // decimalsInFloat: 0,
      // labels: {
      //   rotate: 0,
      //   rotateAlways: false,
      //   formatter: val => val.toFixed(0),
      // },
      labels: {
        show: true,
        rotate: 0,
        // trim: true,
        style: {
          colors: '#758192',
          fontSize: '10px',
          fontWeight: 'normal',
          fontFamily: '"GOSTUI2", "Helvetica Neue", Helvetica, Arial, sans-serif',
          cssClass: 'chart-data-label',
        },
        formatter: val => val.toFixed(0),
      },
    },
    title: {
      text: title,
      style: {
        color: '#213450',
        fontSize: '18px',
        fontWeight: '600',
        fontFamily: 'GOSTUI2, Helvetica Neue, Helvetica, Arial, sans-serif',
      },
    },
    tooltip: {
      y: {
        // formatter: undefined,
        title: {
          formatter: (seriesName, opts) => [barLabels[opts.seriesIndex]],
        },
      },
    },
    grid: {
      show: true,
      borderColor: '#E2E8F1',
      position: 'back',
    },
    fill: {
      opacity: 1,
    },
  };

  if (hideLegend) {
    options.legend = {
      show: false,
    };
  }
  if (onClick && typeof onClick === 'function') {
    options.chart.events = {
      click: onClick,
    };
  }

  return (
    <div className={classNames('card-block-wrapper', className)}>
      <Loader loading={loading} />
      <div className="card-block chart-block">
        <div className="chart-block-content">
          <div className="chart-graph">
            <div className="apex-chart-wrapper">
              <Chart options={options} series={data} type="bar" height={height} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
