import { call, put } from 'redux-saga/effects';
import UserDTO from '../../../dto/UserDTO';
import { BACKEND_URL } from '../../../configs';
import makeRequest from '../../../utils/makeRequest';
import { OK } from '../../../configs/serverStatusCodes';
import { forgotPasswordFailed, forgotPasswordSuccessful } from '../actions';
import { resolveActionPromise } from '../../../utils/resolveActionPromise';
import { rejectActionPromise } from '../../../utils/rejectActionPromise';
import processError from '../../../utils/processError';

export default function* forgotPassword(action) {
  try {
    // todo need refact client model and this code
    const client = new UserDTO(action.payload);
    const url = `${BACKEND_URL}/auth/password-recovery`;
    const pool = client.getForgotData();
    const userDataResponse = yield makeRequest(url, pool, action, 'POST');
    const responseJson = yield userDataResponse.json();
    if (userDataResponse && userDataResponse.status === OK && responseJson.result && responseJson.result.is_ok) {
      yield put(forgotPasswordSuccessful(responseJson));
      yield call(resolveActionPromise(action, responseJson));
    } else {
      yield put(forgotPasswordFailed(responseJson));
      yield call(rejectActionPromise(action, responseJson));
    }
  } catch (e) {
    yield put(forgotPasswordFailed(e));
    yield call(rejectActionPromise(action, e));
    yield processError(e);
  }
}
