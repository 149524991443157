import { takeEvery, takeLatest } from 'redux-saga/effects';
import updateById from './updateById';
import deleteById from './deleteById';
import { MODULE_NAME } from '../constants';
import loadById from './loadById';
import create from './create';
import loadList from './loadList';
import {
  CREATE, DELETE_BY_ID, LOAD_BY_ID, LOAD_LIST, UPDATE,
} from '../../../configs/actionNames';

export default function* sagas() {
  yield takeLatest(MODULE_NAME + LOAD_LIST, loadList);
  yield takeEvery(MODULE_NAME + CREATE, create);
  yield takeEvery(MODULE_NAME + LOAD_BY_ID, loadById);
  yield takeEvery(MODULE_NAME + UPDATE, updateById);
  yield takeEvery(MODULE_NAME + DELETE_BY_ID, deleteById);
}
