// noinspection DuplicatedCode

import React, { Component } from 'react';
import { useIntl } from 'react-intl';

export default function FieldWithAttributeDTO(props) {
  const {
    dtoObject,
    customValidator,
    namePrefix = '',
    disabled,
    additionalValidators = [],
    notValid = false,
    component,
  } = props;
  const intl = useIntl();
  const { formatMessage } = intl;

  let name = 'not-found-dto';
  let dtoLabelKey = 'not-found-dto';
  let dtoValidators = [];
  if (!dtoObject || !dtoObject.labelKey) {
    window.console.error('Dto object not found');
    throw new Error('Dto object not found');
  }

  name = dtoObject.name;
  dtoLabelKey = dtoObject.labelKey;
  dtoValidators = dtoObject.validators;

  if (namePrefix) {
    name = `${namePrefix}.${name}`;
  }

  const validators = [
    ...dtoValidators,
    ...additionalValidators,
  ];

  const Component = component;

  return (
    <Component
      name={name}
      label={formatMessage({ id: dtoLabelKey })}
      placeholder={formatMessage({ id: `${dtoLabelKey}.placeholder`, defaultMessage: ' ' })}
      validate={!disabled && !notValid ? customValidator || validators : []}
      {...props}
    />
  );
}
