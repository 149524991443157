import Role from './Role';
import User from './User';
import System from './System';
import Client from './Client';
import HostsPage from './Hosts';
import ZonesPage from './Zones';
import ConfigZonesAndHost from './ConfigZonesAndHost';
import SessionLogPage from './SessionLog';
import EventLogPage from './EventLog';
import PaymentLogPage from './PaymentLog';
import ServiceMonitoringPage from './ServiceMonitoring';
import ClientsPage from './Clients';
import HostDirectoryPage from './HostDirectory';

import {
  USER_MODULE,
  CLIENT_MODULE,
  NOTIFICATION,
  ROLE_MODULE,
  SYSTEM_MODULE,
  HOSTS_MODULE,
  CONFIG_ZONES_AND_HOST_MODULE,
  SESSION_LOG_PAGE_MODULE,
  EVENT_LOG_PAGE_MODULE,
  PAYMENT_LOG_PAGE_MODULE,
  SERVICE_MONITORING_MODULE,
  CLIENTS_MODULE,
  ZONES_PAGE_MODULE,
  HOSTS_DIRECTORY_MODULE,
} from '../configs/moduleNames';
import Notifications from './Notifications';

const modules = {
  [SYSTEM_MODULE]: System,
  [CLIENT_MODULE]: Client,
  [NOTIFICATION]: Notifications,
  [SERVICE_MONITORING_MODULE]: ServiceMonitoringPage,
  [EVENT_LOG_PAGE_MODULE]: EventLogPage,
  [HOSTS_MODULE]: HostsPage,
  [SESSION_LOG_PAGE_MODULE]: SessionLogPage,
  [PAYMENT_LOG_PAGE_MODULE]: PaymentLogPage,
  [CLIENTS_MODULE]: ClientsPage,
  [ZONES_PAGE_MODULE]: ZonesPage,
  [ROLE_MODULE]: Role,
  [USER_MODULE]: User,
  [HOSTS_DIRECTORY_MODULE]: HostDirectoryPage,
  // [CONFIG_ZONES_AND_HOST_MODULE]: ConfigZonesAndHost,
};

export default modules;
