import * as classNames from 'classnames';
import React from 'react';
import './TabMenuStyles.scss';
import PermissionAccessWrapper from '../PermissionAccessWrapper';

export default function TabMenu(props) {
  const {
    selectedTab = null,
    className = '',
    options = [],
    onSelectOptions = () => {},
  } = props;

  return (
    <div className={classNames('tab-menu-temp', className)}>
      {options.map((item, index) => (
        <PermissionAccessWrapper key={index} requiredPermissions={item.permission} lockedOnClick addLockedClass>
          <div
            className={classNames('tab-menu-item-temp', { active: selectedTab && selectedTab.value === item.value })}
            onClick={() => { onSelectOptions(item); }}
          >
            {item.label}
          </div>
        </PermissionAccessWrapper>
      ))}
    </div>
  );
}
