import React from 'react';
import GridWrapper from './GridWrapper';
import GridHeader from './GridHeader';
import GridBody from './GridBody';

export default function Grid(props) {
  return (
    <GridWrapper {...props}>
      <GridHeader {...props} />
      <GridBody {...props} />
    </GridWrapper>
  );
}
