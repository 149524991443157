export default function ListType(ItemType) {
  return list => {
    const resultList = [];
    if (list && list.length) {
      for (let i = 0, len = list.length; i < len; i++) {
        resultList.push(new ItemType(list[i]));
      }
    }
    return resultList;
  };
}
