import { call, put } from 'redux-saga/effects';
import { BACKEND_URL } from '../../../configs';
import { rejectActionPromise } from '../../../utils/rejectActionPromise';
import { resolveActionPromise } from '../../../utils/resolveActionPromise';

import { createFailed, createSuccessful } from '../actions';
import { MODULE_BACK_PATH, MODULE_DTO } from '../constants';
import makeRequest from '../../../utils/makeRequest';
import { OK } from '../../../configs/serverStatusCodes';
import processError from '../../../utils/processError';

export default function* create(action) {
  try {
    const item = new MODULE_DTO(action.payload);
    const url = `${BACKEND_URL}/${MODULE_BACK_PATH}`;
    const pool = item.getData();
    const dataResponse = yield makeRequest(url, pool, action, 'POST', true);
    const data = yield dataResponse.json();
    if (dataResponse.status === OK) {
      yield put(createSuccessful([]));
      yield call(resolveActionPromise(action, data));
    } else {
      yield put(createFailed([]));
      yield call(rejectActionPromise(action, data));
    }
  } catch (e) {
    yield put(createFailed([]));
    yield call(rejectActionPromise(action, e));
    yield processError(e);
  }
}
