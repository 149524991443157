import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hideGlobalConfirm } from '../actions';
import ConfirmModal from '../../../components/base/Dialog/ConfirmModal';
import { getGlobalConfirmData } from '../reducer';
import { rejectActionPromise } from '../../../utils/rejectActionPromise';
import { resolveActionPromise } from '../../../utils/resolveActionPromise';

export default function GlobalConfirmModal() {
  const dispatch = useDispatch();
  const {
    showConfirm = false,
    confirmTitle = '',
    confirmMessage = '',
    confirmClassName = '',
    confirmButtonText = '',
    confirmCancelButtonText = '',
    hideSubmit = false,
    promiseId = null,
  } = useSelector(getGlobalConfirmData);

  const onHideConfirm = () => {
    rejectActionPromise({ promiseId })();
    dispatch(hideGlobalConfirm());
  };

  const handleOk = () => {
    resolveActionPromise({ promiseId })();
    dispatch(hideGlobalConfirm());
  };
  return (
    <ConfirmModal
      show={showConfirm}
      onHide={onHideConfirm}
      onSubmit={handleOk}
      title={confirmTitle}
      confirmMessage={confirmMessage}
      buttonText={confirmButtonText || 'Продолжить'}
      cancelButtonText={confirmCancelButtonText || 'Отмена'}
      className={confirmClassName}
      hideSubmit={hideSubmit}
    />
  );
}
