import classNames from 'classnames';
import React, { Component } from 'react';
import Chart from 'react-apexcharts';
import { DEFAULT_CHART_COLORS } from '../../configs';
import Loader from '../base/Loader/Loader';

export default function ApexBarChart(props) {
  const {
    series = [],
    labels = [],
    barLabels = [],
    title = '',
    className = '',
    renderFooter = () => {
    },
    loading = false,
  } = props;
  const data = series.map(row => ({ data: row }));
  const options = {
    chart: {
      type: 'bar',
      height: 430,
    },
    colors: DEFAULT_CHART_COLORS,
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          position: 'top',
        },
        barHeight: '100%',
        columnWidth: '100%',
      },
    },
    tooltip: {
      y: {
        // formatter: undefined,
        title: {
          formatter: (seriesName, opts) => [barLabels[opts.seriesIndex]],
        },
      },
    },
    dataLabels: {
      enabled: true,
      offsetX: -6,
      style: {
        fontSize: '10px',
        colors: ['#000'],
      },
      formatter(val) {
        return val || '';
      },
    },
    legend: {
      show: false,
      position: 'right',
      formatter(seriesName, opts) {
        return [barLabels[opts.seriesIndex]];
      },
      fontSize: '16px',
      fontFamily: "'GOSTUI2', 'Helvetica Neue', Helvetica, Arial, sans-serif",
      fontWeight: 400,
      labels: {
        color: undefined,
        useSeriesColors: true,
      },
    },
    stroke: {
      show: true,
      width: 1,
      colors: ['#fff'],
    },
    xaxis: {
      categories: labels,
      labels: {
        show: labels.length,
      },
    },
    yaxis: {
      show: labels.length,
    },
    title: {
      text: title,
      style: {
        fontSize: '15px',
        fontWeight: 500,
        fontFamily: 'GOSTUI2, Helvetica Neue, Helvetica, Arial, sans-serif',
        color: '#1665A7',
      },
    },
    noData: {
      text: 'Данные отсутствуют',
      align: 'center',
      verticalAlign: 'middle',
    },
  };

  let addingProps = {
    height: (labels.length * 50) + 90,
  };
  // todo calc series.len * height 1 column
  if (!labels.length) {
    addingProps = { height: 350 };
  }
  return (
    <div className={classNames('card-block-wrapper', className)}>
      <Loader loading={loading} />
      <div className="card-block chart-block">
        <div className="chart-block-content">
          <div className="chart-graph">
            {/* {yAxisTitle && <div className='axis-title y-axis-title'><div className='axis-title-text'>{yAxisTitle}</div></div>} */}
            {/* <div className={classNames('chart-wrapper', { 'yTitle': !!yAxisTitle }, { 'xTitle': !!xAxisTitle })}> */}
            <div className="apex-chart-wrapper apex-scroll">
              <Chart options={options} series={data} type="bar" {...addingProps} />
            </div>
            {/* {xAxisTitle && <div className='axis-title x-axis-title'>{xAxisTitle}</div>} */}
          </div>
        </div>
        {renderFooter()}
      </div>
    </div>
  );
}
