export const SUCCESSFUL = '_SUCCESSFUL';
export const FAILED = '_FAILED';
export const BEGIN = '_BEGIN';
export const LOAD_LIST = '_LOAD_LIST';
export const CREATE = '_CREATE';
export const LOAD_BY_ID = '_LOAD_BY_ID';
export const UPDATE = '_UPDATE';
export const DELETE_BY_ID = '_DELETE_BY_ID';
export const DELETE_FILE = '_DELETE_FILE';
export const UPLOAD_FILE = '_UPLOAD_FILE';
export const SHOW = '_SHOW';
export const HIDE = '_HIDE';
