import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import {
  Routes,
  Route,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import '../theme/scss/styles.scss';
import { isMobile } from 'react-device-detect';
import { getPathNameWithOutParameters, responseLogWithNotification } from '../utils/utils';
import { clientCheck } from '../modules/Client/actions';
import { getLayoutByRole, getRouteByRole, calculateBaseRoute } from '../utils/routeByRoles';
import { endLoadApp, initApp } from '../modules/System/actions';
import { getClientData } from '../modules/Client/reducer';

export default function Main() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const intl = useIntl();
  const { formatMessage } = intl;
  const {
    loggedIn, clientData, loadingClientCheck, loginProcess,
  } = useSelector(getClientData);
  const { permissions = [] } = clientData;
  const permission_keys = permissions.map(permission => permission.name);

  useEffect(() => {
    dispatch(initApp());
    document.title = formatMessage({ id: 'app.title' });
    if (loggedIn) {
      dispatch(clientCheck())
        .then(() => {
          setTimeout(() => { dispatch(endLoadApp()); }, 800);
        })
        .catch(response => {
          responseLogWithNotification(response);
        });
    } else if (!loginProcess && !loadingClientCheck) {
      dispatch(endLoadApp());
    }
  }, [dispatch, loggedIn, formatMessage]);

  const renderRoute = (prop, key) => {
    if (prop.children && prop.children.length) {
      return prop.children.map((chProp, chKey) => renderRoute(chProp, (chKey + key)));
    }
    if (!prop.path) {
      return null;
    }
    const path = getPathNameWithOutParameters(prop.path);
    if (prop.path && prop.redirect && (prop.path === '*' || location.pathname === path) && location.pathname !== prop.to) {
      setTimeout(() => {
        navigate(prop.to);
      }, 100);
    }
    return (
      <Route path={prop.path} Component={prop.component} key={key} />
    );
  };

  const currentRoutes = getRouteByRole(loggedIn, permission_keys, null, isMobile);
  const currentRoutesByMenu = getRouteByRole(loggedIn, permission_keys, null, isMobile, true);
  const CurrentLayout = getLayoutByRole(loggedIn, isMobile);
  calculateBaseRoute(true, permission_keys, null, isMobile, true);
  return (
    <CurrentLayout currentRoutes={currentRoutes} currentRoutesByMenu={currentRoutesByMenu}>
      <Routes>
        {currentRoutes.map((prop, key) => renderRoute(prop, key))}
      </Routes>
    </CurrentLayout>
  );
}
