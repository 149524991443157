import { createAction } from 'redux-actions';
import { createDeferredAction } from '../../utils/redux';

import {
  BEGIN,
  CREATE,
  FAILED,
  SUCCESSFUL,
} from '../../configs/actionNames';
import { EVENT_LOG_PAGE_MODULE } from '../../configs/moduleNames';

export const LOAD_EVENT_LIST = '_LOAD_EVENT_LIST';
export const LOAD_EVENT_STATUS_LIST = '_LOAD_EVENT_STATUS_LIST';

export const create = createDeferredAction(EVENT_LOG_PAGE_MODULE + CREATE);
export const createSuccessful = createAction(EVENT_LOG_PAGE_MODULE + CREATE + SUCCESSFUL);
export const createFailed = createAction(EVENT_LOG_PAGE_MODULE + CREATE + FAILED);

export const loadEventStatus = createDeferredAction(EVENT_LOG_PAGE_MODULE + LOAD_EVENT_STATUS_LIST);
export const loadEventStatusBegin = createAction(EVENT_LOG_PAGE_MODULE + LOAD_EVENT_STATUS_LIST + BEGIN);
export const loadEventStatusSuccessful = createAction(EVENT_LOG_PAGE_MODULE + LOAD_EVENT_STATUS_LIST + SUCCESSFUL);
export const loadEventStatusFailed = createAction(EVENT_LOG_PAGE_MODULE + LOAD_EVENT_STATUS_LIST + FAILED);

export const loadEventList = createDeferredAction(EVENT_LOG_PAGE_MODULE + LOAD_EVENT_LIST);
export const loadEventListBegin = createAction(EVENT_LOG_PAGE_MODULE + LOAD_EVENT_LIST + BEGIN);
export const loadEventListSuccessful = createAction(EVENT_LOG_PAGE_MODULE + LOAD_EVENT_LIST + SUCCESSFUL);
export const loadEventListFailed = createAction(EVENT_LOG_PAGE_MODULE + LOAD_EVENT_LIST + FAILED);
