import { call } from 'redux-saga/effects';
import UserDTO from '../../../dto/UserDTO';
import { BACKEND_URL } from '../../../configs';
import makeRequest from '../../../utils/makeRequest';
import { OK } from '../../../configs/serverStatusCodes';
import { resolveActionPromise } from '../../../utils/resolveActionPromise';
import { rejectActionPromise } from '../../../utils/rejectActionPromise';
import processError from '../../../utils/processError';

export default function* clientRegistration(action) {
  try {
    const client = new UserDTO(action.payload);
    const url = `${BACKEND_URL}/auth/signup`;
    const pool = client.getRegistrationData();
    const userDataResponse = yield makeRequest(url, pool, action);
    const clientData = yield userDataResponse.json();

    if (userDataResponse.status === OK) {
      yield call(resolveActionPromise(action, clientData));
    } else {
      yield call(rejectActionPromise(action, clientData));
    }
  } catch (e) {
    yield processError(e);
  }
}
