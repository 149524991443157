import React, { useRef, useState, useCallback } from 'react';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import './SelectInputStyles.scss';
import { Icons } from '../../base/BootstrapComponents';
import Dropdown from '../../base/Dropdown/Dropdown';
import TextInput from '../TextInput/TextInput';

export default function CustomSelectInput(props) {
  const {
    inputProps = null,
    className = '',
    options = [],
    fieldState = {
      error: '',
      touched: false,
    },
    controlId = `text-input-${Math.random() * 10000}`,
    renderOption = null,
    addPlaceholder = false,
    useDefaultNull = false,
    placeholder = '',
    multiple = false,
    customToggleContent = null,
    disabled = false,
    inputClassName = '',
  } = props;
  const {
    error,
    isTouched: touched,
  } = fieldState;
  const intl = useIntl();
  const { formatMessage } = intl;
  const [toggleFunction, setToggleFunction] = useState(() => () => {});
  const anchorElement = useRef(null);
  const isError = Boolean(error);
  const defaultRenderOption = item => (!item.name && item.nameId ? formatMessage({ id: item.nameId }) : item.name || '');
  let renderOptionFunction = defaultRenderOption;
  if (renderOption && typeof renderOption === 'function') {
    renderOptionFunction = renderOption;
  }
  const value = inputProps && (inputProps.value || typeof inputProps.value === 'boolean') ? inputProps.value : multiple ? [] : null;

  const onChange = item => {
    if (inputProps && inputProps.onChange) {
      let changeValue = item.value;
      if (multiple) {
        const newValue = [...value];
        const index = value.indexOf(item.value);
        if (index > -1) {
          newValue.splice(index, 1);
        } else {
          newValue.push(item.value);
        }
        changeValue = newValue;
      }
      inputProps.onChange({ target: { value: changeValue } });
    }
  };

  let inputValue = '';
  if (!multiple) {
    const inputOption = options.find(i => i.value === value);
    inputValue = inputOption ? defaultRenderOption(inputOption) : '';
  } else {
    const selectedItems = options.filter(item => value.includes(item.value)).map(defaultRenderOption);
    inputValue = selectedItems.join(', ');
  }
  const isSelectedPlaceholder = value => {
    if ((Array.isArray(value) && !value.length) || value === null) {
      return true;
    }
    if (typeof value === 'number' || typeof value === 'string') {
      return !String(value);
    }
    return false;
  };

  return (
    <div className={classNames('form-control-wrapper', className)}>
      <Dropdown
        dropBottom
        usePortal
        disabled={disabled}
        setToggleFunction={a => setToggleFunction(() => a)}
      >
        <Dropdown.Toggle className="select-toggle">
          {!customToggleContent || typeof customToggleContent !== 'function'
            ? (
              <TextInput
                {...props}
                readOnly
                inputAfter={(
                  <div id={controlId} className="select-arrow-wrapper">
                    <Icons customIcon className="select-arrow" width={12} height={8} icon="expand-more" />
                  </div>
              )}
                className={classNames('custom-select', { 'is-invalid': isError && touched }, inputClassName)}
                refFunction={anchorElement}
                inputProps={{
                  onChange: () => {},
                  value: inputValue,
                  // onBlur: () => typeof toggleFunction === 'function' && setTimeout(() => toggleFunction(false), 100),
                  // onFocus: () => typeof toggleFunction === 'function' && toggleFunction(true),
                }}
              />
            )
            : customToggleContent(props, value, options, anchorElement)}
        </Dropdown.Toggle>
        <Dropdown.Menu disableClickToggle={multiple} rightPadding={32} className="select-options-wrapper" anchorElement={anchorElement} classNamePortal="portal-select-options">
          <div className="select-options-list">
            {addPlaceholder
              ? (
                <div
                  className={classNames('select-option', { selected: isSelectedPlaceholder(value) })}
                  onClick={() => { onChange({ value: multiple ? [] : useDefaultNull ? null : '' }); }}
                >
                  {renderOptionFunction({ name: placeholder || '' })}
                </div>
              )
              : null}
            {options.map((item, key) => {
              let isSelected = item.value === value;
              if (multiple) {
                isSelected = !!value.includes(item.value);
              }
              return (
                <div
                  key={key}
                  className={classNames('select-option', { selected: isSelected })}
                  onClick={() => { onChange(item); }}
                >
                  {renderOptionFunction(item)}
                </div>
              );
            })}
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}
