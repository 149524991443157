import React from 'react';
import { useSelector } from 'react-redux';
import { useDeviceSelectors } from 'react-device-detect';
import classNames from 'classnames';
import { NOTIFICATION } from '../../../configs/moduleNames';
import './NotificationStyles.scss';
import NotificationsItem from './NotificationsItem';

export default function Notifications({ children }) {
  const notifications = useSelector(state => state[NOTIFICATION].notifications);
  const [selectors] = useDeviceSelectors(window.navigator.userAgent);
  const { isMobile } = selectors;
  return notifications && notifications.length
    ? (
      <div className={classNames('notifications-wrapper', { mobile: isMobile })}>
        {notifications.map((notification, key) => <NotificationsItem key={key} {...notification} />)}
      </div>
    )
    : null;
}
