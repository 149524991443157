import { createAction } from 'redux-actions';
import { createDeferredAction } from '../../utils/redux';

import {
  BEGIN,
  CREATE,
  FAILED,
  SUCCESSFUL,
} from '../../configs/actionNames';
import { PAYMENT_LOG_PAGE_MODULE } from '../../configs/moduleNames';

export const LOAD_PAYMENT_LIST = '_LOAD_PAYMENT_LIST';
export const LOAD_PAYMENT_TYPE_LIST = '_LOAD_PAYMENT_TYPE_LIST';
export const LOAD_PAYMENT_METHOD_LIST = '_LOAD_PAYMENT_METHOD_LIST';

export const create = createDeferredAction(PAYMENT_LOG_PAGE_MODULE + CREATE);
export const createSuccessful = createAction(PAYMENT_LOG_PAGE_MODULE + CREATE + SUCCESSFUL);
export const createFailed = createAction(PAYMENT_LOG_PAGE_MODULE + CREATE + FAILED);

export const loadPaymentList = createDeferredAction(PAYMENT_LOG_PAGE_MODULE + LOAD_PAYMENT_LIST);
export const loadPaymentListBegin = createAction(PAYMENT_LOG_PAGE_MODULE + LOAD_PAYMENT_LIST + BEGIN);
export const loadPaymentListSuccessful = createAction(PAYMENT_LOG_PAGE_MODULE + LOAD_PAYMENT_LIST + SUCCESSFUL);
export const loadPaymentListFailed = createAction(PAYMENT_LOG_PAGE_MODULE + LOAD_PAYMENT_LIST + FAILED);

export const loadPaymentTypeList = createDeferredAction(PAYMENT_LOG_PAGE_MODULE + LOAD_PAYMENT_TYPE_LIST);
export const loadPaymentTypeListBegin = createAction(PAYMENT_LOG_PAGE_MODULE + LOAD_PAYMENT_TYPE_LIST + BEGIN);
export const loadPaymentTypeListSuccessful = createAction(PAYMENT_LOG_PAGE_MODULE + LOAD_PAYMENT_TYPE_LIST + SUCCESSFUL);
export const loadPaymentTypeListFailed = createAction(PAYMENT_LOG_PAGE_MODULE + LOAD_PAYMENT_TYPE_LIST + FAILED);

export const loadPaymentMethodList = createDeferredAction(PAYMENT_LOG_PAGE_MODULE + LOAD_PAYMENT_METHOD_LIST);
export const loadPaymentMethodListBegin = createAction(PAYMENT_LOG_PAGE_MODULE + LOAD_PAYMENT_METHOD_LIST + BEGIN);
export const loadPaymentMethodListSuccessful = createAction(PAYMENT_LOG_PAGE_MODULE + LOAD_PAYMENT_METHOD_LIST + SUCCESSFUL);
export const loadPaymentMethodListFailed = createAction(PAYMENT_LOG_PAGE_MODULE + LOAD_PAYMENT_METHOD_LIST + FAILED);
