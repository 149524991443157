import { isMobile } from 'react-device-detect';
import LoginLayout from '../components/layout/LoginLayout';
import Layout from '../components/layout/Layout';
import routes from '../configs/routes';
import MobileLayout from '../components/layout/MobileLayout';
import { GUEST_MODULE, SYSTEM_MODULE } from '../configs/moduleNames';
import { sortLinksFunction } from './layout-utils';
import PermissionDeniedPage from '../modules/System/components/PermissionDeniedPage';

const filterRoutes = (routesList, loggedIn, permission_keys = [], state = {}, isMobile, isLoading) => {
  const userRoutes = [];
  const userRoutesForMenu = [...routesList].filter((route = {}) => {
    if (typeof route.permission === 'string') {
      route.permission = [route.permission];
    }
    const needLoggedIn = Boolean(loggedIn) === Boolean(route.loginRequired);
    const isMobileUse = (route.forMobile && isMobile) || (!route.forMobile && !isMobile);
    const isGuestModule = route.permission.some(item => item === GUEST_MODULE);
    const permission = !!route.permission.every(routePermission => !!permission_keys.find(item => item === routePermission));
    const isSystemModule = route.permission.some(item => item === SYSTEM_MODULE);
    if (permission && route.children && route.children.length && loggedIn) {
      route.children = filterRoutes(route.children, loggedIn, permission_keys, state, isLoading);
    }
    let customFilter = true;
    if (route && route.filter && typeof route.filter === 'function') {
      customFilter = route.filter(state, route);
    }

    if (needLoggedIn && isMobileUse && customFilter) {
      if (!permission && !(isGuestModule || isSystemModule)) {
        userRoutes.push({ ...route, component: PermissionDeniedPage });
      } else {
        userRoutes.push(route);
      }
    }

    return needLoggedIn && isMobileUse && customFilter && (permission || isGuestModule || isSystemModule);
  });

  return isLoading ? userRoutesForMenu : userRoutes;
};

export const getRouteByRole = (loggedIn, permission_keys = [], state = {}, isMobile, isLoading) => filterRoutes(routes, loggedIn, permission_keys, state, isMobile, isLoading);

export const calculateBaseRoute = (loggedIn, permission_keys = [], state = {}, isMobile, isLoading) => {
  const currentRoutes = getRouteByRole(loggedIn, permission_keys, state, isMobile, isLoading);
  const sortedRoutes = currentRoutes.filter(i => !i.hideOnMenu && i.permission[0] !== 'SYSTEM').sort(sortLinksFunction);
  window.baseRoute = sortedRoutes && sortedRoutes.length ? sortedRoutes[0].path : '/';
  return window.baseRoute;
};

export const getLayoutByRole = (loggedIn, isMobile = false) => {
  if (!loggedIn) {
    return LoginLayout;
  }
  if (isMobile) {
    return MobileLayout;
  }
  return Layout;
};
