import { call, put } from 'redux-saga/effects';
import { BACKEND_URL } from '../../../configs';
import { rejectActionPromise } from '../../../utils/rejectActionPromise';
import { resolveActionPromise } from '../../../utils/resolveActionPromise';

import { updateUserFailed, updateUserSuccessful } from '../actions';
import { OK } from '../../../configs/serverStatusCodes';
import makeRequest from '../../../utils/makeRequest';
import processError from '../../../utils/processError';

export default function* updateUser(action) {
  try {
    const {
      userData = {},
      id,
    } = action.payload;
    const url = `${BACKEND_URL}/clients/${id}`;
    const dataResponse = yield makeRequest(url, userData, action, 'PUT', true);
    const data = yield dataResponse.json();
    if (dataResponse.status === OK
      && data
    ) {
      yield put(updateUserSuccessful([]));
      yield call(resolveActionPromise(action, data));
    } else {
      yield put(updateUserFailed([]));
      yield call(rejectActionPromise(action, data));
    }
  } catch (e) {
    yield put(updateUserFailed([]));
    yield call(rejectActionPromise(action, e));
    yield processError(e);
  }
}
